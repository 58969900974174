import { useSigma, useRegisterEvents } from '@react-sigma/core';
import { useEffect } from 'react';
import { highlightPath, resetAllEdgesColor, leaveAllNodes, highlightSelectedNode } from '_helpers/sigmaEvents';

/**
 * @param {setHoveredNode: (node: string | null) => void} param0
 * @returns
 */
function GraphEventsController({ setHoveredNode, onGetNodeInfo, children, onResetEdges }) {
  const sigma = useSigma();
  const graph = sigma.getGraph();
  const registerEvents = useRegisterEvents();

  // those modify the mouse style so nodes are like buttons
  const handleEnterNode = () => {
    sigma.getContainer().style.cursor = 'pointer';
  };

  const handleLeaveNode = () => {
    sigma.getContainer().style.cursor = 'default';

  };

  /**
   * Initialize here settings that require to know the graph and/or the sigma
   * instance:
   */
  useEffect(() => {
    registerEvents({
      clickNode: async ({ node }) => {
        resetAllEdgesColor(sigma);
        leaveAllNodes(sigma);
        const node_label = graph.getNodeAttribute(node, 'label');
        const node_annotation = graph.getNodeAttribute(node, 'annotation');
        if (!graph.getNodeAttribute(node, 'hidden')) {
          const paths = await onGetNodeInfo({ id: node, label: node_label, annotation: node_annotation });
          paths.forEach((path) => {
            highlightPath(sigma, path, node);
          });
          if (paths.length === 0) {
            highlightSelectedNode(sigma, node);
          }
        }
      },
      enterNode: () => { handleEnterNode() },
      leaveNode: () => { handleLeaveNode() },
      clickStage: () => {
        resetAllEdgesColor(sigma);
        leaveAllNodes(sigma);
        onResetEdges();
      },
    });
  }, []);

  return children;
}

export default GraphEventsController;
