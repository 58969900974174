const config = {
  DEBUG: true,
  apiUrl: '/api',
  clientUrls: {
    TargetValidation: '/targetvalidation',
    HOME: '/',
    FILES: '/files',
    VISUALIZATION: '/visualization',
    VISUALIZATION_PROJECT: (projectId) => `/visualization/project/${projectId}`,
    TARGET_VALIDATION_PROJECT: (projectId) => `/target_validation/project/${projectId}`,
    VISUALIZATION_WITHOUT_FILE: '/visualization_without_file',
    PROFILE: '/profile',
    PROJECTS: '/projects',
    BIOMARKERS: '/biomarkers',
    BIOMARKERS_PROJECT_RECONCILATION: (projectId) => `/biomarkers/project/${projectId}/reconciliation`,
    BIOMARKERS_PROJECT: (projectId) => `/biomarkers/project/${projectId}`,
    MODELS: '/models',
    SETTINGS: '/settings',
    HELP: '/help',
    GRAPHS_DISEASE: (modelId, aiPreds = 'true') =>
      `/graphs/${modelId}` +
      `&aipreds=${aiPreds}`,
    NODE_PROCESS_PATHS__TV_PROJECT: (projectId) => `/target_validation/project/${projectId}/node_process_paths`,
  },
};

export default config;
