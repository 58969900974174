import { ControlsContainer, FullScreenControl, SigmaContainer, ZoomControl } from '@react-sigma/core';

import { DirectedGraph } from 'graphology';
import GraphEventsController from '_pages/TargetValidation/GraphDisease/Graphology/GraphEventsController.Demo';
import GraphDataController from './Graphology/GraphDataController';
import GraphSettingsController from '_pages/TargetValidation/GraphDisease/Graphology/GraphSettingsController';

import '@react-sigma/core/lib/react-sigma.min.css';
import { useState } from 'react';
import SearchField from './Graphology/UIControls/SearchField';
import Filters from './Graphology/UIControls/Filters';
import { IconButton, Paper, Tooltip } from '@mui/material';
import customTheme from '_theme/theme';
import { Box } from '@mui/system';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TuneIcon from '@mui/icons-material/Tune';
import GraphLegendTable from './GraphLegendTable';
import ViewListIcon from '@mui/icons-material/ViewList';

function CollapsibleFilters() {
  const [toggleFilters, setToggleFilters] = useState(true);
  function onToggle() {
    setToggleFilters(!toggleFilters)
  }
  return (
    <Box>
      <ControlsContainer className="noBorderControlsContainer" position="top-right">
        <Box sx={{ float: "right" }}>
          <Tooltip title='Show/Hide Filters'>
            <IconButton onClick={() => onToggle()}>
              {!toggleFilters ? (
                <TuneIcon />
              ) : (
                <VisibilityOffIcon />
              )}
            </IconButton>
          </Tooltip>
        </Box>
        {toggleFilters ? (
          <Paper
            variant="outlined"
            sx={{ paddingTop: 1, paddingBottom: 1, backgroundColor: customTheme.palette.background.default }}
          >
            <Box>
              <SearchField />
              <Filters />
            </Box>
          </Paper>
        ) : (<></>)}
      </ControlsContainer>
    </Box>
  )
}


function CollapsibleLegend() {
  const [toggleLegend, setToggleLegend] = useState(true);
  function onToggle() {
    setToggleLegend(!toggleLegend)
  }
  return (
    <Box>
      <ControlsContainer className="noBorderControlsContainer" position="top-left">
        <Box sx={{ float: "left" }}>
          <Tooltip title='Show/Hide Legend'>
            <IconButton onClick={() => onToggle()}>
              {!toggleLegend ? (
                <ViewListIcon />
              ) : (
                <VisibilityOffIcon />
              )}
            </IconButton>
          </Tooltip>
        </Box>
        {toggleLegend ? (
          <GraphLegendTable></GraphLegendTable>

        ) : (<></>)}
      </ControlsContainer>
    </Box>
  )
}

const SIMGA_SETTINGS = {
  defaultEdgeType: 'arrow',
  defaultEdgeColor: '#bcbcbc',
  labelDensity: 0.07,
  labelGridCellSize: 60,
  // labelRenderedSizeThreshold: 15,
  labelFont: 'Lato, sans-serif',
  zIndex: true,
};

/**
 * Renders sigma/graphology graph with external suggestion/selector
 * @todo find appropriate name
 * @param {{diseaseData: Array}} props
 */
function SigmaDiseaseGraph({ diseaseData, onGetNodeInfo, onResetEdges }) {
  const [hoveredNode, setHoveredNode] = useState(null);

  return (
    <SigmaContainer
      graphOptions={{ type: 'directed' }}
      settings={SIMGA_SETTINGS}
      style={{ border: '1px solid grey', }}
      graph={DirectedGraph}
    >
      { // the controls have a z index of 100
      }
      <ControlsContainer position="bottom-right" className="noBorderControlsContainer">
        <Paper variant="outlined">
          <ZoomControl />
          <FullScreenControl />
        </Paper>
      </ControlsContainer>
      <CollapsibleLegend />
      <CollapsibleFilters />
      <GraphSettingsController hoveredNode={hoveredNode} />
      <GraphDataController diseaseData={diseaseData} />

      <GraphEventsController
        onGetNodeInfo={onGetNodeInfo}
        setHoveredNode={setHoveredNode}
        onResetEdges={onResetEdges}
      />
    </SigmaContainer>
  );
}

export default SigmaDiseaseGraph;
