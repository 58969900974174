import { Container, Typography } from "@mui/material";

export default function NotFoundPage() {
  return (
    <Container>
      <Typography variant="h2">
        {"The page you\'re trying to access could not be found."}
      </Typography>
    </Container>
  );
}
