import Plot from "react-plotly.js";

import type { Data, Layout } from 'plotly.js';

interface LocalPlotParams {
    data: Data[];
    layout: Partial<Layout>;
    divId?: string;
}

export default function LocalPlot({ data, layout, divId, ...props }: LocalPlotParams) {
    return <Plot
        data={data}
        layout={layout}
        useResizeHandler={true}
        style={{ width: "100%", minHeight: "550px" }}
        divId={divId}
        {...props}
    />
}
