import { Button, Box, Divider, Accordion, AccordionSummary, AccordionDetails, AccordionActions, Link } from '@mui/material';
import KeywordsChips from './KeywordsChips';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IndividualProteinInfo } from './TargetEfficacyInterface';
import { useState } from 'react';

export default function NodeInfoAccordion(metaInfo: IndividualProteinInfo, id: string) {

  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  function getStringLink() {
    return 'https://string-db.org/network/' + id
  }

  return (
    <Box key={metaInfo?.preferredName} sx={{ marginBottom: 1, marginTop: 1 }}>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography sx={{ width: '33%', flexShrink: 0 }}>{metaInfo?.preferredName}</Typography>
          <Typography sx={{ color: 'text.secondary', marginLeft: 'auto', marginRight: 2 }}>{expanded ? 'View Less' : 'View More'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider>
            <Typography sx={{ color: 'text.secondary' }}>Known Pathways</Typography>
          </Divider>
          <Box sx={{ mt: '5px' }}>{KeywordsChips(metaInfo?.wikipathways)}</Box>
          <Divider>
            <Typography sx={{ color: 'text.secondary' }}>Keywords</Typography>
          </Divider>
          <Box sx={{ mt: '5px' }}>{KeywordsChips(metaInfo?.keywords)}</Box>
          <Divider>
            <Typography sx={{ color: 'text.secondary' }}>Tissues</Typography>
          </Divider>
          <Box sx={{ mt: '5px' }}>{KeywordsChips(metaInfo?.tissues)}</Box>
        </AccordionDetails>
        <AccordionActions>
          <Link target="_blank" href={getStringLink()}>
            {metaInfo?.preferredName} String Source Data
          </Link>
        </AccordionActions>
      </Accordion>
    </Box>
  );
}
