import React from 'react';
import type { PropsWithChildren } from 'react';
import type { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Modal, { ModalProps } from '@mui/material/Modal';
import { Backdrop } from '@mui/material';

const defaultSx: SxProps<Theme> = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: 'background.paper',
  // border: '1px solid #000',
  borderRadius: '30px',
  boxShadow: 24,
  pt: 4,
  pb: 2,
  px: 4,
};

interface BasicModalProps {
  children?: React.ReactElement;
  open?: boolean;
  onClose?: () => void;
  title?: string;
  description?: string;
  boxSx?: SxProps<Theme>;
}

export default function BasicModal({
  children,
  open = true,
  title,
  description,
  onClose,
  boxSx = {},
  ...props
}: PropsWithChildren<BasicModalProps> & ModalProps) {
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            sx: {
              //Your style here....
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
            },
          } as any,
        }}
        {...props}
      >
        <Box sx={{ ...defaultSx, ...boxSx } as SxProps<Theme>}>
          {children}
        </Box>
      </Modal>
    </div>
  );
}