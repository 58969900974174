import { create } from 'zustand';

export interface AiPredStore {
  filterValue: string;
  filterBooleanValue: boolean;
  filterCutoff: number;
  setValue: (value: string) => void;
  setBoolValue: (value: boolean) => void;
  setCutoff: (value: number) => void;
  reset: () => void;
}
const INIT_FILTER_VALUE = 'true';
const INIT_FILTER_BOOLEAN_VALUE = true;
const INIT_FILTER_CUTOFF = 0.5;

const AiPredStore = create<AiPredStore>((set) => ({
  filterValue: INIT_FILTER_VALUE,
  filterBooleanValue: INIT_FILTER_BOOLEAN_VALUE,
  filterCutoff: INIT_FILTER_CUTOFF,
  setValue: (value: string) => set(() => ({ filterValue: value, filterBooleanValue: value === 'true' ? true : false })),
  setBoolValue: (value: boolean) => set(() => ({ filterValue: String(value), filterBooleanValue: value })),
  setCutoff: (value: number) => set(() => ({ filterCutoff: value })),
  reset: () =>
    set(() => ({
      filterValue: INIT_FILTER_VALUE,
      filterBooleanValue: INIT_FILTER_BOOLEAN_VALUE,
      filterCutoff: INIT_FILTER_CUTOFF,
    })),
}));

export default AiPredStore;
