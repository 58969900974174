import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface PaletteColor {
    tinted?: string;
    lightgrey?: string;
  }

  interface SimplePaletteColorOptions {
    tinted?: string;
    lightgrey?: string;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    buttons: Palette['primary'];
    tables: Palette['primary'];
  }

  interface PaletteOptions {
    buttons?: PaletteOptions['primary'];
    tables?: PaletteOptions['primary'];
  }
}

const customTheme = createTheme({
  palette: {
    background: {
      default: '#FEFDFF',
    },
    primary: {
      // dark blue
      main: '#113046',
      tinted: '#294559',
    },
    secondary: {
      // orange
      main: '#FECE61',
      lightgrey: '#F8FAFC',
    },
    buttons: {
      main: 'rgba(138, 168, 205, 1)',
      dark: 'rgba(138, 168, 205, 1)',
    },
    tables: {
      main: 'rgb(138, 168, 205, 0.7)',
      dark: 'rgba(138, 168, 205, 1)',
      light: '#dee6f0',
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: 'white',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          backgroundColor: 'white',
        },
      },
    },
  },
});

export default customTheme;
