import history from '_helpers/history';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import BiotechIcon from '@mui/icons-material/Biotech';
import StorageIcon from '@mui/icons-material/Storage';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import BalanceIcon from '@mui/icons-material/Balance';
import SettingsIcon from '@mui/icons-material/Settings';
import HubIcon from '@mui/icons-material/Hub';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import InventoryIcon from '@mui/icons-material/Inventory';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import HelpIcon from '@mui/icons-material/Help';
import { useTheme } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import './drawer.scss';
import config from '_configs';
import { ExpandLess, ExpandMore, StarBorder } from '@mui/icons-material';
import { Collapse } from '@mui/material';
const drawerWidth = 300;

export default function PermanentDrawerLeft() {
  const theme = useTheme();
  const { user, logout } = useAuth0();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  const buttonTextSize = 20;

  // Texts / ids for the buttons
  const HomeText = 'Home';
  const TargetValidationText = 'Target Validation';
  const MyProjectText = 'My Projects';
  const MyDataText = 'My Data';
  const ModelsText = 'Models';
  const VisualizationText = 'Visualization';
  const BiomarkersText = 'Biomarkers';
  const AccountText = 'Account';
  const LogoutText = 'Logout';
  const HelpText = 'Help';

  // mapping of buttons
  const mainButtons = [{ text: HomeText, icon: HomeIcon, page: config.clientUrls.HOME }];
  const productsButtons = [
    { text: TargetValidationText, icon: VaccinesIcon, page: config.clientUrls.TargetValidation },
    { text: VisualizationText, icon: AnalyticsIcon, page: config.clientUrls.VISUALIZATION },
    { text: BiomarkersText, icon: CoronavirusIcon, page: config.clientUrls.BIOMARKERS },
  ];
  const accessButtons = [
    { text: ModelsText, icon: ViewInArIcon, page: config.clientUrls.MODELS },
    { text: MyProjectText, icon: BiotechIcon, page: config.clientUrls.PROJECTS },
    { text: MyDataText, icon: StorageIcon, page: config.clientUrls.FILES },
  ];
  const secondaryButtons = [
    { text: AccountText, icon: PersonIcon, page: config.clientUrls.PROFILE },
    { text: HelpText, icon: HelpIcon, page: config.clientUrls.HELP },
  ];
  function HandleGoToPage(page) {
    history.push(page);
  }
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleListItemClick = (event, button, index) => {
    setSelectedIndex(index);
    HandleGoToPage(button.page);
  };

  const ListItemButtonStyle = {
    '&.Mui-selected': {
      color: theme.palette.secondary.main,
    },
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.primary.tinted,
    },
    ':hover': {
      backgroundColor: theme.palette.primary.tinted,
    },
    height: 50,
  };

  const AppBarElement = (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${drawerWidth}px)`,
        height: '65px',
        ml: `${drawerWidth}px`,
        backgroundColor: theme.palette.secondary.lightgrey,
        color: 'black',
        boxSizing: 'border-box',
      }}
    >
      <Toolbar sx={{ display: 'flex', position: 'sticky' }}>
        <Typography variant="h6" noWrap component="div">
          Home &gt;
        </Typography>
      </Toolbar>
    </AppBar>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Drawer
        className="mydrawer"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            fontSize: 20,
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <div className="topcontainer">
          <img src="/assets/img/logo-reverse-text.png" />
        </div>
        <Divider></Divider>
        <List>
          {mainButtons.map((button, index) => (
            <ListItem key={button.text} onClick={(event) => handleListItemClick(event, button, index)}>
              <ListItemButton selected={selectedIndex === index} sx={ListItemButtonStyle}>
                <ListItemIcon>{<button.icon sx={{ color: 'white' }} />}</ListItemIcon>
                <ListItemText primary={button.text} primaryTypographyProps={{ fontSize: buttonTextSize }} />
              </ListItemButton>
            </ListItem>
          ))}

          {productsButtons.map((button, index) => (
            <ListItem
              key={button.text}
              onClick={(event) => handleListItemClick(event, button, index + mainButtons.length)}
            >
              <ListItemButton selected={selectedIndex === index + mainButtons.length} sx={ListItemButtonStyle}>
                <ListItemIcon>{<button.icon sx={{ color: 'white' }} />}</ListItemIcon>
                <ListItemText primary={button.text} primaryTypographyProps={{ fontSize: buttonTextSize }} />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem>
            <ListItemButton onClick={handleClick} sx={ListItemButtonStyle}>
              <ListItemText primary="Workspace" primaryTypographyProps={{ fontSize: buttonTextSize }} />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List>
              {accessButtons.map((button, index) => (
                <ListItem
                  key={button.text}
                  onClick={(event) =>
                    handleListItemClick(event, button, index + mainButtons.length + productsButtons.length)
                  }
                >
                  <ListItemButton
                    selected={selectedIndex === index + mainButtons.length + productsButtons.length}
                    sx={{ ...ListItemButtonStyle, pl: 4 }}
                  >
                    <ListItemIcon>{<button.icon sx={{ color: 'white' }} />}</ListItemIcon>
                    <ListItemText primary={button.text} primaryTypographyProps={{ fontSize: buttonTextSize }} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
        <div height="auto"></div>

        <Divider />
        <List style={{ marginTop: 'auto' }}>
          {secondaryButtons.map((button, index) => (
            <ListItem
              key={button.text}
              onClick={(event) =>
                handleListItemClick(
                  event,
                  button,
                  index + mainButtons.length + productsButtons.length + accessButtons.length,
                )
              }
            >
              <ListItemButton
                selected={selectedIndex === index + mainButtons.length + productsButtons.length + accessButtons.length}
                sx={ListItemButtonStyle}
              >
                <ListItemIcon>{<button.icon sx={{ color: 'white' }} />}</ListItemIcon>
                <ListItemText primary={button.text} primaryTypographyProps={{ fontSize: buttonTextSize }} />
              </ListItemButton>
              <Divider />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <ListItem key={LogoutText} onClick={() => logoutWithRedirect()}>
            <ListItemButton sx={ListItemButtonStyle}>
              <ListItemIcon>{<LogoutIcon sx={{ color: 'white' }} />}</ListItemIcon>
              <ListItemText primary={LogoutText} primaryTypographyProps={{ fontSize: buttonTextSize }} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemText primary={user.email} primaryTypographyProps={{ fontSize: 14 }} />
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}
