import { Box, Button, Paper, Theme, Typography, useTheme } from "@mui/material";
import EnhancedTable from "_components/base/table/EnhancedTable";
import { useState } from "react";
import { ColumnRowType } from "./ManageGroupColumn";
import RowType, { RowElementType } from "_components/base/table/RowType";
import HeadCellType from "_components/base/table/HeadCellType";
import ButtonGroup from "_components/base/ButtonGroup";

const headCells: HeadCellType[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Groups',
    },
];

export interface SelectGroupValuesProps {
    selectedFilename: string;
    onSubmit: (groupValues: string[]) => void;
    onCancel: () => void;
    selectedFileSize?: number | null;
    selectedColumnData: ColumnRowType;
}
export default function SelectGroupValues({
    selectedFilename, selectedFileSize = 11677996, onSubmit, onCancel,
    selectedColumnData
}: SelectGroupValuesProps) {
    const [selected, setSelected] = useState<RowType[]>([]);
    const theme: Theme = useTheme();

    const rows = (selectedColumnData.unique_values || [])
        .map((name: string) => ({ name: name }))

    function handleSubmit() {
        if (selected.length < 1) return;
        onSubmit(selected.map((r: RowType) => r.name) as string[]);
    }
    return (
        <Box sx={{ width: '100%' }}>
            <Typography variant="body1" color="dark" sx={{ pb: 2, fontWeight: 800 }}>
                Select groups
            </Typography>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTable
                    idHeadCell={headCells.find(o => o.id == "name") || headCells[0]}
                    headCells={headCells}
                    rows={rows}
                    selected={selected}
                    setSelected={setSelected}
                    allowMultipleSelect={true}
                    tableSx={{
                        minWidth: 450,
                        "& .MuiTableRow-head.MuiTableRow-root": {
                            backgroundColor: theme.palette.tables.light
                        },
                        "& .MuiTableRow-root.Mui-selected": {
                            backgroundColor: theme.palette.tables.dark
                        },

                        "& .MuiTableBody-root>.MuiTableRow-root:hover": {
                            backgroundColor: theme.palette.tables.main
                        }

                    }}
                    tableHeadRowSx={{ backgroundColor: theme.palette.tables.light }}
                />
            </Paper>
            <ButtonGroup
                isNextVisible={true}
                isNextDisabled={selected.length < 1}
                isBackVisible={true}
                isCreateVisible={false}
                onBack={onCancel}
                onNext={handleSubmit}
                nextLabel="Confirm"
                backLabel="Cancel"
            />
        </Box>
    );


}