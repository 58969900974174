import { Box, Typography } from '@mui/material';
import { RowElementType } from '_components/base/table/RowType';
import { renderRowElement } from '_components/base/table/RowTypeAPI';
import ButtonGroup from '_components/base/ButtonGroup';
import { ColumnRowType } from './ColumnRowType';
import GroupValuesList from './GroupValuesList';

export interface VerifyGroupValuesProps {
  selectedFilename: RowElementType;
  controlValues?: string[];
  onSubmit: () => void;
  onCancel: () => void;
  selectedFileSize?: number | null;
  selectedColumnData: ColumnRowType;
}
export default function VerifyGroupValues({
  selectedFilename,
  selectedFileSize = 11677996,
  controlValues = [],
  onSubmit,
  onCancel,
  selectedColumnData,
}: VerifyGroupValuesProps) {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ pb: 2 }}>
        <Typography variant="body1" color="dark" sx={{ pb: 0, fontWeight: 800 }}>
          {renderRowElement(selectedFilename)}
        </Typography>
        <Typography variant="subtitle2" color="dark" fontStyle="italic">
          Group column: {selectedColumnData.name}
        </Typography>
        <Typography variant="subtitle2" color="dark" fontStyle="italic">
          {selectedColumnData.unique_values_no} groups found in column {selectedColumnData.name}
        </Typography>
      </Box>
      <GroupValuesList columnData={selectedColumnData} controlValues={controlValues} />

      <ButtonGroup
        isNextVisible={true}
        isBackVisible={true}
        isCreateVisible={false}
        onBack={onCancel}
        onNext={onSubmit}
        nextLabel="Confirm"
        backLabel="Cancel"
      />
    </Box>
  );
}
