import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Tooltip, useTheme } from '@mui/material';
import type { SxProps, Theme } from '@mui/material';

import HeadCellType from './HeadCellType';

import { visuallyHidden } from '@mui/utils';

export type Order = 'asc' | 'desc';

export interface EnhancedTableHeadProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: string /*keyof Data*/) => void;
    onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string | null;
    rowCount: number;
    headCells: HeadCellType[];
    allowMultipleSelect?: boolean;
    allowSelect?: boolean;
    tableHeadSx?: SxProps<Theme>;
    tableHeadRowSx?: SxProps<Theme>;
    tableHeadCellSx?: SxProps<Theme>;
}

export default function EnhancedTableHead({
    numSelected,
    onRequestSort,
    onSelectAllClick,
    order,
    orderBy,
    rowCount,
    headCells,
    allowMultipleSelect = true,
    allowSelect = true,
    tableHeadSx,
    tableHeadRowSx,
    tableHeadCellSx,
}: EnhancedTableHeadProps) {
    const createSortHandler = (property: string /*keyof Data*/) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    const theme: Theme = useTheme();
    return (
        <TableHead sx={tableHeadSx}>
            <TableRow sx={tableHeadRowSx || { backgroundColor: theme.palette.secondary.lightgrey }}>
                {allowSelect && (
                    <TableCell padding="checkbox" sx={tableHeadCellSx}>
                        {allowMultipleSelect && onSelectAllClick && (
                            <Checkbox
                                color="primary"
                                id="checkbox-header"
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={rowCount > 0 && numSelected === rowCount}
                                onChange={onSelectAllClick}
                                inputProps={{
                                    'aria-label': 'select all desserts',
                                }}
                            />
                        )}
                    </TableCell>
                )}
                {headCells.map((headCell) => (
                    <Tooltip title={headCell?.description} key={headCell.id}>
                        <TableCell
                            sx={headCell.headCellSx}
                            key={headCell.id}
                            align={headCell.headCellSx?.align || (headCell.numeric ? 'right' : 'left')}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {!headCell.isEmpty && (
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    <>
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                    </>
                                </TableSortLabel>
                            )}
                        </TableCell>
                    </Tooltip>
                ))}
            </TableRow>
        </TableHead>
    );
}
