import { Badge, Button, IconButton, Paper, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import './notepad.css'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import NotesIcon from '@mui/icons-material/Notes';
import CheckIcon from '@mui/icons-material/Check';
import moment from "moment"
import DeleteButton from "./base/DeleteButton";
import { useAuth0 } from "@auth0/auth0-react";
import projectsService from "_services/harbor/projects.service";
import { withServiceCallHandling } from "_helpers/decorators";
import { useIsMounted } from "_hooks/useIsMounted";
import { blue, green, grey, orange, pink, red, teal, yellow } from '@mui/material/colors';
import Radio from '@mui/material/Radio';

const COLORS = [
  { flash: blue[600], bg: blue[100] },
  { flash: teal[600], bg: teal[100] },
  { flash: green[600], bg: green[100] },
  { flash: yellow[600], bg: yellow[100] },
  { flash: orange[600], bg: orange[100] },
  { flash: red[600], bg: red[100] },
  { flash: pink[600], bg: pink[100] },
]

interface NotepadProps {
  projectId: string | undefined;
  projectNotes: Note[];
}

export interface Note {
  inputText: string;
  id: number;
  color: string;
}

interface NoteFrameProps {
  note: Note;
  handleDelete: Function;
  handleChange: Function;
}

interface ColorRadioButtonsProps {
  onChange: Function
  initialColor: string
}

function ColorRadioButtons({ onChange, initialColor }: ColorRadioButtonsProps) {
  const [selectedValue, setSelectedValue] = useState<string>(initialColor);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    onChange(event.target.value)
  };

  const controlProps = (item: string) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  return (
    <div>
      <Radio {...controlProps(grey[50])} sx={{
        color: grey[300],
        '&.Mui-checked': {
          color: grey[300],
        }
      }}
      />
      {COLORS.map((c) => {
        return (
          <Radio key={c.bg} {...controlProps(c.bg)} sx={{
            color: c.flash,
            '&.Mui-checked': {
              color: c.flash,
            }
          }}
          />
        )
      })}
    </div>
  );
}


function NoteFrame({ note, handleDelete, handleChange }: NoteFrameProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [noteText, setNoteText] = useState<string>(note.inputText);
  const [noteColor, setNoteColor] = useState<string>(note.color);

  function handleClickEditSave() {
    if (isEditing) {
      handleChange(note.id, noteText, noteColor)
    }
    setIsEditing(!isEditing)
  }

  function handleLocalChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setNoteText(event.currentTarget.value)
  }

  function handleLocalDelete() {
    handleDelete(note.id)
  }

  function handleLocalColorChange(color: string) {
    setNoteColor(color)
  }

  useEffect(() => {
    setNoteText(note.inputText)
  }, [note.id]);

  return (
    <Paper key={note.id} className='noteFrame' elevation={5} sx={{ backgroundColor: noteColor }}>
      {!isEditing && (
        <Typography>
          {noteText}
        </Typography>
      )}
      {isEditing && (
        <TextField
          label="Enter your note"
          value={noteText}
          onChange={(e) => handleLocalChange(e)}
          multiline
          fullWidth
          maxRows={4}
        />
      )}

      <Stack direction="row" alignItems='center' spacing={2} justifyContent='space-between' className="noteFrameBottom">
        <Stack direction="row" alignItems='center' spacing={2}>
          <Button variant={isEditing ? "contained" : "outlined"} onClick={() => handleClickEditSave()}>
            {(isEditing ? (<CheckIcon />) : (<EditIcon />))}
            {(isEditing ? 'Save' : 'Edit')}
          </Button>
          <DeleteButton handleDelete={handleLocalDelete} />
        </Stack>
        {isEditing && (
          <ColorRadioButtons onChange={(arg: string) => handleLocalColorChange(arg)} initialColor={noteColor} />
        )}
        <Typography align="right">
          Date created: {moment(new Date(note.id).toString()).format("ll")}
        </Typography>
      </Stack>
    </Paper >
  )
}

export default function Notepad({ projectId, projectNotes }: NotepadProps) {
  const [isOpened, setisOpened] = useState(false);
  const [notes, setNotes] = useState<Note[]>(projectNotes)
  const updateTriggered = useRef(false);
  const { getAccessTokenSilently } = useAuth0();
  const isMounted = useIsMounted();

  function addNewNote() {
    var newNote = { inputText: "New note", id: Date.now(), color: grey[50] }
    setNotes([...notes, newNote])
  }

  function handleDeleteNote(id: number) {
    setNotes(notes.filter(note => note.id !== id))
    updateTriggered.current = true
  }

  function handleEditNote(id: number, content: string, color: string) {
    setNotes(
      notes.map(note => (note.id === id ? { ...note, inputText: content, color: color } : note))
    );
    updateTriggered.current = true
  }

  async function updateNotesImpl() {
    if (projectId) {
      const accessToken = await getAccessTokenSilently();
      return projectsService.updateNotes(accessToken, projectId, notes)
    }
  }

  const updateNotes = withServiceCallHandling(
    updateNotesImpl,
    () => { },
    undefined,
    false,
    () => isMounted(),
  );

  useEffect(() => {
    if (updateTriggered.current) {
      updateNotes();
      updateTriggered.current = false;
    }
  }, [notes]);

  return (
    <Box className='notepadArea'>
      {(notes.length && (
        <Badge badgeContent={notes.length} color="secondary">
          <Button variant="contained" onClick={() => setisOpened(!isOpened)}>
            <NotesIcon />
            Notepad
          </Button>
        </Badge>
      )) || (
          <Button variant="contained" onClick={() => setisOpened(!isOpened)}>
            <NotesIcon />
            Notepad
          </Button>
        )}
      {isOpened &&
        <Paper className='notepadFrame' elevation={20} >

          {!notes.length && (
            <Typography>You do not have any notes for this project. Create one below.</Typography>
          )}
          {notes.map((n: Note, index: number) => {
            return (<NoteFrame key={index} handleChange={handleEditNote} handleDelete={handleDeleteNote} note={n} />);
          })}
          <Tooltip title="Add a new note">
            <IconButton onClick={() => addNewNote()}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Paper>
      }
    </Box >
  )
} 
