import { useAuth0 } from "@auth0/auth0-react";
import Typography from "@mui/material/Typography";
import fileService from "_services/files.service";
import { ReactElement, useEffect, useState } from "react";
import SelectGroupColumn from "./SelectGroupColumn";
import VerifyGroupValues from "./VerifyGroupValues";
import SelectGroupValues from "./SelectGroupValues";
import RowType, { RowElementType } from "_components/base/table/RowType";
import { useIsMounted } from "_hooks/useIsMounted";
import { withServiceCallHandling } from "_helpers/decorators";

export interface ManageGroupColumnProps {
    selectedUploadId: string;
    selectedFilenamePrefix: string,
    onSubmit: (selectedColumnName: string, selectedColumnValues: string[]) => void;
    onCancel: () => void;
    selectedFileSize?: number | null;
}

export interface ColumnRowType extends RowType {
    name: string;
    unique_values_no?: number;
    unique_values?: string[];
    valid_group: boolean;
    selectable?: boolean;
    [key: string]: string | number | boolean | string[] | undefined | ReactElement;
}

export default function ManageGroupColumn({
    selectedUploadId, selectedFilenamePrefix, selectedFileSize,
    onSubmit, onCancel,
}: ManageGroupColumnProps) {
    const { getAccessTokenSilently } = useAuth0();
    const isMounted = useIsMounted();
    const [doFilterRows, setDoFilterRows] = useState<boolean>(true);
    const [rows, setRows] = useState<ColumnRowType[]>([]);
    const [selectedColumn, setSelectedColumn] = useState<RowElementType | null>(null);
    const [isGroupConfirmed, setIsGroupConfirmed] = useState<boolean>(false);
    // could be useful if logic expands
    // const [selectedGroupValues, setSelectedGroupValues] = useState<string[]>([]);

    async function listFileUploadColumnsImpl() {
        if (!isMounted()) return;
        const accessToken = await getAccessTokenSilently();
        return fileService.listFileUploadColumns(accessToken, selectedUploadId);
    };

    const listFileUploadColumns = withServiceCallHandling(
        listFileUploadColumnsImpl,
        (data) => setRows(
            (data as ColumnRowType[])
                .map(row => ({ ...row, selectable: row.valid_group }))
        ),
        undefined, false,
        () => isMounted()
    );

    useEffect(() => {
        listFileUploadColumns();
    }, [selectedUploadId]);


    function handleSelectGroupValues(groupValues: string[]) {
        if (groupValues.length < 1 || !selectedColumn) return;
        onSubmit(selectedColumn as string, groupValues)
    }

    const filteredRows = doFilterRows ? rows.filter(row => row['unique_values_no']) : rows;

    const selectedColumnData = rows.find(row => row.name === selectedColumn);
    return (
        <>
            {!selectedColumn && (
                <SelectGroupColumn underTableCaption={
                    doFilterRows ? (
                        <Typography variant="caption">Only non-continuous columns are shown. <span style={{ cursor: 'pointer' }} onClick={() => setDoFilterRows(false)}><u>Show all</u></span>.</Typography>
                    ) : (
                        <Typography variant="caption">All columns are shown. <span style={{ cursor: 'pointer' }} onClick={() => setDoFilterRows(true)}><u>Show less</u></span>.</Typography>
                    )}
                    rows={filteredRows}
                    onCancel={onCancel} // completely cancel the file (go back to select the file)
                    onSubmit={(row: RowType) => { setSelectedColumn(row.name) }}
                    selectedFilename={selectedFilenamePrefix}
                    selectedFileSize={selectedFileSize}
                />
            )}
            {selectedColumn && !isGroupConfirmed && selectedColumnData && (
                <VerifyGroupValues
                    selectedFilename={selectedFilenamePrefix}
                    selectedFileSize={selectedFileSize}
                    selectedColumnData={selectedColumnData}
                    onCancel={() => setSelectedColumn(null)}
                    onSubmit={() => setIsGroupConfirmed(true)}
                />
            )}
            {selectedColumn && isGroupConfirmed && selectedColumnData && (
                <SelectGroupValues
                    selectedFilename={selectedFilenamePrefix}
                    selectedFileSize={selectedFileSize}
                    selectedColumnData={selectedColumnData}
                    onCancel={() => setIsGroupConfirmed(false)}
                    onSubmit={handleSelectGroupValues}
                />
            )}

        </>
    );


}