import { Paper, Theme, useTheme } from '@mui/material';
import BasicTable from '_components/base/table/BasicTable';
import { ColumnRowType } from './ColumnRowType';

const verifyHeadCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Groups',
  },
];

interface GroupValuesListProps {
  columnData: ColumnRowType;
  controlValues?: string[];
}
export default function GroupValuesList({ columnData, controlValues = [] }: GroupValuesListProps) {
  const theme: Theme = useTheme();
  const rows = (columnData.unique_values || []).sort().map((name: string) => {
    let newName = name;
    if (controlValues.includes(name)) newName = `${name} - Control group`;
    return { name: newName };
  });
  return (
    <Paper sx={{ width: '100%', mb: 0 }}>
      <BasicTable
        title=""
        rows={rows}
        headCells={verifyHeadCells}
        tableProps={{ size: 'small' }}
        tableSx={{
          minWidth: 450,
          '& .MuiTableRow-head.MuiTableRow-root': {
            backgroundColor: theme.palette.tables.light,
          },
          '& .MuiTableRow-root.Mui-selected': {
            backgroundColor: theme.palette.tables.dark,
          },

          '& .MuiTableBody-root>.MuiTableRow-root:hover': {
            backgroundColor: theme.palette.tables.main,
          },
        }}
      />
    </Paper>
  );
}
