import { Box, Button, Typography, useTheme, TextField, FormControl, Paper, FormLabel } from '@mui/material';
import { DiseaseFileFormControl, DISEASES } from '_components/content/SpecFileFormControls';
import { useEffect, useState } from 'react';
import baseModelsServices from '_services/harbor/basemodels.service';
import { useAuth0 } from '@auth0/auth0-react';
import { useIsMounted } from '_hooks/useIsMounted';
import EnhancedTable from '_components/base/table/EnhancedTable';
import RowType from '_components/base/table/RowType';
import HeadCellType from '_components/base/table/HeadCellType';
import FileUploads from '_pages/FileUploads/FileUploads';
import fileService from '_services/files.service';
import modelsService from '_services/harbor/models.service';
import { toast } from 'react-toastify';
import { withServiceCallHandling } from '_helpers/decorators';

const idHeadCell: HeadCellType = {
  id: '_id',
  numeric: false,
  disablePadding: false,
  label: 'ID',
};
const headCells: HeadCellType[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'current_version',
    numeric: false,
    disablePadding: true,
    label: 'Version',
    rowCellSx: { width: '10px' },
  },
  {
    id: 'created_at',
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: 'Date',
    alignRowCell: 'left',
  },
];

interface CreateModelRoutineProps {
  onCancel: () => void;
  onSubmit: (modelId: string, modelName: string) => void;
}

export default function CreateModelRoutine({ onCancel, onSubmit }: CreateModelRoutineProps) {
  const theme = useTheme();
  const [modelName, setModelName] = useState<string>('');
  const [disease, setDisease] = useState<string>(DISEASES[0]);
  const [baseModels, setBaseModels] = useState<RowType[]>([]);
  const [screen, setScreen] = useState<number>(0);
  const [selectedBaseModels, setSelectedBaseModels] = useState<RowType[]>([]);
  const [fileUploadRows, setFileUploadRows] = useState<RowType[]>([]);
  const [selectedFileUploadRows, setSelectedFileUploadRows] = useState<RowType[]>([]);
  const isMounted = useIsMounted();
  const { getAccessTokenSilently } = useAuth0();

  async function listBaseModelsImpl() {
    const accessToken = await getAccessTokenSilently();
    return baseModelsServices.listBaseModels(accessToken);
  }
  const listBaseModels = withServiceCallHandling(
    listBaseModelsImpl,
    (data) => setBaseModels(data),
    undefined,
    false,
    () => isMounted(),
  );

  async function listFileUploadsImpl() {
    const accessToken = await getAccessTokenSilently();
    return fileService.listFileUploads(accessToken);
  }
  const listUploads = withServiceCallHandling(
    listFileUploadsImpl,
    (data) => setFileUploadRows(data),
    undefined,
    false,
    () => isMounted(),
  );

  useEffect(() => {
    listBaseModels();
    listUploads();
  }, []);

  function handleNext() {
    setScreen(screen + 1);
  }
  function handleBack() {
    setScreen(screen - 1);
  }

  function handleModelNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setModelName(event.target.value);
  }

  async function createModelImpl() {
    if (!isMounted()) return;
    const accessToken = await getAccessTokenSilently();
    return modelsService.createModel(
      accessToken,
      modelName,
      disease,
      'baseline',
      selectedBaseModels[0][idHeadCell.id],
      selectedFileUploadRows.map((r) => r[idHeadCell.id]),
    );
  }
  const handleCreateModelClick = withServiceCallHandling(
    createModelImpl,
    (data) => onSubmit(data['_id'], modelName),
    undefined,
    false,
    () => isMounted(),
  );

  return (
    <>
      <Box>
        <Typography variant="h5" color="primary">
          Create model{modelName ? `: ${modelName}` : ''}
        </Typography>
      </Box>
      {screen === 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Box sx={{ flex: '1 2 auto', width: '100%' }}>
            <TextField
              id="standard-basic"
              label="Model Name"
              variant="standard"
              value={modelName}
              onChange={handleModelNameChange}
            />

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <DiseaseFileFormControl disease={disease} setDisease={setDisease} asSelect />
            </Box>
          </Box>
          <Box sx={{ flex: '1 1 auto', width: '100%' }}>
            <FormControl sx={{ width: '100%' }}>
              <FormLabel id="base-models-table">Base Models</FormLabel>
              <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTable
                  tableContainerRowSx={{ maxHeight: '60vh' }}
                  idHeadCell={idHeadCell}
                  headCells={headCells}
                  rows={baseModels}
                  selected={selectedBaseModels}
                  setSelected={setSelectedBaseModels}
                  allowMultipleSelect={false}
                  tableSx={{
                    minWidth: 250,
                    '& .MuiTableRow-head.MuiTableRow-root': {
                      backgroundColor: theme.palette.tables.light,
                    },
                    '& .MuiTableRow-root.Mui-selected': {
                      backgroundColor: theme.palette.tables.dark,
                    },

                    '& .MuiTableBody-root>.MuiTableRow-root:hover': {
                      backgroundColor: theme.palette.tables.main,
                    },
                  }}
                  // tableHeadRowSx={{ backgroundColor: theme.palette.tables.light }}
                  tableProps={{ size: 'small' }}
                />
              </Paper>
            </FormControl>
          </Box>
        </Box>
      )}
      {screen === 1 && (
        <FileUploads
          asPage={false}
          tableTitle="Add new or select existing dataset"
          allowRename={false}
          allowDelete={false}
          allowInsert={true}
          onRowsSelected={setSelectedFileUploadRows}
          initRows={fileUploadRows}
          initRowsSelected={selectedFileUploadRows}
        />
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pt: 2 }}>
        <Box>
          {screen < 1 && (
            <Button
              disabled={!modelName || selectedBaseModels.length < 1}
              onClick={handleNext}
              variant="outlined"
              sx={{ mr: 1 /*backgroundColor: theme.palette.primary.main*/ }}
            >
              Next
            </Button>
          )}
          {screen > 0 && (
            <Button
              onClick={handleBack}
              variant="outlined"
              sx={{ mr: 1 /*backgroundColor: theme.palette.primary.main*/ }}
            >
              Back
            </Button>
          )}
        </Box>
        <Box>
          {modelName && selectedBaseModels.length > 0 && selectedFileUploadRows.length > 0 && (
            <Button
              onClick={handleCreateModelClick}
              variant="contained"
              sx={{ mr: 1, backgroundColor: theme.palette.primary.main }}
            >
              Create
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
}
