import AnimationBackground from './AnimationBackground';
import './frontpage.scss';
import Button from '@mui/material/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Container, Typography } from '@mui/material';

const audience = process.env.REACT_APP_API_SERVER_URL;

function TagLine() {
    return (
        <Box className='tagline'>
            <Typography color={"#ffffff"} variant='h2' align='center'>
                Simmunome
                <Typography color={"#ffffff"} variant='h3' align='center'>
                    Move your pipeline forward, with confidence.
                </Typography>           </Typography>
        </Box>
    )
}

function Login() {
    const { loginWithRedirect } = useAuth0();
    return (

        <Box className='loginContainer'>
            <Button className='loginButton' color='secondary' variant='contained' onClick={() => loginWithRedirect()}>
                Login
            </Button>
        </Box>
    )
}

const App = () => {
    return (
        <Box sx={{ width: "100%", height: "100%", position: "fixed" }}>
            <TagLine />
            <Login />
            <AnimationBackground />
        </Box>
    )
}
export default App;
