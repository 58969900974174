import { Box, IconButton, Paper, Stack, Typography } from '@mui/material';
import { DataRecordType } from './DataRecordType';
import { extractPrefixFromFileUpload } from '_helpers/data';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GroupValuesList from './GroupValuesList';

interface DataBoxProps {
  dataRecord: DataRecordType;
  onDelete: (record: DataRecordType) => void;
  onUpdate: (record: DataRecordType) => void;
}

export default function DataBox({ dataRecord, onDelete, onUpdate }: DataBoxProps) {
  const prefix = extractPrefixFromFileUpload(dataRecord.fileUpload);
  return (
    <Paper sx={{ width: '100%', mt: 1, p: 1 }}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box>
          <Stack direction="row" spacing={2}>
            <Box mt={2} ml={1}>
              <CheckCircleIcon sx={{ color: 'green' }} fontSize="large" />
            </Box>
            <Stack>
              <Typography variant="h5" display="inline">
                {prefix}
              </Typography>

              <Typography variant="subtitle2" color="dark" fontStyle="italic">
                Modality: {dataRecord.fileUpload?.modality as string}
              </Typography>

              <Typography variant="subtitle2" color="dark" fontStyle="italic">
                Platform: {dataRecord.fileUpload?.platform as string}
              </Typography>

              <Typography variant="subtitle2" color="dark" fontStyle="italic">
                Group column: {(dataRecord?.groupColumn as string) || ''}
              </Typography>

              <Typography variant="subtitle2" color="dark" fontStyle="italic">
                {/* Group column: {(dataRecord?.columnData as string) || ''} */}
                {(dataRecord?.columnData.unique_values_no as number) || '0'} unique groups found in column{' '}
                {(dataRecord?.groupColumn as string) || ''}
              </Typography>

              <Box sx={{ width: '100%', maxWidth: 400 }}>
                <GroupValuesList columnData={dataRecord.columnData} />
              </Box>
            </Stack>
          </Stack>
        </Box>

        <Box mt={2}>
          <IconButton size="small" onClick={() => onUpdate(dataRecord)}>
            <EditIcon />
          </IconButton>
          <IconButton size="small" onClick={() => onDelete(dataRecord)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
}
