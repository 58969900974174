import { Box, Button, Stack, Typography } from '@mui/material';
import EnhancedTable from '_components/base/table/EnhancedTable';
import HeadCellType from '_components/base/table/HeadCellType';
import RowType from '_components/base/table/RowType';
import { extractPrefixFromFileUpload } from '_helpers/data';
import { useState } from 'react';
import BMRoutineStore from './BMRoutineStore';
import { useIsMounted } from '_hooks/useIsMounted';
import { useAuth0 } from '@auth0/auth0-react';
import projectsService from '_services/harbor/projects.service';
import { withAPICall } from '_helpers/decorators';
import history from '_helpers/history';
import config from '_configs';
import biomarkerService from '_services/biomarker.service';
import SSEStore from '_helpers/SSEStore';
import { TaskType } from '_components/sse/TaskTypesEnum';

const headCells: HeadCellType[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Modalities',
    rowCellSx: { px: 2, minWidth: '100px' },
  },
];
const groupHeadCells: HeadCellType[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Groups',
    rowCellSx: { px: 2, minWidth: '100px' },
  },
];
const idHeadCell: HeadCellType = {
  id: 'name',
  numeric: false,
  disablePadding: true,
  label: 'Modality',
};

export default function AnalysisDataSelectorRoutine() {
  const isMounted = useIsMounted();
  const { getAccessTokenSilently } = useAuth0();
  const projectName = BMRoutineStore((state) => state.projectName);
  const dataRecords = BMRoutineStore((state) => state.dataRecords);
  const recGroups = BMRoutineStore((state) => state.recGroups);
  const resetBMProject = BMRoutineStore((state) => state.reset);
  const addSSEChannel = SSEStore((state) => state.add_channel);

  const availableFileUploadIds = [...new Set(recGroups.flatMap((gr) => gr.dataGroups.map((dg) => dg[0]._id)))];

  interface FileUploadDictAccumulator {
    [key: string]: { prefix: string; modality: string; platform: string };
  }

  const fileUploadDict = dataRecords
    .filter((record) => availableFileUploadIds.includes(record.fileUpload?._id as string))
    .reduce<FileUploadDictAccumulator>((acc, record) => {
      const prefix = extractPrefixFromFileUpload(record.fileUpload);
      acc[record.fileUpload?._id as string] = {
        prefix,
        modality: record.fileUpload?.modality as string,
        platform: record.fileUpload?.platform as string,
      };
      return acc;
    }, {});

  const modalityRows: RowType[] = availableFileUploadIds.map((fileUploadId) => {
    return { name: fileUploadDict[fileUploadId as string].modality };
  });

  const groupRows: RowType[] = recGroups.flatMap((group) => ({ name: group.name }));

  const [selectedModalities, setSelectedModalities] = useState<RowType[]>(modalityRows);
  const [selectedGroups, setSelectedGroups] = useState<RowType[]>(groupRows);

  const handleClickGenerateAnalysis = withAPICall(
    getAccessTokenSilently,
    [
      (accessToken) => {
        const dataGroupInfo: DataGroupItemInfoType[] = dataRecords.map((record) => {
          return {
            _id: record.fileUpload?._id as string,
            groupColumn: record.groupColumn as string,
          };
        });
        const modalitiesFiltered = selectedModalities.map((mod) => mod.name);
        const groupsFiltered = selectedGroups.map((group) => group.name);
        return projectsService.createBMProject(accessToken, projectName, {
          recGroups: recGroups.map((gr) => ({ ...gr, dataGroups: gr.dataGroups.map((dgr) => [dgr[0]._id, dgr[1]]) })),
          dataGroupInfo,
          modalitiesFiltered,
          groupsFiltered,
        });
      },
      (accessToken, apiResponse) => biomarkerService.startMotifMiningPipeline(accessToken, apiResponse?.data._id),
      (accessToken, apiResponse) => {
        const sse_channel = apiResponse?.sse_channel;
        const closeable = apiResponse?.data.closeable || false;
        const projectId = apiResponse?.data._id || undefined;
        if (!sse_channel) throw Error('Missing field: sse_channel');
        const failCallback = () => {
          console.error('ERROR SSE');
        };
        const successCallback = () => {
          console.log('SUCCESS SSE');
        };
        addSSEChannel(
          sse_channel,
          biomarkerService.URLS.sseRunBMPipeline,
          closeable,
          TaskType.Biomarker,
          projectId,
          failCallback,
          successCallback,
        );
        return;
      },
    ],
    (results) => {
      resetBMProject();
      const bmData = results[0]?.data;
      history.push(config.clientUrls.BIOMARKERS_PROJECT(bmData._id));
    },
    undefined,
    false,
    () => isMounted(),
  );

  return (
    <Box minWidth="900px">
      <Stack spacing={2} sx={{ pb: 2 }}>
        <Typography variant="h4">{projectName}</Typography>
      </Stack>

      <Typography variant="h6" sx={{ pb: 1 }}>
        Select data to include in the analysis
      </Typography>

      <Box sx={{ pb: 4 }}>
        <Box sx={{ pb: 1 }}>
          <Typography variant="body1" display="inline">
            1. Select modalities -{' '}
          </Typography>
          <Typography variant="body1" display="inline" fontStyle="italic">
            {' '}
            {selectedModalities.length} selected
          </Typography>
        </Box>

        <EnhancedTable
          rows={modalityRows}
          headCells={headCells}
          idHeadCell={idHeadCell}
          selected={selectedModalities}
          setSelected={setSelectedModalities}
        />
      </Box>

      <Box sx={{ pb: 4 }}>
        <Box sx={{ pb: 1 }}>
          <Typography variant="body1" display="inline">
            2. Select groups -{' '}
          </Typography>
          <Typography variant="body1" display="inline" fontStyle="italic">
            {' '}
            {selectedGroups.length} selected
          </Typography>
        </Box>
        <EnhancedTable
          rows={groupRows}
          headCells={groupHeadCells}
          idHeadCell={idHeadCell}
          selected={selectedGroups}
          setSelected={setSelectedGroups}
        />
      </Box>

      <Button variant="contained" onClick={handleClickGenerateAnalysis}>
        Generate analysis
      </Button>
    </Box>
  );
}
