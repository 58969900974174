import ButtonGroup from '_components/base/ButtonGroup';
import RowType, { RowElementType } from '_components/base/table/RowType';
import { useIsMounted } from '_hooks/useIsMounted';
import FileUploads from '_pages/FileUploads/FileUploads';

import { useEffect, useState } from 'react';
import ManageGroupColumn from './ManageGroupColumn';
import { withServiceCallHandling } from '_helpers/decorators';
import fileService from '_services/files.service';
import { useAuth0 } from '@auth0/auth0-react';
import { DataRecordType } from './DataRecordType';
import { extractPrefixFromFileUpload } from '_helpers/data';
import { ColumnRowType } from './ColumnRowType';

interface AddModifyDataRoutineProps {
  onSubmit: (fileUpload: RowType, groupColumn: RowElementType, columnData: ColumnRowType) => void;
  onCancel: () => void;
  initData: DataRecordType | null;
}

enum ScreenTypes {
  Dataset = 1,
  Group = 2,
}

export default function AddModifyDataRoutine({ initData, onSubmit, onCancel }: AddModifyDataRoutineProps) {
  const isMounted = useIsMounted();
  // In case of the update - automatically select previously selected fileupload
  const [selectedFileUploadsRows, setSelectedFileUploadsRows] = useState<RowType[]>(
    initData?.fileUpload ? [initData.fileUpload] : [],
  );
  const [screen, setScreen] = useState<ScreenTypes>(initData ? ScreenTypes.Group : ScreenTypes.Dataset);
  const [fileUploadRows, setFileUploadRows] = useState<RowType[]>([]);
  const { getAccessTokenSilently } = useAuth0();

  async function listUploadsImpl() {
    const accessToken = await getAccessTokenSilently();
    return fileService.listFileUploads(accessToken);
  }

  const updateMode: boolean = initData !== null;

  const listUploads = withServiceCallHandling(
    listUploadsImpl,
    (data) => {
      setFileUploadRows(data);
    },
    undefined,
    false,
    () => isMounted(),
  );

  useEffect(() => {
    listUploads();
  }, []);

  let body;
  if (screen === ScreenTypes.Dataset) {
    body = (
      <>
        <FileUploads
          asPage={false}
          tableTitle="Add new or select existing dataset"
          allowDelete={false}
          allowMultipleSelect={false}
          allowRename={false}
          initRows={fileUploadRows}
          initRowsSelected={selectedFileUploadsRows}
          onRowsSelected={setSelectedFileUploadsRows}
        />
        <ButtonGroup
          isNextVisible={true}
          isBackVisible={false}
          isCreateVisible={false}
          isNextDisabled={selectedFileUploadsRows.length !== 1}
          onNext={() => setScreen(ScreenTypes.Group)}
          onBack={onCancel}
        />
      </>
    );
  } else if (screen === ScreenTypes.Group) {
    if (selectedFileUploadsRows.length === 1) {
      const selectedRow = selectedFileUploadsRows[0];
      const prefix = extractPrefixFromFileUpload(selectedRow);
      const selectedUploadId = selectedRow._id as string;
      body = (
        <ManageGroupColumn
          selectedUploadId={selectedUploadId}
          selectedFileSize={selectedRow.size as number}
          selectedFilenamePrefix={prefix}
          onSubmit={(groupColumn, columnData) => onSubmit(selectedRow, groupColumn, columnData)}
          onCancel={() => setScreen(ScreenTypes.Dataset)}
          initData={{
            selectedColumn: initData?.groupColumn,
          }}
        />
      );
    }
  }

  return <>{body}</>;
}
