import { useEffect, useRef } from 'react';

export const useAbortOnUnload = () => {
    const abortControllerRef = useRef<AbortController | null>(null);

    useEffect(() => {
        const handleBeforeUnload = () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort("unmounted");
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return abortControllerRef;
};