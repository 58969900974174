import React, { ReactNode } from "react";
import { RowElementType } from "_components/base/table/RowType";
import { isInternalFileObjArrayType } from "_components/base/table/InternalFileObjAPI";
import { isInternalIdNameObjArrayType } from "_components/base/table/InternalIdNameObjAPI";

export function isReactNode(value: any): value is ReactNode {
    return typeof value === "string" || typeof value === "number" || typeof value === "boolean" || value instanceof Array || React.isValidElement(value);
}

export function renderRowElement(el: RowElementType): ReactNode {
    if (isReactNode(el)) {
        return el;
    } else if (isInternalFileObjArrayType(el)) {
        return JSON.stringify(el);
    } else if (isInternalIdNameObjArrayType(el)) {
        return JSON.stringify(el);
    } else {
        return null;
    }
}