import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface AlertDialogProps {
    open: boolean;
    onYes: () => void;
    onNo: () => void;
    yesLabel?: string;
    noLabel?: string;
    title: string;
    message: string;
}

export default function AlertDialog({ open, onNo, onYes, title, message, yesLabel = "Yes", noLabel = "No" }: AlertDialogProps) {

    return (
        <Dialog
            open={open}
            onClose={onNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onNo}>{noLabel}</Button>
                <Button onClick={onYes} autoFocus>
                    {yesLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
}