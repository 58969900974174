const TEXT_COLOR = '#000000';
const MAX_LINE_WIDTH_DEFAUL = 300;
const TRIM_WORDS_DEFAULT = 6;

/**
 * Takes first @n words
 * @param {String} text
 * @param {Number} n
 */
function trimNWords(text, n) {
  if (!text) return null;
  const words = text.split(' ');
  return words.slice(0, n).join(' ') + (words.length > n ? '...' : '');
}

/**
 * Split text into lines of text of at most specific length in pixels
 * @param {CanvasRenderingContext2D} ctx
 * @param {String} text
 * @param {Number} maxWidth
 * @returns
 */
function getLines(ctx, text, maxWidth) {
  const words = text.split(' ');
  const lines = [];
  let currentLine = words[0];
  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    const { width } = ctx.measureText(`${currentLine} ${word}`);
    if (width < maxWidth) {
      currentLine += ` ${word}`;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }
  lines.push(currentLine);
  return lines;
}

/**
 * This function draw in the input canvas 2D context a rectangle.
 * It only deals with tracing the path, and does not fill or stroke.
 * @param {CanvasRenderingContext2D} ctx
 * @param {number} x
 * @param {number} y
 * @param {number} width
 * @param {number} height
 * @param {number} radius
 */
export function drawRoundRect(ctx, x, y, width, height, radius) {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
}

/**
 * Custom hover renderer,
 * accepts full annotations (no trim) and wraps
 * @param {CanvasRenderingContext2D} context
 * @param {PlainObject} data
 * @param {Settings} settings
 * @param {Number} wrapWidth [optional]
 */
export function drawHoverFullWrap(context, data, settings, wrapWidth = MAX_LINE_WIDTH_DEFAUL) {
  const size = settings.labelSize;
  const font = settings.labelFont;
  const weight = settings.labelWeight;
  const stringIdSize = size - 2;
  const { label } = data;
  const { stringId } = data;
  const annotationLines = getLines(context, data.annotation, wrapWidth);
  const maxAnnLine = annotationLines.reduce(
    (a, b) => (a.length > b.length ? a : b),
  );

  // Then we draw the label background
  context.beginPath();
  context.fillStyle = '#fff';
  context.shadowOffsetX = 0;
  context.shadowOffsetY = 2;
  context.shadowBlur = 8;
  context.shadowColor = '#000';

  context.font = `${weight} ${size}px ${font}`;
  const labelWidth = context.measureText(label).width;
  context.font = `${weight} ${stringIdSize}px ${font}`;
  const stringIdWidth = stringId ? context.measureText(stringId).width : 0;
  context.font = `${weight} ${stringIdSize}px ${font}`;
  const annotationLabelWidth = maxAnnLine ? context.measureText(maxAnnLine).width : 0;

  const textWidth = Math.max(labelWidth, stringIdWidth, annotationLabelWidth);

  const x = Math.round(data.x);
  const y = Math.round(data.y);
  const w = Math.round(textWidth + size / 2 + data.size + 3);
  const hLabel = Math.round(size / 2 + 4);
  const hSubLabel = stringId ? Math.round(stringIdSize / 2 + 9) : 0;
  const hAnnotationLabel = Math.round(stringIdSize / 2 + 9) * annotationLines.length;

  drawRoundRect(context, x, y - hSubLabel - 12, w, hAnnotationLabel + hLabel + hSubLabel + 12, 5);
  context.closePath();
  context.fill();

  context.shadowOffsetX = 0;
  context.shadowOffsetY = 0;
  context.shadowBlur = 0;

  // And finally we draw the labels
  context.fillStyle = TEXT_COLOR;
  context.font = `${weight} ${size}px ${font}`;
  context.fillText(label, data.x + data.size + 3, data.y + size / 3);

  if (stringId) {
    context.fillStyle = TEXT_COLOR;
    context.font = `${weight} ${stringIdSize}px ${font}`;
    context.fillText(stringId, data.x + data.size + 3, data.y - (2 * size) / 3 - 2);
  }

  context.fillStyle = data.color;
  context.font = `${weight} ${stringIdSize}px ${font}`;
  for (let i = 0; i < annotationLines.length; i++) {
    const line = annotationLines[i];
    context.fillText(
      line,
      data.x + data.size + 3,
      data.y + size / 3 + (3 + stringIdSize) * (i + 1),
    );
  }
}

/**
 * Custom hover renderer,
 * Trim annotation to @trimWords words, keeping as single line
 * @todo merge with drawHoverFullWrap() as it reuses a lot of parts
 * @param {CanvasRenderingContext2D} context
 * @param {PlainObject} data
 * @param {Settings} settings
 * @param {Number} trimWordsCount [optional]
 */
export function drawHover(context, data, settings, trimWordsCount = TRIM_WORDS_DEFAULT) {
  const size = settings.labelSize;
  const font = settings.labelFont;
  const weight = settings.labelWeight;
  const stringIdSize = size - 2;
  const { label } = data;
  const { stringId } = data;
  const annotation = trimNWords(data.annotation, trimWordsCount);

  // Then we draw the label background
  context.beginPath();
  context.fillStyle = '#fff';
  context.shadowOffsetX = 0;
  context.shadowOffsetY = 2;
  context.shadowBlur = 8;
  context.shadowColor = '#000';

  context.font = `${weight} ${size}px ${font}`;
  const labelWidth = context.measureText(label).width;
  context.font = `${weight} ${stringIdSize}px ${font}`;
  const stringIdWidth = stringId ? context.measureText(stringId).width : 0;
  context.font = `${weight} ${stringIdSize}px ${font}`;
  const annotationLabelWidth = annotation ? context.measureText(annotation).width : 0;

  const textWidth = Math.max(labelWidth, stringIdWidth, annotationLabelWidth);

  const x = Math.round(data.x);
  const y = Math.round(data.y);
  const w = Math.round(textWidth + size / 2 + data.size + 3);
  const hLabel = Math.round(size / 2 + 4);
  const hSubLabel = stringId ? Math.round(stringIdSize / 2 + 9) : 0;
  const hAnnotationLabel = Math.round(stringIdSize / 2 + 9);

  drawRoundRect(context, x, y - hSubLabel - 12, w, hAnnotationLabel + hLabel + hSubLabel + 12, 5);
  context.closePath();
  context.fill();

  context.shadowOffsetX = 0;
  context.shadowOffsetY = 0;
  context.shadowBlur = 0;

  // And finally we draw the labels
  context.fillStyle = TEXT_COLOR;
  context.font = `${weight} ${size}px ${font}`;
  context.fillText(label, data.x + data.size + 3, data.y + size / 3);

  if (stringId) {
    context.fillStyle = TEXT_COLOR;
    context.font = `${weight} ${stringIdSize}px ${font}`;
    context.fillText(stringId, data.x + data.size + 3, data.y - (2 * size) / 3 - 2);
  }

  context.fillStyle = data.color;
  context.font = `${weight} ${stringIdSize}px ${font}`;
  context.fillText(
    annotation,
    data.x + data.size + 3,
    data.y + size / 3 + (3 + stringIdSize),
  );
}

/**
 * Custom label renderer
 * @param {CanvasRenderingContext2D} context
 * @param {PartialButFor<NodeDisplayData, "x" | "y" | "size" | "label" | "color">} data
 * @param {Settings} settings
 */
export default function drawLabel(context, data, settings) {
  if (!data.label) return;

  const size = settings.labelSize;
  const font = settings.labelFont;
  const weight = settings.labelWeight;

  context.font = `${weight} ${size}px ${font}`;
  const width = context.measureText(data.label).width + 8;

  context.fillStyle = '#ffffffcc';
  context.fillRect(data.x + data.size, data.y + size / 3 - 15, width, 20);

  context.fillStyle = '#000';
  context.fillText(data.label, data.x + data.size + 3, data.y + size / 3);
}
