import SSEStore, { SSEChannelType } from '_helpers/SSEStore';
import SSEListener from './SSEListener';
import { useEffect } from 'react';
import taskServices from '_services/harbor/tasks.service';
import { useAuth0 } from '@auth0/auth0-react';
import { withAPICall } from '_helpers/decorators';
import { TaskType } from './TaskTypesEnum';
import biomarkerService from '_services/biomarker.service';
import fileService from '_services/files.service';
import { Typography } from '@mui/material';
import config from '_configs';
import { Link } from 'react-router-dom';

const taskTypeToUrl = {
  [TaskType.Biomarker]: biomarkerService.URLS.sseRunBMPipeline,
  [TaskType.Preprocessing]: fileService.URLS.sseUploadFiles,
};

export default function SSEHub() {
  const { getAccessTokenSilently } = useAuth0();
  const replace_channels = SSEStore((state) => state.replace_channels);
  const handleListTasks = withAPICall(
    getAccessTokenSilently,
    [(accessToken) => taskServices.listActiveTasks(accessToken)],
    (results) => {
      const newTasks = results[0]?.data.tasks.map((task: any) => {
        const taskType = task.type as TaskType;
        const url = taskTypeToUrl[taskType];
        const id = task.event_type;
        const projectId = task.project_id;
        const closeable = task.closeable || false;
        return { id, url, closeable, taskType, projectId };
      });
      replace_channels(newTasks);
    },
  );
  useEffect(() => {
    handleListTasks();
  }, []);
  const sseChannels = SSEStore((state) => state.sse_channels);

  function getSuccessMessage(channel: SSEChannelType) {
    let successMessage;
    if (channel.taskType === TaskType.Biomarker) {
      successMessage = () => (
        <Typography variant="body1">
          Motif mining finished, results available{' '}
          <Link to={config.clientUrls.BIOMARKERS_PROJECT(channel.projectId)}>here</Link>
        </Typography>
      );
    }
    return successMessage;
  }
  return sseChannels.map((ch) => (
    <SSEListener
      channelId={ch.id}
      key={ch.id}
      url={ch.url}
      failCallback={ch.failCallback}
      successCallback={ch.successCallback}
      closeable={ch.closeable}
      successMessage={getSuccessMessage(ch)}
    />
  ));
}
