import { useState, ReactNode, ComponentType } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RenamePrompt from '_components/content/RenamePrompt';
import { IconButton, Menu, MenuItem } from '@mui/material';
import HeadCellType from '_components/base/table/HeadCellType';
import BasicModal from '_components/base/BasicModal';
import EnhancedTableToolbar from '_components/base/table/EnhancedTableToolbar';
import EnhancedTable from '_components/base/table/EnhancedTable';
import RowType from '_components/base/table/RowType';
import InternalFileObjType from '_components/base/table/InternalFileObj';
import { isInternalFileObjArrayType } from '_components/base/table/InternalFileObjAPI';

const styles = {
    uploadModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    },
    optionsModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    },
};

type OptionKindsType = {
    [key: string]: string;
};

// Add new option names here
const OPTION_KINDS: OptionKindsType = {
    rename: 'rename',
};

export type ContentTableToolbarComponentType = ComponentType<{
    selected: string[];
    permanentToolbarIcons?: ReactNode;
    selectedToolbarIcons?: ReactNode;
}>;

export interface ContentTableProps {
    idHeadCell: HeadCellType;
    headCells: HeadCellType[];
    entityName: string;
    tableTitle: string;
    rows?: RowType[];
    rowsSelected: RowType[];
    onRowsSelected: (rows: RowType[]) => any;
    renameRecordFunction?: (row: RowType, name: string, newName: string) => Promise<boolean>;
    InsertRecordComponent?: ReactNode;
    DeleteRecordComponent?: ReactNode;
    allowMultipleSelect?: boolean;
    allowFilter?: boolean;
    allowSelect?: boolean;
}

type OptionsModalType = {
    open: boolean;
    kind: string | null;
};

interface RowFilter {
    columnId: string;
    value: string;
}

export default function ContentTable({
    idHeadCell,
    headCells,
    entityName,
    tableTitle,
    rows = [],
    rowsSelected = [],
    onRowsSelected,
    renameRecordFunction,
    InsertRecordComponent,
    DeleteRecordComponent,
    allowMultipleSelect = true,
    allowFilter = true,
    allowSelect = true,
}: ContentTableProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [rowFilters, setRowFilters] = useState<RowFilter[]>([]);

    const [optionsModal, setOptionsModal] = useState<OptionsModalType>({
        open: false,
        kind: null,
    });

    const allowRowOptions: boolean = Boolean(renameRecordFunction);

    const handleCloseOptionModal = () => {
        setOptionsModal({ open: false, kind: null });
        handleCloseOptionsDropdown();
    };

    function setRowsSelected(selected: RowType[]) {
        onRowsSelected(selected);
    }

    async function handleRename(row: RowType, name: string, newName: string) {
        if (!renameRecordFunction) return;
        const finished = await renameRecordFunction(row, name, newName);
        if (finished) handleCloseOptionModal();
    }

    const permanentToolbarIcons: ReactNode = <>{InsertRecordComponent}</>;
    const selectedToolbarIcons: ReactNode = <>{DeleteRecordComponent}</>;

    const optionsHeadCell: HeadCellType = {
        isEmpty: true,
        // to satisfy type requirements for HeadCellType
        id: 'optionsDropdown',
        numeric: false,
        disablePadding: true,
        isSelfClickable: true,
        label: 'optionsDropdown',
        alignRowCell: 'right',
    };

    const columnNames = headCells
        .filter((c) => !c.isEmpty && !c.isSelfClickable)
        .map((c) => ({ label: c.label, id: c.id }));
    let headCellsImpl = allowRowOptions ? [...headCells, optionsHeadCell] : headCells;

    function handleClickOptionsIcon(event: React.MouseEvent<HTMLElement>, row: RowType) {
        setAnchorEl(event.currentTarget);
        // select row to visually show the target
        setRowsSelected([row]);
    }

    function handleCloseOptionsDropdown() {
        setAnchorEl(null);
        setRowsSelected([]);
    }

    function handleClickOption(kind: string | null) {
        setOptionsModal({ open: true, kind });
    }

    /* Can be modified to the multiple criterion */
    function handleFilterData(columnId: string, filterValue: string) {
        setRowFilters([{ columnId: columnId, value: filterValue }]);
    }

    let rowsImpl = rows.filter((row) => {
        return rowFilters.every(({ columnId, value }) => {
            const elm = row[columnId];
            if (elm && isInternalFileObjArrayType(elm)) {
                return (elm as InternalFileObjType[]).some((obj) =>
                    obj._id?.toString()?.toLowerCase().includes(value.toLowerCase()),
                );
            }
            return elm?.toString()?.toLowerCase().includes(value.toLowerCase());
        });
    });
    if (allowRowOptions) {
        rowsImpl = rowsImpl.map((row) => ({
            ...row,
            optionsDropdown: (
                <>
                    <IconButton onClick={(e) => handleClickOptionsIcon(e, row)}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(
                            anchorEl &&
                                rowsSelected.length === 1 &&
                                rowsSelected[0][idHeadCell.id] === row[idHeadCell.id],
                        )}
                        onClose={handleCloseOptionsDropdown}
                    >
                        <MenuItem onClick={() => handleClickOption(OPTION_KINDS.rename)}>Rename</MenuItem>
                    </Menu>
                </>
            ),
        }));
    }

    const OptionsModals: ReactNode = (
        <>
            <BasicModal
                open={optionsModal.open}
                onClose={handleCloseOptionModal}
                aria-labelledby="options-modal-title"
                aria-describedby="options-modal-description"
                boxSx={styles.optionsModal}
            >
                <>
                    {optionsModal.kind === OPTION_KINDS.rename && rowsSelected.length === 1 && (
                        <RenamePrompt
                            onCancel={handleCloseOptionModal}
                            onSubmit={(name: string, newName: string) => handleRename(rowsSelected[0], name, newName)}
                            originalName={rowsSelected[0].name}
                            title={`Rename ${entityName}`}
                            label="Name"
                        />
                    )}
                </>
            </BasicModal>
        </>
    );

    return (
        <>
            {OptionsModals}
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar
                        selected={rowsSelected}
                        permanentToolbarIcons={permanentToolbarIcons}
                        selectedToolbarIcons={selectedToolbarIcons}
                        title={tableTitle}
                        filterColumnNames={columnNames}
                        filterDefaultColumn={columnNames[0]}
                        onFilter={allowFilter ? handleFilterData : undefined}
                        allowMultipleSelect={allowMultipleSelect}
                        allowSelect={allowSelect}
                    />
                    <EnhancedTable
                        idHeadCell={idHeadCell}
                        headCells={headCellsImpl}
                        rows={rowsImpl}
                        selected={rowsSelected}
                        setSelected={setRowsSelected}
                        allowMultipleSelect={allowMultipleSelect}
                        allowSelect={allowSelect}
                    />
                </Paper>
            </Box>
        </>
    );
}
