import { Tooltip, Typography } from "@mui/material";
import { RowElementType } from "_components/base/table/RowType";
import moment from "moment";

interface CellDateProps {
    value: RowElementType;
}
export default function CellDate({ value }: CellDateProps) {
    // tooltip displays the date + time and default text only displays date
    // https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
    return (
        <Tooltip title={moment(value as string).format("llll")}>
            <Typography variant="body2">{moment(value as string).format("ll")}</Typography>
        </Tooltip >
    );
}