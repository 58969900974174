import FileFormControl from "_components/base/FileFormControl";
import { useState } from "react";

export const PLATFORMS: { [key: string]: string[] } = {
    'Proteomics': ['FragPipe', 'MaxQuant', 'mzTab', 'ProteomeDiscoverer', 'Skyline', 'Custom Template'],
    'Transcriptomics': ['FragPipe', 'MaxQuant', 'mzTab', 'ProteomeDiscoverer', 'Skyline', 'counts table', 'Custom Template'],
    'Metabolomics': ['MetabolomicsPlatform', 'Custom Template'],
    'Lipidomics': ['LipidomicsPlatform', 'Custom Template'],
}

export const DISEASES: string[] = ['Breast_Cancer', 'Other'];
export const MODALITIES: string[] = Object.keys(PLATFORMS);


interface DiseaseFileFormControlProps {
    disease: string;
    setDisease: (disease: string) => void,
    asSelect?: boolean;
}
export function DiseaseFileFormControl({ disease, setDisease, asSelect = false }: DiseaseFileFormControlProps) {
    return <FileFormControl
        label="Disease"
        choices={DISEASES}
        stateVariable={disease}
        setVariableStateFunction={setDisease}
        asSelect={asSelect}
    />
}

interface PlatformFileFormControlProps {
    platform: string;
    setPlatform: (platform: string) => void;
    asSelect?: boolean;
    platforms?: string[];
}
export function PlatformFileFormControl({ platforms = [], platform, setPlatform, asSelect }: PlatformFileFormControlProps) {
    return <FileFormControl
        label="Platform"
        choices={platforms}
        stateVariable={platform}
        setVariableStateFunction={setPlatform}
        asSelect={asSelect}
    />
}

interface ModalityFileFormControlProps {
    modality: string;
    setModality: (platform: string) => void;
    asSelect?: boolean;
}
export function ModalityFileFormControl({ modality, setModality, asSelect }: ModalityFileFormControlProps) {
    return <FileFormControl
        label="Modality"
        choices={MODALITIES}
        stateVariable={modality}
        setVariableStateFunction={setModality}
        asSelect={asSelect}
    />
}


export function TrioFileFormControl({ disease, setDisease, modality, setModality, platform, setPlatform, asSelect }: (ModalityFileFormControlProps & DiseaseFileFormControlProps & PlatformFileFormControlProps)) {
    const [availablePlatforms, setAvailablePlatforms] = useState(PLATFORMS[modality]);


    function handleModalityChange(modality: string) {
        setModality(modality);
        setAvailablePlatforms(PLATFORMS[modality]);
        setPlatform(PLATFORMS[modality][0]);
    }

    return <>
        <DiseaseFileFormControl disease={disease} setDisease={setDisease} asSelect={asSelect} />
        <ModalityFileFormControl modality={modality} setModality={handleModalityChange} asSelect={asSelect} />
        <PlatformFileFormControl platform={platform} setPlatform={setPlatform} platforms={availablePlatforms} asSelect={asSelect} />
    </>
}

