import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Stack, IconButton } from '@mui/material';
import BasicModal from '_components/base/BasicModal';
import { useAuth0 } from '@auth0/auth0-react';
import { useIsMounted } from '_hooks/useIsMounted';
import RowType, { RowElementType } from '_components/base/table/RowType';
import projectsService from '_services/harbor/projects.service';
import { ProjectType } from '_pages/Projects/ProjectTypeEnum';
import config from '_configs';
import Projects, { HopBackInProjectButton, NotesWithBadge } from '_pages/Projects/Projects';
import HeadCellType from '_components/base/table/HeadCellType';
import PageHeader from '_components/base/PageHeader';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import VizRoutine from './VizRoutine';
import { withServiceCallHandling } from '_helpers/decorators';
import FeatureHomePageContent from '_components/base/FeatureHomePage';

/**
 * VisualizationWithFile Page consistst of multiple step
 */
export default function Visualization() {
  const { getAccessTokenSilently } = useAuth0();
  const isMounted = useIsMounted();
  const history = useHistory();

  const headCells: HeadCellType[] = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: false,
      label: 'Creation Date',
      isDate: true,
    },
    {
      id: 'updated_at',
      numeric: false,
      disablePadding: false,
      label: 'Last Updated',
      isDate: true,
    },
    {
      id: 'notes',
      numeric: false,
      disablePadding: false,
      label: '',
      isEmpty: true,
      isSelfClickable: false,
      renderElementImpl: (_id: RowElementType, row: RowType) => {
        return (
          <NotesWithBadge row={row} />
        )
      }
    },
    {
      id: '_id',
      numeric: false,
      disablePadding: false,
      label: '',
      isEmpty: true,
      isSelfClickable: true,
      renderElementImpl: (_id: RowElementType) => {
        function goToProject() {
          history.push(config.clientUrls.VISUALIZATION_PROJECT(_id));
        }
        return (
          <HopBackInProjectButton onClickFunction={goToProject} />
        )
      },
    },
  ];

  const [modal, setModal] = useState({ open: false });

  function closeModal() {
    setModal({ ...modal, open: false });
  }
  function openModal() {
    setModal({ ...modal, open: true });
  }

  async function createVisProjectImpl(uploadId: string, groupColumn: string, groupValues: string[]) {
    const accessToken = await getAccessTokenSilently();
    return projectsService.createVisProject(accessToken, 'My Project', {
      uploadId,
      groupColumn,
      groupValues,
      isDefault: true,
    });
  }
  const handleFreshStartRoutineComplete = withServiceCallHandling(
    createVisProjectImpl,
    (data) => {
      history.push(config.clientUrls.VISUALIZATION_PROJECT(data._id));
    },
    undefined,
    false,
    () => isMounted(),
  );

  return (
    <>
      <BasicModal
        aria-labelledby="visualization-pipeline"
        onClose={closeModal}
        open={modal.open}
        boxSx={{ maxHeight: '95vh', overflow: 'auto' }}
      >
        <VizRoutine onSubmit={handleFreshStartRoutineComplete} onCancel={closeModal} />
      </BasicModal>

      <Container>
        <Stack spacing={1}>
          <PageHeader
            title="Visualization"
            imageSrc="/assets/img/cards/visualization.webp"
            descriptions={[
              "Visualize your proteomics data using Simmunome's plotting engine.",
            ]}
          />

          <FeatureHomePageContent buttonAction={openModal} featureName="Visualization">
            <Projects
              headCells={headCells}
              asPage={false}
              tableTitle="Visualization Projects"
              entityName="Project"
              fixedType={ProjectType.Visualization}
              allowInsert={false}
            />
          </FeatureHomePageContent>
        </Stack>
      </Container>
    </>
  );
}
