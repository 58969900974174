export async function handleResponse(response) {
  const { status } = response;
  // process status of response [400, 401, 500 etc]
  if (status >= 500) {
    throw Error('Server problem');
  }
  const data = await response.json();
  if (status >= 400) {
    throw Error(data.error);
  }
  return data;
}

export async function handleResponseFile(response) {
  const { status } = response;
  // process status of response [400, 401, 500 etc]
  if (status >= 500) {
    throw Error('Server problem');
  }
  if (status >= 400) {
    const data = await response.json();
    throw Error(data.error);
  }
  return response.blob();
}
