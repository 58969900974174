import { Box, Button, FormLabel, Typography } from '@mui/material';
import RowType from '_components/base/table/RowType';
import { useState } from 'react';
import Models from '_pages/Models/Models';
import HeadCellType from '_components/base/table/HeadCellType';
import InfoTooltipSurrounder from '_components/base/InfoTooltip';

interface TVRoutineProps {
  onSubmit: (model: RowType) => void;
  onCancel: () => void;
}

const modelsIdHeadCell: HeadCellType = {
  id: '_id',
  numeric: false,
  disablePadding: false,
  label: 'ID',
};

export default function TVRoutine({ onSubmit }: TVRoutineProps) {
  const [selectedModel, setSelectedModel] = useState<RowType | null>(null);


  const isButtonDisabled =
    !selectedModel?.[modelsIdHeadCell.id] ||
    selectedModel[modelsIdHeadCell.id] === ''

  return (
    <>
      <Box>
        <Typography variant="h4" component="div" color="primary">
          Target Validation
        </Typography>
      </Box>
      <Box sx={{ pb: 2 }}>
        <InfoTooltipSurrounder description="Different models for the same disease may reflect differences in disease indications or population subtypes.">
          <FormLabel>
            <Typography variant="overline">Select a Disease Model</Typography>
          </FormLabel>
        </InfoTooltipSurrounder>
        <Box>
          <Models
            asPage={false}
            allowCreateProject={false}
            allowDelete={false}
            allowInsert={false}
            allowRename={false}
            allowMultipleSelect={false}
            onRowsSelected={(rows: RowType[]) => {
              if (rows.length !== 1) return setSelectedModel(null);
              setSelectedModel(rows[0]);
            }}
          />
        </Box>
      </Box>
      {selectedModel?.name ? (
        <Typography>
          Selected Model: {selectedModel?.name}
        </Typography>
      ) : (<Typography> You need to select a model first. </Typography>)}

      <Box sx={{ pb: 2 }}>

        <Button
          sx={{ mt: 2 }}
          variant="contained"
          onClick={() => {
            if (isButtonDisabled) return;
            onSubmit(selectedModel);
          }}
          disabled={isButtonDisabled}
        >
          Confirm
        </Button>
      </Box>
    </>
  );
}
