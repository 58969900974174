import { Box, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import './Footer.css'

function Footer() {
  const theme = useTheme();
  return (
    <footer className="footer" style={{ backgroundColor: theme.palette.primary.main, color: 'white' }}>
      <Box sx={{
        display: 'flex', alignItems: 'center', justifyContent: 'center',
      }}
      >
        <Link href="https://simmunome.com/">
          <img className="footer-logo" src="/assets/img/logo-reverse-icon.png" alt="simmunome-logo" />
          {' '}
          <img className="footer-logo-text" src="/assets/img/logo-reverse-flat-text.png" alt="simmunome-logo-text" />
        </Link>
        <span style={{ marginLeft: '20px', fontSize: '0.8rem' }}>
          &#169;
          {new Date().getFullYear()}
        </span>
      </Box>
    </footer>
  );
}

export default Footer;
