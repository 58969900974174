import { Box, Button, FormControl, FormLabel, Input, Stack, Typography } from '@mui/material';
import DataBox from '../DataBox';
import AddModifyDataRoutine from '../AddModifyDataRoutine';
import BasicModal from '_components/base/BasicModal';
import { toast } from 'react-toastify';
import { DataRecordType } from '../DataRecordType';
import BMRoutineStore from './BMRoutineStore';
import { useState } from 'react';

const INIT_DATA_MODAL = { open: false, dataRecord: null };
export default function DatasetRoutine() {
  const [projectName, setProjectName] = useState<string>('');
  const [dataModal, setDataModal] = useState<{
    open: boolean;
    dataRecord: DataRecordType | null;
  }>(INIT_DATA_MODAL);
  const [dataRecords, setDataRecords] = useState<DataRecordType[]>([]);
  const setInitBMProject = BMRoutineStore((state) => state.setInitBMProject);

  function hanleDeleteDataRecord(record: DataRecordType) {
    setDataRecords(dataRecords.filter((dataRecord) => dataRecord.fileUpload?._id !== record.fileUpload?._id));
  }

  function handleCloseDataModal() {
    setDataModal(INIT_DATA_MODAL);
  }

  const isButtonDisabled = dataRecords.length < 1 || projectName.length < 1;

  return (
    <>
      <Box>
        <Typography variant="h6" component="div" color="primary">
          New Project
        </Typography>
      </Box>
      <Box sx={{ pb: 2 }}>
        <FormControl>
          <FormLabel id="project-name">
            <Typography variant="overline">1. Define project name</Typography>
          </FormLabel>
          <Input
            placeholder="Project Name"
            id="project-name-text"
            name="project-name"
            inputProps={{ 'aria-label': 'description' }}
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
          />
        </FormControl>
      </Box>

      <Stack sx={{ pb: 2 }}>
        <Typography variant="overline">3. Configure data.</Typography>
        <Button size="small" variant="outlined" onClick={() => setDataModal({ ...dataModal, open: true })}>
          Add data
        </Button>
        {dataRecords.map((dataRecord, indx: number) => (
          <DataBox
            key={`dataset-box-${indx}`}
            dataRecord={dataRecord}
            onDelete={hanleDeleteDataRecord}
            onUpdate={() =>
              setDataModal({
                ...dataModal,
                open: true,
                dataRecord,
              })
            }
          />
        ))}

        <BasicModal
          aria-labelledby="bm-pipeline"
          onClose={handleCloseDataModal}
          open={dataModal.open}
          boxSx={{ maxHeight: '95vh', overflow: 'auto' }}
        >
          <AddModifyDataRoutine
            initData={dataModal.dataRecord}
            onSubmit={(fileUpload, groupColumn, columnData) => {
              const fileExists = dataRecords.find((o) => o.fileUpload?._id === fileUpload._id);
              const isNewRecord = !dataModal.dataRecord;
              if (fileExists) {
                if (isNewRecord) {
                  toast.error('This dataset is already added');
                  return false;
                } else {
                  // update mode
                  setDataRecords(
                    dataRecords.map((record) => {
                      if (record.fileUpload?._id !== fileUpload._id) {
                        return record;
                      }
                      return { fileUpload, groupColumn, columnData };
                    }),
                  );
                }
              } else {
                // insert mode
                setDataRecords([...dataRecords, { fileUpload, groupColumn, columnData }]);
              }
              handleCloseDataModal();
              return true;
            }}
            onCancel={handleCloseDataModal}
          />
        </BasicModal>
      </Stack>

      <Box>
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          onClick={() => {
            if (isButtonDisabled) return;
            setInitBMProject(projectName, dataRecords);
          }}
          disabled={isButtonDisabled}
        >
          Reconcile Groups
        </Button>
      </Box>
    </>
  );
}
