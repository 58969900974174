import { useState, useEffect } from 'react'; import targetValidationService from '_services/target.validation.service';
import diseaseService from '_services/disease.service';
import { Box, Container, Typography, Button, Paper, Tooltip, Stack } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import nodeStore from '../SelectedNodeStore';
import SigmaGraph from './SigmaDiseaseGraph';
import AiPredStore from '_pages/TargetValidation/FilterAIPredictionStore';
import '@react-sigma/core/lib/react-sigma.min.css';
import '_css/sigma.css';
import { withServiceCallHandling } from '_helpers/decorators';
import InfoTooltipSurrounder from '_components/base/InfoTooltip';
import Loading from '_components/Loading';

function HelpText() {
  return (
    <Paper sx={{ padding: 1 }}>
      <Typography style={{ display: 'inline-block' }} sx={{ pb: 1 }}>
        The graph illustrates the network of proteins involved in diseases pathways. Red nodes (circles) denote molecular
        endpoints that signify disease progression. By clicking on a node, we visualize the pathways involved from the
        selected node to each molecular endpoint.
      </Typography>
      <Typography style={{ display: 'inline-block' }} sx={{ pb: 1 }}>
        When you click on a node (circle), the Calculate button will be enabled. This button will launch computations and lets you access overall score for
        your molecular target along with further details on the selected target and how it can reach the different
        endpoints.
      </Typography>
      <Typography style={{ display: 'inline-block' }}>
        Using the filters on the left, you can customize how you want to visualize the disease network. First, you can
        search for a protein target if you already know its name. You can also search by using one or multiple
        annotation keywords. This will highlight the proteins that are related to any of the annotation keywords that
        are selected. You can also toggle to view/hide the relations between the proteins that are AI predicted.
        Finally, you can adjust the confidence level you want to allow from our Ai-predicted interactions.
      </Typography>
    </Paper>
  );
}

function HelpPopper() {
  const [open, setOpen] = useState(false);
  return (
    <Container sx={{ position: 'absolute', width: '40%', zIndex: 101, top: "80px", left: "300px" }}> <Button
      variant="outlined"
      onClick={() => {
        setOpen(!open);
      }}
    >
      {open ? 'Hide' : 'Show'} Instructions
    </Button>
      {open && <HelpText />}
    </Container>
  );
}

function GraphDiseasePage({ modelId, onGoToNodeProcessPage }) {
  const [diseaseData, setDiseaseData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedNode, setSelectedNode] = useState('');
  const [remainingRuns, setRemainingRuns] = useState('');
  const setNode = nodeStore((state) => state.setNode);

  const { getAccessTokenSilently } = useAuth0();

  async function getRemainingRunsImpl() {
    const accessToken = await getAccessTokenSilently();
    return targetValidationService.getRemainingTargetValidationRuns(accessToken);
  }
  const getRemainingRuns = withServiceCallHandling(getRemainingRunsImpl, (data) => setRemainingRuns(data));
  useEffect(() => {
    getRemainingRuns();
  }, []);

  async function getDiseaseDataImpl() {
    // conditional retrieve conc version in case filename is provided
    const accessToken = await getAccessTokenSilently();
    return diseaseService.getDiseaseData(modelId, accessToken);
  }

  const getDiseaseData = withServiceCallHandling(getDiseaseDataImpl, (data) => {
    setDiseaseData(data);
    setIsLoaded(true)
  });

  useEffect(() => {
    getDiseaseData();
  }, [modelId]);

  async function calculateButtonActionGoToNodeProcessPage() {
    setNode(selectedNode.id, selectedNode.label);
    const aiPredIsOn = AiPredStore.getState().filterBooleanValue;
    const aiPredCutoff = AiPredStore.getState().filterCutoff;
    if (onGoToNodeProcessPage) onGoToNodeProcessPage(selectedNode.id, aiPredIsOn, aiPredCutoff);
  }

  async function handleGetNodeInfo(node) {
    // TODO To convert to withServiceCallHandling pattern
    const aiPredValue = AiPredStore.getState().filterValue;
    const aiPredCutoff = AiPredStore.getState().filterCutoff;
    setSelectedNode(node);

    try {
      // conditional retrieve conc version in case filename is provided
      const accessToken = await getAccessTokenSilently();
      const { paths } = await targetValidationService.getShortestPaths(
        modelId,
        node.id,
        aiPredValue,
        aiPredCutoff,
        accessToken,
      );
      return paths;
    } catch (error) {
      // handle error
      console.error(error);
    }
  }

  function validateButtonTooltipTitle() {
    if (remainingRuns <= 0) {
      return "You don't have any remaining runs left. Contact us or your organization to get more runs.";
    } else if (selectedNode !== '') {
      return `Compute an estimation of the probability of success and get more information on how the physical interations of ${selectedNode.label} can affect the disease system. This will create a new Project.`;
    } else {
      return 'You need to select a node first.';
    }
  }

  function resetSelectedNode() {
    setSelectedNode('');
  }

  return (
    <Box>
      <HelpPopper></HelpPopper>
      <div className="header">
        <Box sx={{ marginTop: '10px' }}>
          <Typography variant="h5" color="primary">
            Model Pathways Analysis
          </Typography>
        </Box>
      </div>



      <Box sx={{ height: '70vh', width: '80vw' }}>
        {isLoaded ?
          <SigmaGraph
            diseaseData={diseaseData}
            onGetNodeInfo={(data) => handleGetNodeInfo(data)}
            onResetEdges={() => resetSelectedNode()}
          />
          : <Loading />
        }
      </Box>

      <Box>
        <InfoTooltipSurrounder description="Click on a circle (node) in the network above to select it. To unselect a node, click anywhere in the empty space in the network area.">
          <Typography sx={{ mt: 1, mb: 1 }}>Selected Node: {selectedNode.label}</Typography>
        </InfoTooltipSurrounder>
        <Stack direction="row" spacing={2} display='flex' alignItems={'center'}>
          <Tooltip title={validateButtonTooltipTitle()}>
            <div>
              <Button
                variant="contained"
                disabled={(selectedNode === '') | (remainingRuns <= 0)}
                onClick={() => {
                  calculateButtonActionGoToNodeProcessPage();
                }}
              >
                Validate
              </Button>
            </div>
          </Tooltip>
          <Typography>You have {remainingRuns} remaining runs left.</Typography>
        </Stack>
        {selectedNode !== '' && (
          <Box sx={{ mt: 2 }}>
            <Typography sx={{ mt: 1, mb: 1, width: "60%" }}><Typography sx={{ fontWeight: 'bold' }} >Selected Node Description:</Typography> {selectedNode.annotation}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default GraphDiseasePage;
