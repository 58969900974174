import { Box, Button, Paper, Stack, Typography, IconButton, TextField } from '@mui/material';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ReplayIcon from '@mui/icons-material/Replay';
import MinimizeIcon from '@mui/icons-material/Minimize';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { extractPrefixFromFileUpload } from '_helpers/data';
import BMRoutineStore from './BMRoutineStore';
import { ProjectStatusType } from './ProjectStatusEnum';

export default function RecSummaryRoutine() {
  const projectName = BMRoutineStore((state) => state.projectName);
  const dataRecords = BMRoutineStore((state) => state.dataRecords);
  const recGroups = BMRoutineStore((state) => state.recGroups);
  const setBMProjectStatus = BMRoutineStore((state) => state.setProjectStatus);
  const resetBMProject = BMRoutineStore((state) => state.reset);
  const setRecGroups = BMRoutineStore((state) => state.setRecGroups);

  const [editGroupIndex, setEditGroupIndex] = useState<number | null>(null);
  const [newGroupName, setNewGroupName] = useState<string>('');

  interface FileUploadDictAccumulator {
    [key: string]: { prefix: string; modality: string; platform: string };
  }

  const fileUploadDict = dataRecords.reduce<FileUploadDictAccumulator>((acc, record) => {
    const prefix = extractPrefixFromFileUpload(record.fileUpload);
    acc[record.fileUpload?._id as string] = {
      prefix,
      modality: record.fileUpload?.modality as string,
      platform: record.fileUpload?.platform as string,
    };
    return acc;
  }, {});

  const [expandedGroups, setExpandedGroups] = useState(recGroups.map(() => true));

  const handleToggleGroup = (index: number) => {
    setExpandedGroups((prev) => {
      const newExpanded = [...prev];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };

  const usedGroupsPerFileId = recGroups.reduce<{ [key: string]: string[] }>((acc, gr) => {
    gr.dataGroups.forEach((dg) => {
      const fileId = dg[0]._id as string,
        value = dg[1];
      if (!acc[fileId]) acc[fileId] = [];
      acc[fileId].push(value);
    });
    return acc;
  }, {});

  const numGroupsNotReconciled =
    dataRecords.reduce((sum, r) => sum + (r.columnData.unique_values_no || 0), 0) -
    recGroups.reduce((sum, rg) => sum + (rg.dataGroups.length || 0), 0);

  return (
    <Box minWidth="900px">
      <Stack spacing={2} sx={{ pb: 2 }}>
        <Typography variant="h4">{projectName}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography display="inline" variant="h6" sx={{ pb: 1 }}>
          Group Reconciliation summary
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button
            variant="text"
            sx={{ fontSize: 10 }}
            size="small"
            startIcon={<EditIcon fontSize="small" />}
            onClick={() => setBMProjectStatus(ProjectStatusType.Reconcile)}
          >
            Edit group reconciliation
          </Button>
          <Button
            variant="text"
            sx={{ fontSize: 10 }}
            size="small"
            startIcon={<ReplayIcon fontSize="small" />}
            onClick={() => resetBMProject()}
          >
            Start from the beginning (manually)
          </Button>
        </Stack>
      </Stack>

      <Paper variant="outlined" sx={{ borderColor: 'blue', p: 2, mb: 2 }}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <CheckCircleIcon sx={{ color: 'green' }} />
            <Typography display="inline">{recGroups.length} groups were reconciled</Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <CancelIcon sx={{ color: 'red' }} />
            <Box>
              <Typography>{numGroupsNotReconciled} groups could not be reconciled</Typography>
              <Typography variant="caption">These groups won`&apos;t be considered in the analysis</Typography>
            </Box>
          </Stack>
        </Stack>
      </Paper>

      <Typography variant="h6">Groups reconciled - {recGroups.length} groups</Typography>

      {recGroups.map((group, index) => (
        <Paper key={group.name} variant="outlined" sx={{ borderColor: 'blue', p: 2, mb: 2 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Stack direction="row" spacing={1}>
                {editGroupIndex === null || editGroupIndex !== index ? (
                  <Typography variant="h6" display="inline">
                    {group.name}
                  </Typography>
                ) : (
                  <Box>
                    <TextField
                      value={newGroupName}
                      variant="standard"
                      label="Group name"
                      onChange={(e) => setNewGroupName(e.target.value)}
                    />
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setRecGroups(
                          recGroups.map((gr, i) => {
                            if (i !== index) return gr;
                            return { ...gr, name: newGroupName };
                          }),
                        );
                        setEditGroupIndex(null);
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                )}
                {editGroupIndex === null && (
                  <Button
                    variant="text"
                    sx={{ fontSize: 10 }}
                    size="small"
                    startIcon={<EditIcon fontSize="small" />}
                    onClick={() => {
                      setNewGroupName(group.name);
                      setEditGroupIndex(index);
                    }}
                  >
                    Edit name
                  </Button>
                )}
              </Stack>
            </Box>
            <Stack direction="row" spacing={2}>
              <Typography variant="body2" fontStyle="italic" display="inline">
                {recGroups[index].dataGroups.length} groups linked
              </Typography>
              <IconButton size="small" onClick={() => handleToggleGroup(index)}>
                {expandedGroups[index] ? <MinimizeIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Stack>
          </Stack>
          {expandedGroups[index] && (
            <Stack spacing={1} sx={{ mt: 2 }}>
              {group.dataGroups.map((dataGroup, idx) => {
                const [fileUpload, groupValue] = dataGroup;
                const fileUploadId = fileUpload._id as string;
                return (
                  <Typography variant="body2" key={idx} fontWeight={600}>
                    {[groupValue, fileUploadDict[fileUploadId].modality, fileUploadDict[fileUploadId].prefix].join(
                      ' - ',
                    )}
                  </Typography>
                );
              })}
            </Stack>
          )}
        </Paper>
      ))}
      {numGroupsNotReconciled > 0 && (
        <Stack spacing={3}>
          <Stack spacing={0}>
            <Typography variant="h6">Groups not reconciled - {numGroupsNotReconciled} groups</Typography>
            <Typography variant="caption">These groups won`&apos;t be considered in the analysis</Typography>
          </Stack>

          <Stack spacing={2}>
            {dataRecords.map((record, i) => {
              return (
                <Stack key={i} spacing={1}>
                  {record.columnData.unique_values
                    ?.filter(
                      (groupValue: string) =>
                        !usedGroupsPerFileId[record.fileUpload?._id as string].includes(groupValue),
                    )
                    .map((groupValue, j) => (
                      <Typography key={j} variant="body2" fontWeight={600}>
                        {[
                          groupValue,
                          fileUploadDict[record.fileUpload?._id as string].modality,
                          fileUploadDict[record.fileUpload?._id as string].prefix,
                        ].join(' - ')}
                      </Typography>
                    ))}
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      )}
      <Button
        variant="contained"
        onClick={() => {
          setBMProjectStatus(ProjectStatusType.AnalysisDataSelector);
        }}
      >
        Next
      </Button>
    </Box>
  );
}
