import { useEffect, useState } from 'react';
import { useSigma } from '@react-sigma/core';
import { Autocomplete, Container } from '@mui/material';
import TextField from '@mui/material/TextField';

/**
 * This component is basically a fork from React-sigma-v2's SearchControl
 * component, to get some minor adjustments:
 * 1. We need to hide hidden nodes from results
 * 2. We need custom markup
 */
function SearchField({ filters }) {
  const sigma = useSigma();

  const [values, setValues] = useState([]); // < Array < { id: string; label: string } >>
  const [selected, setSelected] = useState(''); // < string | null >
  const [inputValue, setInputValue] = useState(''); // < string | null >

  const refreshValues = () => {
    const newValues = []; // Array<{ id: string; label: string }>
    sigma.getGraph().forEachNode((key, attributes) => {
      if (!attributes.hidden && attributes.label) {
        newValues.push({ id: key, label: attributes.label, annotation: attributes.annotation });
      }
    });
    setValues(newValues);
  };

  function handleRefreshFocus() {
    if (values.length === 0) {
      refreshValues();
    }
  }

  // Refresh values when filters are updated (but wait a frame first):
  useEffect(() => {
    requestAnimationFrame(refreshValues);
  }, [filters]);

  useEffect(() => {
    if (!selected || selected === '' || selected === null) return;

    sigma.getGraph().setNodeAttribute(selected.id, 'highlighted', true);
    const nodeDisplayData = sigma.getNodeDisplayData(selected.id);

    if (nodeDisplayData) {
      sigma.getCamera().animate(
        { ...nodeDisplayData, ratio: 0.05 },
        {
          duration: 600,
        },
      );
    }

    // eslint-disable-next-line consistent-return
    return () => {
      sigma.getGraph().setNodeAttribute(selected.id, 'highlighted', false);
    };
  }, [selected]);

  return (
    <Container sx={{ padding: 1 }}>
      <Autocomplete
        disablePortal
        options={values}
        value={selected}
        onChange={(_, newValue) => {
          setSelected(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onFocus={() => {
              handleRefreshFocus();
            }}
            label="Search node"
            placeholder="Node"
          />
        )}
      />
    </Container>
  );
}

export default SearchField;
