import { Badge, Container, IconButton, Tooltip } from '@mui/material';
import NotesIcon from '@mui/icons-material/Notes';
import HeadCellType from '_components/base/table/HeadCellType';
import RowType, { RowElementType } from '_components/base/table/RowType';
import { ReactNode, useEffect, useState } from 'react';
import projectsService from '_services/harbor/projects.service';
import { useAuth0 } from '@auth0/auth0-react';
import { useIsMounted } from '_hooks/useIsMounted';
import ContentTable from '_components/base/table/ContentTable';
import AddIcon from '@mui/icons-material/Add';
import BasicModal from '_components/base/BasicModal';
import CreateProjectRoutine from './Routines/CreateProjectRoutine';
import { ProjectType } from './ProjectTypeEnum';
import PageHeader from '_components/base/PageHeader';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useHistory } from 'react-router-dom';
import { withServiceCallHandling } from '_helpers/decorators';
import { convertProjectType2ProjectUrl } from '_helpers/data';
import DeleteButton from '_components/base/DeleteButton';
import { Note } from '_components/notepad';


interface NotesWithBadgeProps {
  row: RowType
}

export function NotesWithBadge({ row }: NotesWithBadgeProps) {
  if (row.notes) {
    var notes = row.notes as Note[]
    const noteNumber = notes.length
    return (
      <Badge badgeContent={notes.length} color="secondary">
        <Tooltip title={`This project has ${noteNumber} notes.`}>
          <NotesIcon />
        </Tooltip>
      </Badge>
    )
  } else {
    return (<></>)
  }
}

interface HopBackInProjectButtonProps {
  onClickFunction: Function
}

export function HopBackInProjectButton({ onClickFunction }: HopBackInProjectButtonProps) {
  return (
    <Tooltip title='Hop back into the project'>
      <IconButton
        onClick={() => {
          onClickFunction()
        }}
      >
        <ArrowCircleRightIcon fontSize="large" />
      </IconButton>
    </Tooltip>
  );
}

const styles = {
  insertModalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '70%',
    minWidth: '40%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  },
};
const defaultIdHeadCell: HeadCellType = {
  id: '_id',
  numeric: false,
  disablePadding: false,
  label: 'ID',
};

interface ProjectsProps {
  tableTitle?: string;
  entityName?: string;
  allowInsert?: boolean;
  allowDelete?: boolean;
  allowFilter?: boolean;
  allowRename?: boolean;
  allowMultipleSelect?: boolean;
  initRows?: RowType[];
  initRowsSelected?: RowType[];
  headCells?: HeadCellType[];
  idHeadCell?: HeadCellType;
  onRowsSelected?: (rows: RowType[]) => void;
  fixedType?: ProjectType;
  asPage?: boolean;
}
function Projects({
  asPage = true,
  initRows = [],
  initRowsSelected = [],
  allowMultipleSelect = true,
  allowInsert = false,
  allowDelete = true,
  allowFilter = true,
  allowRename = true,
  tableTitle = 'Projects',
  entityName = 'Project',
  headCells = [],
  idHeadCell = defaultIdHeadCell,
  onRowsSelected,
  fixedType,
}: ProjectsProps) {
  const history = useHistory();

  const isMounted = useIsMounted();
  const { getAccessTokenSilently } = useAuth0();

  const [rows, setRows] = useState<RowType[]>(initRows);
  const [rowsSelected, setRowsSelected] = useState<RowType[]>(initRowsSelected);
  const [insertModalOpen, setInsertModalOpen] = useState<boolean>(false);

  async function listProjectsImpl() {
    const accessToken = await getAccessTokenSilently();
    const args = [];
    if (fixedType) args.push(fixedType);
    return projectsService.listProjects(accessToken, ...args);
  }
  const listProjects = withServiceCallHandling(
    listProjectsImpl,
    (data) => setRows(data),
    undefined,
    false,
    () => isMounted(),
  );

  useEffect(() => {
    listProjects();
  }, []);

  if (!headCells || headCells.length < 1)
    headCells = [
      { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
      { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
      {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'Creation Date',
        isDate: true,
      },
      {
        id: 'updated_at',
        numeric: false,
        disablePadding: false,
        label: 'Last Updated',
        isDate: true,
      },
      {
        id: 'notes',
        numeric: false,
        disablePadding: false,
        label: '',
        isEmpty: true,
        isSelfClickable: false,
        renderElementImpl: (_id: RowElementType, row: RowType) => {
          return (
            <NotesWithBadge row={row} />
          )
        }
      },
      {
        id: '_id',
        numeric: false,
        disablePadding: false,
        label: '',
        isEmpty: true,
        isSelfClickable: true,
        renderElementImpl: (_id: RowElementType, row: RowType) => {
          function goToProject() {
            history.push(convertProjectType2ProjectUrl(row.type as ProjectType, _id as string))
          }
          return (
            <HopBackInProjectButton onClickFunction={goToProject} />

          )
        }
      }
    ];

  async function renameProjectImpl(row: RowType, name: string, newName: string) {
    const accessToken = await getAccessTokenSilently();
    return projectsService.renameProject(accessToken, row[idHeadCell.id] as string, name, newName);
  }
  const renameProject = withServiceCallHandling(
    renameProjectImpl,
    (data, response) => {
      const newRows: RowType[] = rows.map((r) => {
        if (r[idHeadCell.id] !== data._id) return r;
        return { ...r, name: data.newName };
      });
      setRows(newRows);
    },
    undefined,
    'Renaming...',
    () => isMounted(),
  );

  const handleModalOpen = () => {
    setInsertModalOpen(true);
  };
  const handleModalClose = () => {
    setInsertModalOpen(false);
  };

  function handleRowsSelected(selRows: RowType[]) {
    if (onRowsSelected) onRowsSelected(selRows);
    setRowsSelected(selRows);
  }

  async function deleteProjectsImpl() {
    if (!rowsSelected || rowsSelected.length < 1) return;
    const accessToken = await getAccessTokenSilently();
    return projectsService.deleteProjects(
      accessToken,
      rowsSelected.map((row) => row._id as string),
    );
  }
  const handleDeleteSelected = withServiceCallHandling(
    deleteProjectsImpl,
    (data) => {
      const newRows = rows.filter((row) => !data.project_ids.includes(row[idHeadCell.id]));
      setRows(newRows);
      handleRowsSelected([]);
    },
    undefined,
    false,
    () => isMounted() && rowsSelected && rowsSelected.length > 0,
  );

  const DeleteRecordComponent: ReactNode = <DeleteButton handleDelete={handleDeleteSelected} />;

  const InsertRecordComponent: ReactNode = (
    <div>
      <Tooltip title="Create new project">
        <IconButton onClick={handleModalOpen}>
          <AddIcon />
        </IconButton>
      </Tooltip>

      <BasicModal
        open={insertModalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        boxSx={styles.insertModalStyle}
      >
        <CreateProjectRoutine
          onCancel={handleModalClose}
          onSubmit={() => {
            listProjects();
            handleModalClose();
          }}
        />
      </BasicModal>
    </div>
  );

  const body = (
    <ContentTable
      idHeadCell={idHeadCell}
      headCells={headCells}
      rows={rows}
      rowsSelected={rowsSelected}
      onRowsSelected={handleRowsSelected}
      InsertRecordComponent={allowInsert ? InsertRecordComponent : undefined}
      DeleteRecordComponent={allowDelete ? DeleteRecordComponent : undefined}
      renameRecordFunction={allowRename ? renameProject : undefined}
      entityName={entityName}
      tableTitle={tableTitle}
      allowFilter={allowFilter}
      allowMultipleSelect={allowMultipleSelect}
    />
  );
  if (!asPage) return body;
  return (
    <Container>
      <PageHeader
        title="Projects"
        imageSrc="/assets/img/cards/projects.webp"
        descriptions={['Manage and continue your progress on your saved projects.']}
      />
      {body}
    </Container>
  );
}

export default Projects;
