import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Stack } from '@mui/material';
import BasicModal from '_components/base/BasicModal';
import RowType, { RowElementType } from '_components/base/table/RowType';
import { ProjectType } from '_pages/Projects/ProjectTypeEnum';
import config from '_configs';
import Projects, { HopBackInProjectButton, NotesWithBadge } from '_pages/Projects/Projects';
import HeadCellType from '_components/base/table/HeadCellType';
import PageHeader from '_components/base/PageHeader';
import TVRoutine from './TVRoutine';
import TVProjectStore from './TVProjectStore';
import FeatureHomePageContent from '_components/base/FeatureHomePage';

export default function TargetValidation() {
  const setInitTVProject = TVProjectStore((state) => state.setInitTVProject);
  const history = useHistory();

  const headCells: HeadCellType[] = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    {
      id: 'created_date',
      numeric: false,
      disablePadding: false,
      label: 'Creation Date',
      isDate: true,
    },
    {
      id: 'updated_at',
      numeric: false,
      disablePadding: false,
      label: 'Last Updated',
      isDate: true,
    },
    {
      id: 'notes',
      numeric: false,
      disablePadding: false,
      label: '',
      isEmpty: true,
      isSelfClickable: false,
      renderElementImpl: (_id: RowElementType, row: RowType) => {
        return (
          <NotesWithBadge row={row} />
        )
      }
    },
    {
      id: '_id',
      numeric: false,
      disablePadding: false,
      label: '',
      isEmpty: true,
      isSelfClickable: true,
      renderElementImpl: (_id: RowElementType) => {
        function goToProject() {
          history.push(config.clientUrls.NODE_PROCESS_PATHS__TV_PROJECT(_id));
        }
        return (
          <HopBackInProjectButton onClickFunction={goToProject} />
        );
      },
    },
  ];

  const [modal, setModal] = useState({ open: false });

  function closeModal() {
    setModal({ ...modal, open: false });
  }
  function openModal() {
    setModal({ ...modal, open: true });
  }

  function handleFreshStartRoutineComplete(model: RowType) {
    setInitTVProject(model);
    history.push(config.clientUrls.TARGET_VALIDATION_PROJECT("new_project"));
  }

  return (
    <>
      <BasicModal
        aria-labelledby="tv-pipeline"
        onClose={closeModal}
        open={modal.open}
        boxSx={{ maxHeight: '95vh', overflow: 'auto' }}
      >
        <TVRoutine onSubmit={handleFreshStartRoutineComplete} onCancel={closeModal} />
      </BasicModal>

      <Container>
        <Stack spacing={1}>
          <PageHeader
            title="Target Validation"
            imageSrc="/assets/img/cards/targetvalidation.webp"
            descriptions={[
              'Validate that a molecular target has a chance of making an effect on a biological network by leveraging our AI disease graph algorithms.',
            ]}
          />

          <FeatureHomePageContent buttonAction={openModal} featureName="Target Validation">
            <Projects
              headCells={headCells}
              asPage={false}
              tableTitle="Target Validation Projects"
              entityName="Project"
              fixedType={ProjectType.TargetValidation}
              allowInsert={false}
            />
          </FeatureHomePageContent>
        </Stack>
      </Container>
    </>
  );
}
