import { useEffect, useState } from 'react';
import targetValidationService from '_services/target.validation.service';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { Container, Link, Button, Box, Divider, Stack, Chip, Paper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import PathGraph from './PathGraph';
import Typography from '@mui/material/Typography';
import history from '_helpers/history';
import reportService from '_services/report.service';
import { saveAs } from 'file-saver';
import GraphLegendTable from '../GraphDisease/GraphLegendTable';
import '_css/d3.css';
import './NodeProcessPathsPage.css';
import NodeInfoAccordion from './NodeInfoAccordion';
import createPdfReport from './Report';
import { withServiceCallHandling } from '_helpers/decorators';
import { InterruptedPath, ProteinInfo, TargetEfficacyData } from './TargetEfficacyInterface';
import Loading from '_components/Loading';
import TVProjectType from '../TVProjectType';
import projectsService from '_services/harbor/projects.service';
import { useIsMounted } from '_hooks/useIsMounted';
import config from '_configs';
import { toast } from 'react-toastify';
import disease_name_readable from '_helpers/format';
import InfoTooltipSurrounder from '_components/base/InfoTooltip';
import Notepad from '_components/notepad';

const SELECTED_GRAPH_WIDTH = 814;
const SELECTED_GRAPH_HEIGHT = 420;
export const SCORES_DECIMAL = 3;

function renderPathGraph(
  o: InterruptedPath,
  key: string,
  target: string | undefined,
  isInteractive: boolean,
  width: number = 260,
  height: number = 200,
) {
  if (o.path.links.length === 0) {
    return <Loading />;
  }
  return (
    <PathGraph
      key={key}
      links={o.path.links}
      nodes={o.path.nodes}
      start={target}
      isBroken={false}
      isInteractive={isInteractive}
      dimensions={{
        width: width,
        height: height,
      }}
    />
  );
}

function findSelectedNodeName(nodeid: string, data: TargetEfficacyData) {
  return data?.protein_info[nodeid]?.preferredName;
}

interface UnreachedEndpointsSectionInterface {
  unreachedEndpoints: string[], proteinInfo: ProteinInfo
}

function UnreachedEndpointsSection({ unreachedEndpoints, proteinInfo }: UnreachedEndpointsSectionInterface) {
  return (
    <Box>
      <div className="titlesection">
        <p>Non-interrupted pathways</p>
      </div>
      <Box sx={{ mt: 1 }}>
        <Typography>Unreached Endpoints:</Typography>
        {unreachedEndpoints?.length === 0 && (
          <Typography>All endpoints are reached by the target. There are no non-interrupted pathways.</Typography>
        )}
      </Box>
      {unreachedEndpoints?.map((endpointId: string) => {
        return NodeInfoAccordion(proteinInfo[endpointId], endpointId);
      })}
    </Box>
  );
}

export interface NodeProcessPathsPageProps {
  initProjectData?: TVProjectType;
  asPage?: boolean;
}

function NodeProcessPathsPage({ initProjectData, asPage = true }: NodeProcessPathsPageProps) {
  const isMounted = useIsMounted();
  const { getAccessTokenSilently } = useAuth0();
  const { projectId } = useParams<{ projectId: string; disease: string; nodeId: string }>();
  const [projectData, setProjectData] = useState<TVProjectType>();

  const _id = projectData?._id;
  const disease = projectData?.disease;
  const nodeId = projectData?.nodeId;
  const aiPredIsOn = projectData?.filterToggle;
  const aiPredCutoff = projectData?.filterValue;
  console.log(projectData)

  const [selectedPath, setSelectedPath] = useState<InterruptedPath>({
    endpoint: '',
    path: { directed: true, graph: {}, links: [], multigraph: false, nodes: [] },
    score: 0,
    target: '',
  });
  const [efficacyData, setEfficacyData] = useState<TargetEfficacyData>({
    interrupted_paths: [],
    protein_info: {},
    report_id: '',
    score: 0,
    unreached_endpoints: [],
  });
  const [selectedNodeName, setSelectedNodeName] = useState('');

  async function getTVProjectImpl() {
    const accessToken = await getAccessTokenSilently();
    return projectsService.getTVProject(accessToken, projectId);
  }

  const getTVProjectInfo = withServiceCallHandling(
    getTVProjectImpl,
    (data) => {
      setProjectData(data);
    },
    undefined,
    false,
    () => isMounted(),
  );

  useEffect(() => {
    if (!initProjectData) getTVProjectInfo();
    else setProjectData(initProjectData);
  }, [initProjectData]);

  // In case nodeId is not set in projectData - rollback to the main target validation project list
  useEffect(() => {
    if (_id && !nodeId) {
      toast.error('Node was not processed for this project');
      history.push(config.clientUrls.TargetValidation);
    }
  }, [_id, nodeId]);

  function displaySelectedPath() {
    return renderPathGraph(selectedPath, 'main', nodeId, true, SELECTED_GRAPH_WIDTH, SELECTED_GRAPH_HEIGHT);
  }

  function handleSetSelectedPath(pathId: number) {
    setSelectedPath(efficacyData.interrupted_paths[pathId]);
  }

  async function getDataImpl() {
    const accessToken = await getAccessTokenSilently();
    return targetValidationService.getScores(disease, nodeId, aiPredIsOn, aiPredCutoff, projectData?._id, accessToken);
  }
  const fetchData = withServiceCallHandling(
    getDataImpl,
    (data: any) => {
      if (nodeId === undefined) return;
      setEfficacyData(data);
      setSelectedNodeName(findSelectedNodeName(nodeId, data));

      if (data.interrupted_paths.length > 0) {
        setSelectedPath(data.interrupted_paths[0]);
      }
    },
    undefined,
    false,
    () => nodeId !== undefined && disease !== undefined && isMounted(),
  );

  useEffect(() => {
    if (!nodeId || !disease || !projectData._id) return;
    fetchData();
  }, [disease, nodeId, projectData]);

  async function getReportImpl(projectId: string) {
    const accessToken = await getAccessTokenSilently();
    return reportService.getReport(projectId, accessToken);
  }
  const handleRequestReport = withServiceCallHandling(
    getReportImpl,
    (data, blob) => {
      saveAs(blob, 'report.csv');
    },
    undefined,
    false,
    () => isMounted(),
  );

  if (!projectData || !nodeId || !disease) return <></>;

  const gridItems = [];
  const interruptedEndpoints: string[] = [];
  for (let i = 0; i < efficacyData?.interrupted_paths?.length; i++) {
    const endpointId = efficacyData?.interrupted_paths[i]?.endpoint;
    const individualScore = efficacyData?.interrupted_paths[i]?.score;
    const endpointName = efficacyData?.protein_info[endpointId]?.preferredName;
    interruptedEndpoints.push(endpointName);
    gridItems.push(
      <ListItem disablePadding className="smallPathContainer" key={i}>
        <ListItemButton onClick={() => handleSetSelectedPath(i)}>
          <div>
            <Grid key={`grid-cell-complete-${i}`} xs={100} style={{ textAlign: 'left' }}>
              <Box>
                <Typography>Pathway - {endpointName}</Typography>
                <Typography>Score: {individualScore.toFixed(SCORES_DECIMAL)}</Typography>
              </Box>
              <div className="smallPathGraphContainer">
                {renderPathGraph(efficacyData.interrupted_paths[i], `not-broken-p-${i}`, nodeId, false, 260, 200)}
              </div>
            </Grid>
          </div>
        </ListItemButton>
      </ListItem>,
    );
  }

  interface TopOverlayInterface {
    projectName: string,
    modelName: string,
    disease: string,
  }

  function TopOverlay({ projectName, modelName, disease }: TopOverlayInterface) {
    return (
      <Stack sx={{ marginTop: '10px' }} direction="row" spacing={2} justifyContent="flex-end">
        <Typography variant="body1" color="primary">
          Project: {projectName}
        </Typography>

        <Box flexDirection="row" justifyContent="start">
          <Typography variant="body1" color="primary" fontStyle="italic" align="left" display="inline">
            Model:
          </Typography>
          <Chip label={modelName} size="medium" />
        </Box>

        <Box flexDirection="row" justifyContent="start">
          <Typography variant="body1" color="primary" fontStyle="italic" align="left" display="inline">
            Disease:
          </Typography>
          <Chip label={disease_name_readable(disease)} size="medium" />
        </Box>
      </Stack>
    )
  }

  interface InterruptedPathwaysSectionInterface { effScore: number, gridItems: any, nodeId: string }

  function InterruptedPathwaysSection({ effScore, gridItems, nodeId }: InterruptedPathwaysSectionInterface) {
    return (
      <Box>
        <div className="titlesection">
          <p>
            INTERRUPTED PATHWAYS
          </p>
        </div>
        <div style={{ display: 'flex' }}>
          <div className="interPathsContainer roundedBorderContainer">
            <InfoTooltipSurrounder description="The Overall Score is an estimation of the probability of success of targeting the chosen molecular node, based on the effects predicted from the physical interactions. All of the disease endpoints are accounted in the score. Below is the list of endpoints that are reached: you can click on them to view them in more details on the right.">
              <Typography variant="h6">Overall Score: {effScore.toFixed(SCORES_DECIMAL)}</Typography>
            </InfoTooltipSurrounder>
            <Divider sx={{ mb: 1 }}></Divider>
            <div className="pathsListContainer">
              <Grid container direction="row" justifyContent="space-between" alignItems="center" width={300}>
                {gridItems}
              </Grid>
            </div>
          </div>
          <Box className="selectedPathContainer roundedBorderContainer">
            <Box display="flex" justifyContent="center" alignItems="center">
              <InfoTooltipSurrounder
                description={`Below is the detailed pathway for ${efficacyData?.protein_info[selectedPath.endpoint]?.preferredName
                  }. You can interact with it by moving the circles around, hovering on the lines to see their probability scores, and clicking on the circles to see more information about the molecular nodes.`}
              >
                <Typography align="center">{efficacyData?.protein_info[selectedPath.endpoint]?.preferredName}</Typography>
              </InfoTooltipSurrounder>
            </Box>

            <Box sx={{ border: 1, borderColor: '#DADADA', padding: 0 }}>{displaySelectedPath()}</Box>

            <GraphLegendTable></GraphLegendTable>
          </Box>
        </div>
        <Typography>Target:</Typography>
        {NodeInfoAccordion(efficacyData?.protein_info[nodeId], nodeId)}
        <Typography>Reached Endpoints:</Typography>
        {efficacyData?.interrupted_paths.map((e) => (
          <div key={e.endpoint}>{NodeInfoAccordion(efficacyData?.protein_info[e.endpoint], e.endpoint)}</div>
        ))}
      </Box>
    )
  }

  interface SummaryItemInterface {
    mainText: string,
    descriptor: string,
    isTargetItem?: boolean,
  }

  function SummaryItem({ mainText, descriptor, isTargetItem }: SummaryItemInterface) {
    return (
      <Paper sx={{ padding: 2, height: "100%", backgroundColor: '#FBf7df' }}>
        <Stack sx={{ height: "100%", justifyContent: "space-between", }}>
          <Box>
            <Typography fontSize={25}>
              {mainText}
            </Typography>
            {isTargetItem &&
              <Typography fontSize={20}>
                String ID: {nodeId}
              </Typography>
            }
            {isTargetItem &&
              <Link href={`https://string-db.org/network/${nodeId}`}>
                Find out more on String
              </Link>
            }
          </Box>
          <Typography fontSize={18} color='#616161'>
            {descriptor}
          </Typography>
        </Stack>
      </Paper>
    )
  }

  interface SummarySectionInterface {
    disease: string,
    nodeId: string,
  }

  function SummarySection({ disease, nodeId }: SummarySectionInterface) {
    return (
      <Box>
        <div className="titlesection">
          <p>Summary</p>
        </div>
        <div>
          <Box sx={{ marginTop: 2 }}>
            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
              <Grid xs={4} >
                <SummaryItem mainText={selectedNodeName} descriptor='Selected Target' isTargetItem={true}></SummaryItem>
              </Grid>
              <Grid sx={{ flexGrow: 1 }} container spacing={2} xs={8}>
                <Grid xs={6}>
                  <SummaryItem mainText={efficacyData?.score.toFixed(SCORES_DECIMAL)} descriptor='Overall Score'></SummaryItem>
                </Grid>
                <Grid xs={6}>
                  <SummaryItem mainText="Breast Cancer v1.0" descriptor='Baseline Model'></SummaryItem>
                </Grid>
                <Grid xs={6}>
                  <SummaryItem mainText={aiPredIsOn ? `Activated with Cutoff at ${aiPredCutoff?.toFixed(2)}` : 'Deactivated'} descriptor='AI Predictions'></SummaryItem>
                </Grid>
                <Grid xs={6}>
                  <SummaryItem mainText='N/A' descriptor='Custom Model'></SummaryItem>
                </Grid>
              </Grid>
              <Grid xs={12}>
                <SummaryItem mainText={interruptedEndpoints.join(', ')} descriptor='Interrupted Endpoints'></SummaryItem>
              </Grid>
            </Grid>
          </Box>
          <p />
          <div style={{ display: 'flex' }}>
            <Button variant="outlined" onClick={() => history.goBack()}>
              Back to source network
            </Button>
            <Box style={{ flexGrow: 1 }}></Box>
            <Button
              variant="contained"
              onClick={() => {
                createPdfReport(
                  projectData?.name,
                  aiPredCutoff,
                  selectedNodeName,
                  nodeId,
                  disease_name_readable(disease),
                  efficacyData.interrupted_paths,
                  interruptedEndpoints,
                  efficacyData.score.toFixed(SCORES_DECIMAL),
                  efficacyData.protein_info,
                  efficacyData.unreached_endpoints,
                  projectData?.model.name,
                  'version 1'
                );
              }}
            >
              Download PDF report
            </Button>
            <Box style={{ flexGrow: 0.1 }}></Box>
            <Button variant="contained" onClick={() => handleRequestReport(projectId)}>
              Download results CSV
            </Button>

          </div>
        </div>
      </Box>
    )
  }

  return (
    <Container disableGutters sx={{ maxWidth: '100%', magin: 'auto' }}>
      <TopOverlay projectName={projectData.name} modelName={projectData.model.name} disease={projectData.disease} />
      <Notepad projectId={projectData._id} projectNotes={projectData.notes ?? []} />
      <p />
      <SummarySection disease={disease} nodeId={nodeId} />
      <p />
      <InterruptedPathwaysSection effScore={efficacyData?.score} gridItems={gridItems} nodeId={nodeId} />
      <p />
      <UnreachedEndpointsSection unreachedEndpoints={efficacyData?.unreached_endpoints} proteinInfo={efficacyData?.protein_info} />
      <p />
    </Container>
  );
}

export default NodeProcessPathsPage;
