import { handleResponse } from '_helpers/middleware';

const URLS = {
  testPlot: '/api/viz/testplot',
  barPlot: '/api/viz/barplot',
  volcanoPlot: '/api/viz/volcano',
  lineVarPCAPlot: '/api/viz/lineplots_var',
  boxPlots: '/api/viz/boxplots',
  lineCVDRPlot: '/api/viz/lineplots_cv_dr',
  heatmaps: '/api/viz/heatmaps',
  singleSource: {
    volcanoPlot: '/api/viz/single-source/volcano',
    lineVarPCAPlot: '/api/viz/single-source/lineplots_var',
    boxPlots: '/api/viz/single-source/boxplots',
    lineCVDRPlot: '/api/viz/single-source/lineplots_cv_dr',
    heatmaps: '/api/viz/single-source/heatmaps',
    // all plots together
    // plots: '/api/viz/single-source/plots',
    plotsFromProject: (projectId) => `/api/viz/single-source/project/${projectId}/plots`
  }
}

function testPlot(accessToken) {
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  return fetch(URLS.testPlot, requestOptions).then(handleResponse);
}

function barPlot(accessToken, filename) {
  const url = new URL(URLS.barPlot, window.location.href);
  url.searchParams.set('filename', filename);
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const fetchDataPromise = fetch(url.toString(), requestOptions);
  return fetchDataPromise.then(handleResponse);
}

function volcanoPlot(accessToken, filename, pvalTh, foldchangeTh) {
  const url = new URL(URLS.volcanoPlot, window.location.href);
  url.searchParams.set('filename', filename);
  if (pvalTh !== undefined) url.searchParams.set('pval_th', pvalTh);
  if (foldchangeTh !== undefined) url.searchParams.set('foldchange_th', foldchangeTh);
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const fetchDataPromise = fetch(url.toString(), requestOptions);
  return fetchDataPromise.then(handleResponse);
}

function lineVarPCAPlot(accessToken, filename) {
  const url = new URL(URLS.lineVarPCAPlot, window.location.href);
  url.searchParams.set('filename', filename);
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const fetchDataPromise = fetch(url.toString(), requestOptions);
  return fetchDataPromise.then(handleResponse);
}

function boxPlots(accessToken, filename) {
  const url = new URL(URLS.boxPlots, window.location.href);
  url.searchParams.set('filename', filename);
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const fetchDataPromise = fetch(url.toString(), requestOptions);
  return fetchDataPromise.then(handleResponse);
}

function lineCVDRPlot(accessToken, filename_cv, filename_dr) {
  const url = new URL(URLS.lineCVDRPlot, window.location.href);
  url.searchParams.set('filename_cv', filename_cv);
  url.searchParams.set('filename_dr', filename_dr);
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const fetchDataPromise = fetch(url.toString(), requestOptions);
  return fetchDataPromise.then(handleResponse);
}

function heatmaps(accessToken, filename) {
  const url = new URL(URLS.heatmaps, window.location.href);
  url.searchParams.set('filename', filename);
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const fetchDataPromise = fetch(url.toString(), requestOptions);
  return fetchDataPromise.then(handleResponse);
}

function volcanoPlotSS(accessToken, filename, groupColumn, groupValues, pvalTh, foldchangeTh) {
  const url = new URL(URLS.singleSource.volcanoPlot, window.location.href);
  url.searchParams.set('filename', filename);
  url.searchParams.set('group_column', groupColumn);
  url.searchParams.set('group_values', groupValues);
  if (pvalTh !== undefined)
    url.searchParams.set('pval_th', pvalTh);
  if (foldchangeTh !== undefined)
    url.searchParams.set('foldchange_th', foldchangeTh);
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const fetchDataPromise = fetch(url.toString(), requestOptions);
  return fetchDataPromise.then(handleResponse);
}

function lineVarPCAPlotSS(accessToken, filename, groupColumn, groupValues) {
  const url = new URL(URLS.singleSource.lineVarPCAPlot, window.location.href);
  url.searchParams.set('filename', filename);
  url.searchParams.set('group_column', groupColumn);
  url.searchParams.set('group_values', groupValues);
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const fetchDataPromise = fetch(url.toString(), requestOptions);
  return fetchDataPromise.then(handleResponse);
}

function boxPlotsSS(accessToken, filename, groupColumn, groupValues) {
  const url = new URL(URLS.singleSource.boxPlots, window.location.href);
  url.searchParams.set('filename', filename);
  url.searchParams.set('group_column', groupColumn);
  url.searchParams.set('group_values', groupValues);
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const fetchDataPromise = fetch(url.toString(), requestOptions);
  return fetchDataPromise.then(handleResponse);
}

function lineCVDRPlotSS(accessToken, filename, groupColumn, groupValues) {
  const url = new URL(URLS.singleSource.lineCVDRPlot, window.location.href);
  url.searchParams.set('filename', filename);
  url.searchParams.set('group_column', groupColumn);
  url.searchParams.set('group_values', groupValues);
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const fetchDataPromise = fetch(url.toString(), requestOptions);
  return fetchDataPromise.then(handleResponse);
}

function heatmapsSS(accessToken, filename, groupColumn, groupValues) {
  const url = new URL(URLS.singleSource.heatmaps, window.location.href);
  url.searchParams.set('filename', filename);
  url.searchParams.set('group_column', groupColumn);
  url.searchParams.set('group_values', groupValues);
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const fetchDataPromise = fetch(url.toString(), requestOptions);
  return fetchDataPromise.then(handleResponse);
}

function plotsSSFromProject(accessToken, projectId) {
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const fetchDataPromise = fetch(URLS.singleSource.plotsFromProject(projectId), requestOptions);
  return fetchDataPromise.then(handleResponse);
}

const VisualizationService = {
  testPlot,
  barPlot,
  volcanoPlot,
  lineVarPCAPlot,
  lineCVDRPlot,
  boxPlots,
  heatmaps,
  // singleSource
  volcanoPlotSS,
  lineVarPCAPlotSS,
  lineCVDRPlotSS,
  boxPlotsSS,
  heatmapsSS,
  // all singleSource plots together
  plotsSSFromProject,
  URLS,
};

export default VisualizationService;
