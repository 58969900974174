import { useAuth0 } from '@auth0/auth0-react';
import Typography from '@mui/material/Typography';
import fileService from '_services/files.service';
import { useEffect, useState } from 'react';
import SelectGroupColumn from './SelectGroupColumn';
import VerifyGroupValues from './VerifyGroupValues';
import RowType, { RowElementType } from '_components/base/table/RowType';
import { useIsMounted } from '_hooks/useIsMounted';
import { withServiceCallHandling } from '_helpers/decorators';
import { ColumnRowType } from './ColumnRowType';
import { DataRecordType } from './DataRecordType';

export enum ScreenType {
  SelectGroupColumn = 0,
  VerifyGroupValues = 1,
}

interface initDataRecordType {
  selectedColumn: RowElementType | null;
}

export interface ManageGroupColumnProps {
  selectedUploadId: string;
  selectedFilenamePrefix: string;
  onSubmit: (selectedColumn: RowElementType, selectedColumnData: ColumnRowType) => void;
  onCancel: () => void;
  selectedFileSize?: number | null;
  // To enable update with the same forms
  initData?: initDataRecordType;
}

export default function ManageGroupColumn({
  selectedUploadId,
  selectedFilenamePrefix,
  selectedFileSize,
  onSubmit,
  onCancel,
  initData = { selectedColumn: null },
}: ManageGroupColumnProps) {
  const { getAccessTokenSilently } = useAuth0();
  const isMounted = useIsMounted();
  const [doFilterRows, setDoFilterRows] = useState<boolean>(true);
  const [rows, setRows] = useState<ColumnRowType[]>([]);
  const [selectedColumn, setSelectedColumn] = useState<RowElementType | null>(initData.selectedColumn);
  const [screen, setScreen] = useState<ScreenType>(
    initData.selectedColumn ? ScreenType.VerifyGroupValues : ScreenType.SelectGroupColumn,
  );

  async function listFileUploadColumnsImpl() {
    if (!isMounted()) return;
    const accessToken = await getAccessTokenSilently();
    return fileService.listFileUploadColumns(accessToken, selectedUploadId);
  }

  const listFileUploadColumns = withServiceCallHandling(
    listFileUploadColumnsImpl,
    (data) => setRows((data as ColumnRowType[]).map((row) => ({ ...row, selectable: row.valid_group }))),
    undefined,
    false,
    () => isMounted(),
  );

  useEffect(() => {
    listFileUploadColumns();
  }, [selectedUploadId]);

  const filteredRows = doFilterRows ? rows.filter((row) => row['unique_values_no']) : rows;

  const selectedColumnData = rows.find((row) => row.name === selectedColumn);
  return (
    <>
      {screen === ScreenType.SelectGroupColumn && (
        <SelectGroupColumn
          underTableCaption={
            doFilterRows ? (
              <Typography variant="caption">
                Only non-continuous columns are shown.{' '}
                <span style={{ cursor: 'pointer' }} onClick={() => setDoFilterRows(false)}>
                  <u>Show all</u>
                </span>
                .
              </Typography>
            ) : (
              <Typography variant="caption">
                All columns are shown.{' '}
                <span style={{ cursor: 'pointer' }} onClick={() => setDoFilterRows(true)}>
                  <u>Show less</u>
                </span>
                .
              </Typography>
            )
          }
          rows={filteredRows}
          onCancel={onCancel} // completely cancel the file (go back to select the file)
          onSubmit={(row: RowType) => {
            if (selectedColumn !== row.name) {
              setSelectedColumn(row.name);
            }
            setScreen(ScreenType.VerifyGroupValues);
          }}
          selectedFilename={selectedFilenamePrefix}
          selectedFileSize={selectedFileSize}
          initSelectedColumn={(selectedColumn || initData?.selectedColumn) as string | null}
        />
      )}
      {selectedColumn && screen === ScreenType.VerifyGroupValues && selectedColumnData && (
        <VerifyGroupValues
          selectedFilename={selectedFilenamePrefix}
          selectedFileSize={selectedFileSize}
          selectedColumnData={selectedColumnData}
          onCancel={() => {
            setScreen(ScreenType.SelectGroupColumn);
          }}
          onSubmit={() => {
            onSubmit(selectedColumn, selectedColumnData);
          }}
        />
      )}
    </>
  );
}
