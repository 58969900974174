export enum ProjectType {
    TargetValidation = "targetvalidation",
    Visualization = "visualization",
    Biomarkers = "biomarkers",
};

export const ProjectTypeDisplayDict = {
    [ProjectType.TargetValidation]: "Target Validation",
    [ProjectType.Visualization]: "Visualization",
    [ProjectType.Biomarkers]: "Biomarkers",
}