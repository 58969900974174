import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import projectsService from '_services/harbor/projects.service';
import { useAuth0 } from '@auth0/auth0-react';
import { useIsMounted } from '_hooks/useIsMounted';
import { withServiceCallHandling } from '_helpers/decorators';

interface CreateFixTVProject {
  modelId: string;
  modelName: string;
  onCancel: () => void;
  onSubmit: (projectId: string) => void;
}

export default function CreateFixTVProject({ modelId, modelName, onCancel, onSubmit }: CreateFixTVProject) {
  const isMounted = useIsMounted();
  const { getAccessTokenSilently } = useAuth0();
  const [projectName, setProjectName] = useState<string>('');
  function handleProjectNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setProjectName(event.target.value);
  }

  async function createTVProjectImpl() {
    const accessToken = await getAccessTokenSilently();
    return projectsService.createTVProject(accessToken, projectName, { model_id: modelId });
  }
  const handleCreate = withServiceCallHandling(
    createTVProjectImpl,
    (data) => onSubmit(data.project_id),
    undefined,
    false,
    () => isMounted(),
  );
  function handelCancel() {
    onCancel();
  }
  return (
    <>
      <Box marginBottom={2}>
        <Typography variant="h5" color="primary">
          Create Target Validation project for {modelName}
        </Typography>
        <Typography variant="body2">
          Target Validation project enables user to save intermediate process for Target Validation module.
        </Typography>
      </Box>
      <Stack spacing={5}>
        <Box sx={{ flex: '1 2 auto', width: '100%' }}>
          <TextField
            id="standard-basic"
            label="Project Name"
            variant="standard"
            value={projectName}
            onChange={handleProjectNameChange}
          />
        </Box>
        <Box>
          <Button
            disabled={!projectName}
            onClick={handleCreate}
            variant="outlined"
            sx={{ mr: 1 /*backgroundColor: theme.palette.primary.main*/ }}
          >
            Create
          </Button>
          <Button
            onClick={handelCancel}
            variant="outlined"
            sx={{ mr: 1 /*backgroundColor: theme.palette.primary.main*/ }}
          >
            Cancel
          </Button>
        </Box>
      </Stack>
    </>
  );
}
