import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import "./pdfDownloadableWrap.css"

interface PrintableComponentProps {
  children: React.ReactNode;
}

const PrintableComponent = React.forwardRef<HTMLDivElement, PrintableComponentProps>((props, ref) => (
  <div ref={ref}>
    {props.children}
  </div>
));
PrintableComponent.displayName = 'PrintableComponent'

export default function PdfDownloadableWrap({ children }: PrintableComponentProps) {
  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Box sx={{ paddingBottom: "30px" }}>
      <PrintableComponent ref={componentRef}>
        {children}
      </PrintableComponent>
      <Tooltip title={`To just download the pdf, select "Save as PDF" as desination.`}>
        <Button onClick={handlePrint} variant="contained">Print as PDF Report</Button>
      </Tooltip>
    </Box >
  );
};
