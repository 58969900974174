import history from '_helpers/history';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Container, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import config from '_configs';
import { Box } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

const cardXsSize = 4;

const cardSX = { paddingTop: 0 };

function HandleGoToPage(page: string) {
  history.push(page);
}

export interface Size {
  mWidth: number;
  height: string;
  titleVariant: Variant;
}

export interface PageCardProps {
  name: string;
  pathurl: string;
  descriptionText: string;
  imagePath: string;
  size: Size;
}

const productSize: Size = {
  mWidth: 360,
  height: '330px',
  titleVariant: 'h4',
};

const accessSize: Size = {
  mWidth: 260,
  height: '260px',
  titleVariant: 'h6',
};

function PageCard({ name, pathurl, descriptionText, imagePath, size }: PageCardProps) {
  return (
    <Card sx={{ maxWidth: size.mWidth }}>
      <CardActionArea sx={{ height: size.height }} onClick={() => HandleGoToPage(pathurl)}>
        <CardMedia component="img" height={size.mWidth / 2.5} image={imagePath} alt={name} />
        <CardContent>
          <Typography gutterBottom variant={size.titleVariant} component="div">
            {name}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {descriptionText}
          </Typography>
        </CardContent>
        <Box sx={{ height: '100%' }} />
      </CardActionArea>
    </Card>
  );
}

function Home() {
  return (
    <Container>
      <div className="header">
        <Box sx={{ marginTop: '10px', marginBottom: '10px' }}>
          <Typography variant="h4" color="primary">
            Welcome to the Simmunome Platform
          </Typography>
        </Box>
      </div>
      <Divider></Divider>
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 1, md: 1 }}
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        sx={{ marginTop: '10px', marginBottom: '30px' }}
      >
        <Grid item xs={cardXsSize} sx={cardSX}>
          <PageCard
            name="Target Validation"
            pathurl={config.clientUrls.TargetValidation}
            descriptionText="Validate that a molecular target has a chance of making an effect on a biological network by leveraging our AI disease graph algorithms."
            size={productSize}
            imagePath="/assets/img/cards/targetvalidation.webp"
          ></PageCard>
        </Grid>

        <Grid item xs={cardXsSize}>
          <PageCard
            name="Visualization"
            pathurl={config.clientUrls.VISUALIZATION}
            descriptionText="Visualize your proteomics data using Simmunome's plotting engine."
            size={productSize}
            imagePath="/assets/img/cards/visualization.webp"
          ></PageCard>
        </Grid>

        <Grid item xs={cardXsSize}>
          <PageCard
            name="Biomarkers"
            pathurl={config.clientUrls.BIOMARKERS}
            descriptionText="Explore higher-order features in your dataset."
            size={productSize}
            imagePath="/assets/img/cards/biomarkers.jpg"
          ></PageCard>
        </Grid>
      </Grid>

      <Divider></Divider>
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 1, md: 1 }}
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        sx={{ marginTop: '10px' }}
      >
        <Grid item xs={cardXsSize}>
          <PageCard
            name="Models"
            pathurl={config.clientUrls.MODELS}
            descriptionText="View our available disease models or create your own customized data-driven models."
            size={accessSize}
            imagePath="/assets/img/cards/models.webp"
          ></PageCard>
        </Grid>

        <Grid item xs={cardXsSize}>
          <PageCard
            name="My Projects"
            pathurl={config.clientUrls.PROJECTS}
            descriptionText="Manage and continue your progress on your saved projects."
            size={accessSize}
            imagePath="/assets/img/cards/projects.webp"
          ></PageCard>
        </Grid>
        <Grid item xs={cardXsSize}>
          <PageCard
            name="My Data"
            pathurl={config.clientUrls.FILES}
            descriptionText="Upload, edit and view your saved data on the Simmunome platform."
            size={accessSize}
            imagePath="/assets/img/cards/data.webp"
          ></PageCard>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Home;
