import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface DeleteButtonProps {
  handleDelete: Function;
}

export default function DeleteButton({ handleDelete }: DeleteButtonProps) {
  function handleDeleteWithConfirm() {
    if (confirm('Are you sure you want to delete? This action cannot be undone.')) {
      handleDelete();
    }
  }
  return (
    <div>
      <Tooltip title="Delete">
        <IconButton onClick={handleDeleteWithConfirm}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}
