import {
  Box,
  Button,
  Typography,
  useTheme,
  TextField,
  FormControl,
  Paper,
  FormLabel,
  Select,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useIsMounted } from '_hooks/useIsMounted';
import RowType from '_components/base/table/RowType';
import HeadCellType from '_components/base/table/HeadCellType';
import projectsService from '_services/harbor/projects.service';
import { toast } from 'react-toastify';
import { ProjectType, ProjectTypeDisplayDict } from '../ProjectTypeEnum';
import Models from '_pages/Models/Models';
import { withServiceCallHandling } from '_helpers/decorators';

const idHeadCell: HeadCellType = {
  id: '_id',
  numeric: false,
  disablePadding: false,
  label: 'ID',
};
const headCells: HeadCellType[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'current_version',
    numeric: false,
    disablePadding: true,
    label: 'Version',
    rowCellSx: { width: '10px' },
  },
  {
    id: 'created_at',
    numeric: false,
    isDate: true,
    disablePadding: false,
    label: 'Date',
    alignRowCell: 'left',
  },
];

interface CreateProjectRoutineProps {
  onCancel: () => void;
  onSubmit: (insertedId: string) => void;
}

enum ScreenTypes {
  Init = 'init',
  Models = 'models',
}

export default function CreateProjectRoutine({ onCancel, onSubmit }: CreateProjectRoutineProps) {
  const theme = useTheme();
  const [projectName, setProjectName] = useState<string>('');
  const [screen, setScreen] = useState<string>(ScreenTypes.Init);
  const [projectType, setProjectType] = useState<ProjectType>(ProjectType.TargetValidation);
  const [modelsSelected, setModelsSelected] = useState<RowType[]>([]);

  const isMounted = useIsMounted();
  const { getAccessTokenSilently } = useAuth0();

  function handleNext() {
    if (projectType === ProjectType.TargetValidation) {
      setScreen(ScreenTypes.Models);
    }
  }
  function handleBack() {
    if (projectType === ProjectType.TargetValidation) setScreen(ScreenTypes.Init);
  }

  function handleProjectNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setProjectName(event.target.value);
  }

  async function createProjectImpl() {
    const accessToken = await getAccessTokenSilently();
    if (projectType === ProjectType.TargetValidation) {
      return projectsService.createTVProject(accessToken, projectName, {
        model_id: modelsSelected[0][idHeadCell.id] as string,
      });
    } else if (projectType === ProjectType.Visualization) {
      return projectsService.createVisProject(accessToken, projectName);
    }
  }
  const handleCreateProjectClick = withServiceCallHandling(
    createProjectImpl,
    (data) => onSubmit(data._id),
    undefined,
    false,
    () => {
      if (!isMounted()) return false;
      if (!Object.values(ProjectType).includes(projectType)) {
        toast.error('Wrong project type!');
        return false;
      } else if (projectType === ProjectType.TargetValidation && modelsSelected.length !== 1) {
        toast.error('Model is missing');
        return false;
      }
      return true;
    },
  );

  function handleChangeType(event: SelectChangeEvent<ProjectType>) {
    setProjectType(event.target.value as ProjectType);
  }

  const isTVProjectValid: boolean = projectType === ProjectType.TargetValidation && modelsSelected.length > 0;
  const isVisProjectVald: boolean = projectType === ProjectType.Visualization;
  const showCreateButton: boolean = Boolean(projectName) && (isTVProjectValid || isVisProjectVald);

  return (
    <>
      <Box>
        <Typography variant="h5" color="primary">
          Create Project{projectName ? `: ${projectName}` : ''}
        </Typography>
      </Box>
      {screen === ScreenTypes.Init && (
        <Box sx={{ flex: '1 2 auto', width: '100%' }}>
          <TextField
            id="standard-basic"
            label="Project Name"
            variant="standard"
            value={projectName}
            onChange={handleProjectNameChange}
          />
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <FormControl variant="standard">
              <InputLabel id="controlled-select-buttons-group">Type</InputLabel>
              <Select
                labelId="controlled-select-buttons-group"
                id="demo-simple-select"
                value={projectType}
                label="Type"
                onChange={handleChangeType}
              >
                {Object.values(ProjectType).map((v) => (
                  <MenuItem key={v} value={v}>
                    {ProjectTypeDisplayDict[v]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      )}
      {screen === ScreenTypes.Models && (
        <Models
          asPage={false}
          allowMultipleSelect={false}
          allowCreateProject={false}
          allowDelete={false}
          allowRename={false}
          tableTitle="Models"
          onRowsSelected={setModelsSelected}
        />
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pt: 2 }}>
        <Box>
          {screen === ScreenTypes.Init && projectType === ProjectType.TargetValidation && (
            <Button
              disabled={!projectName}
              onClick={handleNext}
              variant="outlined"
              sx={{ mr: 1 /*backgroundColor: theme.palette.primary.main*/ }}
            >
              Next
            </Button>
          )}
          {screen === ScreenTypes.Models && (
            <Button
              onClick={handleBack}
              variant="outlined"
              sx={{ mr: 1 /*backgroundColor: theme.palette.primary.main*/ }}
            >
              Back
            </Button>
          )}
        </Box>
        <Box>
          {showCreateButton && (
            <Button
              onClick={handleCreateProjectClick}
              variant="contained"
              sx={{ mr: 1, backgroundColor: theme.palette.primary.main }}
            >
              Create
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
}
