import { handleResponse } from '_helpers/middleware';
import { APIOptionsType } from '_types/APIOptionsType';

function getUserOrganization(accessToken: string, options?: APIOptionsType): Promise<any> {
  const url = new URL('/api/harbor/organizations/self', window.location.href);
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
    signal: options?.signal,
  }
  return fetch(url.toString(), requestOptions).then(handleResponse);
}

const organizationService = {
  getUserOrganization
}

export default organizationService;