import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Stack, IconButton } from '@mui/material';
import BasicModal from '_components/base/BasicModal';
import RowType, { RowElementType } from '_components/base/table/RowType';
import { ProjectType } from '_pages/Projects/ProjectTypeEnum';
import config from '_configs';
import Projects, { HopBackInProjectButton, NotesWithBadge } from '_pages/Projects/Projects';
import HeadCellType from '_components/base/table/HeadCellType';
import PageHeader from '_components/base/PageHeader';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import BMRoutine from './routine/BMRoutine';
import BMRoutineStore from './routine/BMRoutineStore';
import FeatureHomePageContent from '_components/base/FeatureHomePage';

export default function Biomarkers() {
  const resetBMProject = BMRoutineStore((state) => state.reset);
  const history = useHistory();

  const headCells: HeadCellType[] = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: false,
      label: 'Creation Date',
      isDate: true,
    },
    {
      id: 'updated_at',
      numeric: false,
      disablePadding: false,
      label: 'Last Updated',
      isDate: true,
    },
    {
      id: 'notes',
      numeric: false,
      disablePadding: false,
      label: '',
      isEmpty: true,
      isSelfClickable: false,
      renderElementImpl: (_id: RowElementType, row: RowType) => {
        return (
          <NotesWithBadge row={row} />
        )
      }
    },
    {
      id: '_id',
      numeric: false,
      disablePadding: false,
      label: '',
      isEmpty: true,
      isSelfClickable: true,
      renderElementImpl: (_id: RowElementType) => {
        function goToProject() {
          history.push(config.clientUrls.BIOMARKERS_PROJECT
            (_id));
        }
        return (
          <HopBackInProjectButton onClickFunction={goToProject} />
        );
      },
    },
  ];

  const [modal, setModal] = useState({ open: false });

  function closeModal() {
    setModal({ ...modal, open: false });
  }
  function openModal() {
    resetBMProject();
    setModal({ ...modal, open: true });
  }

  return (
    <>
      <BasicModal
        aria-labelledby="tv-pipeline"
        onClose={closeModal}
        open={modal.open}
        boxSx={{ minWidth: '50vw', maxWidth: '95vw', maxHeight: '95vh', overflow: 'auto' }}
      >
        <BMRoutine />
      </BasicModal>

      <Container>
        <Stack spacing={1}>
          <PageHeader
            title="Biomarkers"
            imageSrc="/assets/img/cards/biomarkers.jpg"
            descriptions={['Explore higher-order features in your dataset.']}
          />

          <FeatureHomePageContent buttonAction={openModal} featureName="Biomarkers">
            <Projects
              headCells={headCells}
              asPage={false}
              tableTitle="Biomarkers Projects"
              entityName="Project"
              fixedType={ProjectType.Biomarkers}
              allowInsert={false}
            />
          </FeatureHomePageContent>
        </Stack>
      </Container>
    </>
  );
}
