import AnalysisDataSelectorRoutine from './AnalysisDataSelectorRoutine';
import BMRoutineStore from './BMRoutineStore';
import DatasetRoutine from './DatasetRoutine';
import { ProjectStatusType } from './ProjectStatusEnum';
import ReconcileRoutine from './ReconcileRoutine';
import RecSummaryRoutine from './RecSummaryRoutine';

export default function BMRoutine() {
  const projectStatus: ProjectStatusType = BMRoutineStore((state) => state.projectStatus);
  if (projectStatus === ProjectStatusType.Init) return <DatasetRoutine />;
  else if (projectStatus === ProjectStatusType.Reconcile) return <ReconcileRoutine />;
  else if (projectStatus === ProjectStatusType.RecSummary) return <RecSummaryRoutine />;
  else if (projectStatus === ProjectStatusType.AnalysisDataSelector) return <AnalysisDataSelectorRoutine />;
  return <></>;
}
