import { Avatar, Box, Button, Paper, Theme, Typography, useTheme } from '@mui/material';
import EnhancedTable from '_components/base/table/EnhancedTable';
import { ReactNode, useEffect, useState } from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import RowType, { RowElementType } from '_components/base/table/RowType';
import { renderRowElement } from '_components/base/table/RowTypeAPI';
import HeadCellType from '_components/base/table/HeadCellType';
import ButtonGroup from '_components/base/ButtonGroup';
import { ColumnRowType } from './ColumnRowType';

const headCells: HeadCellType[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Header',
  },
  {
    id: 'unique_values_no',
    numeric: true,
    disablePadding: false,
    label: 'Unique value found',
    alignRowCell: 'right',
    headCellSx: { maxWidth: 70 },
  },
];

export interface SelectGroupColumnProps {
  selectedFilename: RowElementType;
  // Each column is presented as a row in the table. Column is not refered to table.
  onSubmit: (row: RowType) => void;
  onCancel: () => void;
  selectedFileSize?: number | null;
  initSelectedColumn?: string | null;
  underTableCaption?: ReactNode;
  rows: ColumnRowType[];
}
export default function SelectGroupColumn({
  selectedFilename,
  selectedFileSize = 11677996,
  onSubmit,
  onCancel,
  underTableCaption,
  rows,
  initSelectedColumn = null,
}: SelectGroupColumnProps) {
  const [selected, setSelected] = useState<RowType[]>([]);

  const theme: Theme = useTheme();
  function handleColumnSelect() {
    if (selected.length < 1) return;
    return onSubmit(selected[0]);
  }

  function convertToMbString(sizeInByte: number, dec: number = 2): string {
    return (sizeInByte / 1024 / 1024).toFixed(dec);
  }

  useEffect(() => {
    if (!initSelectedColumn || !rows) return;
    const row = rows.find((row) => row.name === initSelectedColumn);
    setSelected([row] as RowType[]);
  }, [initSelectedColumn, rows]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ pb: 2 }}>
        <Button
          endIcon={
            <Box display="flex" flexDirection="column" justifyContent="center" sx={{ mr: 2 }}>
              <CloseRoundedIcon sx={{ color: 'rgb(147, 166, 208)' }} />
            </Box>
          }
          sx={{
            width: '100%',
            height: '60px',
            display: 'flex',
            justifyContent: 'space-between',
            textTransform: 'none',
            borderWidth: '2px',
            borderStyle: 'dashed',
            borderColor: 'rgb(208, 226, 245)',
          }}
          onClick={onCancel}
        >
          <Box display="flex">
            <Box display="flex" flexDirection="column" justifyContent="center" sx={{ mr: 2, ml: 1 }}>
              <Avatar sx={{ backgroundColor: 'rgb(241, 243, 250)' }}>
                <UploadFileIcon sx={{ color: 'rgb(82, 113, 181)' }} fontSize="medium" />
              </Avatar>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Typography variant="body2" fontWeight={600}>
                {renderRowElement(selectedFilename)}
              </Typography>
              {selectedFileSize && (
                <Typography align="left" color="rgb(183, 196, 224)" variant="caption">
                  {convertToMbString(selectedFileSize)}Mb
                </Typography>
              )}
            </Box>
          </Box>
        </Button>
      </Box>
      <Typography variant="body1" color="dark" sx={{ pb: 2, fontWeight: 800 }}>
        Define the group column
      </Typography>
      <Paper sx={{ width: '100%', mb: 0 }}>
        <EnhancedTable
          idHeadCell={headCells.find((o) => o.id === 'name') || headCells[0]}
          headCells={headCells}
          rows={rows}
          selected={selected}
          setSelected={setSelected}
          allowMultipleSelect={false}
          tableSx={{
            minWidth: 450,
            '& .MuiTableRow-head.MuiTableRow-root': {
              backgroundColor: theme.palette.tables.light,
            },
            '& .MuiTableRow-root.Mui-selected': {
              backgroundColor: theme.palette.tables.dark,
            },

            '& .MuiTableBody-root>.MuiTableRow-root:hover': {
              backgroundColor: theme.palette.tables.main,
            },
          }}
          tableProps={{ size: 'small' }}
          tableHeadRowSx={{ backgroundColor: theme.palette.tables.light }}
        />
      </Paper>
      <>{underTableCaption}</>
      <ButtonGroup
        isNextVisible={true}
        isBackVisible={true}
        isCreateVisible={false}
        isNextDisabled={selected.length < 1}
        onNext={handleColumnSelect}
        onBack={onCancel}
        backLabel="Cancel"
      />
    </Box>
  );
}
