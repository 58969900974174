import {
  Container,
  Typography,
  Box,
  TextField,
  Paper,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useState } from 'react';
import { toast } from 'react-toastify';
import notificationService from '../_services/notification-service';
import { useAuth0 } from '@auth0/auth0-react';

interface FAQItemProps {
  question: string;
  answer: string;
}

function FAQItem({ question, answer }: FAQItemProps) {
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ marginBottom: 1 }}>
      <Box
        onClick={() => setOpen(!open)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          padding: '7px 15px',
          backgroundColor: '#f0f0f0',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          borderBottomLeftRadius: open ? '0px' : '5px',
          borderBottomRightRadius: open ? '0px' : '5px',
        }}
      >
        <Typography variant="h6">{question}</Typography>
        <IconButton>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box
          sx={{
            padding: '7px 15px',
            backgroundColor: '#fafafa',
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
          }}
        >
          <Typography>{answer}</Typography>
        </Box>
      </Collapse>
    </Box>
  );
}

function Report() {
  const { user, getAccessTokenSilently } = useAuth0();
  const SIDE_MARGIN = 30;
  const [messageType, setMessageType] = useState<string>('question');
  const [message, setMessage] = useState<string>('');
  const [altEmail, setAltEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  function isValid() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (altEmail && !emailRegex.test(altEmail)) {
      toast.error('Invalid alternative email format');
      setLoading(false);
      return;
    }
    if (message.trim() === '') {
      toast.error('Invalid message content');
      setLoading(false);
      return;
    }
    if (message.length > 1000) {
      toast.error('Message is too long');
      setLoading(false);
    }
  }

  async function handleSendClick() {
    setLoading(true);
    isValid();

    try {
      const accessToken = await getAccessTokenSilently();
      const recipientEmail = altEmail.trim() !== '' ? altEmail : user?.email;
      if (recipientEmail && user?.email) {
        await notificationService.sendNotification(accessToken, user.email, altEmail, messageType, message);
        toast.success('Message sent!', { autoClose: 1000 });
        setMessage('');
        setAltEmail('');
      } else {
        toast.error('User is not authenticated or email not provided');
      }
    } catch (error) {
      if ((error as any).response && (error as any).response.data && (error as any).response.data.message) {
        if (
          typeof error === 'object' &&
          error !== null &&
          'response' in error &&
          'data' in (error as any).response &&
          'message' in (error as any).response.data
        ) {
          toast.error(`Error: ${(error as any).response.data.message}`);
        } else {
          toast.error('Failed to send the message. Please try again.');
        }
      } else {
        toast.error('Failed to send the message. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box sx={{ marginLeft: SIDE_MARGIN, marginRight: SIDE_MARGIN }}>
      <Paper elevation={3} sx={{ padding: 3 }}>
        <TextField
          fullWidth
          label="Write a message"
          multiline
          rows={10}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          label="Alternative Contact Email (optional)"
          placeholder="Specify another email to receive replies"
          value={altEmail}
          onChange={(e) => setAltEmail(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <FormControl>
            <FormLabel>Message type</FormLabel>
            <RadioGroup row value={messageType} onChange={(event) => setMessageType(event.target.value)}>
              <FormControlLabel value="question" control={<Radio />} label="Question" />
              <FormControlLabel value="bug" control={<Radio />} label="Bug Report" />
              <FormControlLabel value="other" control={<Radio />} label="Other" />
            </RadioGroup>
          </FormControl>
          <Button
            disabled={loading || message === ''}
            variant="contained"
            sx={{ margin: 'auto', marginRight: 0 }}
            onClick={handleSendClick}
          >
            {loading ? <CircularProgress size={24} /> : 'Send'}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default function Help() {
  const faq = [
    {
      question: 'How do I upload my files?',
      answer:
        "Go to My Data, and click on the '+' button. Make sure your data is in the correct format, and follow the instructions.",
    },
    {
      question: 'How do I change my password?',
      answer:
        'Logout, and click on login. If you click on the forgot password button, you should be able to change your password.',
    },
    {
      question: 'How do I upgrade my tier/ get more credits?',
      answer:
        'You can send an email to your Simmunome contact, or to support@simmunome.com. You can also go to the Account page.',
    },
  ];

  return (
    <Container>
      <Box sx={{ marginTop: '10px', marginBottom: '10px' }}>
        <Typography variant="h4" color="primary" align="center">
          FAQ
        </Typography>
      </Box>
      {faq.map((item, index) => (
        <FAQItem key={index} question={item.question} answer={item.answer} />
      ))}
      <Box sx={{ marginTop: '30px', marginBottom: '10px' }}>
        <Typography variant="h5" color="primary" align="center">
          {"Can't find an answer? Ask us!"}
        </Typography>
      </Box>
      <Report />
    </Container>
  );
}
