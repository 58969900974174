import { handleResponse } from '_helpers/middleware';

/**
 * Send a notification message to the helpdesk
 * @param {string} accessToken - Authorization token for the API
 * @param {string} clientEmail - Email of the recipient (logged-in user)
 * @param {string} messageType - Type of message (e.g., question, bug)
 * @param {string} message - Content of the message
 * @returns Promise
 */
interface SendNotificationRequest {
    clientEmail: string;
    messageType: string;
    message: string;
}

async function sendNotification(
    accessToken: string,
    clientEmail: string,
    altEmail: string, //
    messageType: string,
    message: string,
): Promise<any> {
    console.log("sendNotification");
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            clientEmail,
            altEmail,
            messageType,
            message,
        } as SendNotificationRequest),
    };

    const response = await fetch('/api/notification/send_notification', requestOptions);
    if (!response.ok) {
        const errorData = await response.json();
        throw { response: { data: errorData } };
      }
    
    return handleResponse(response);
}

const notificationService = {
    sendNotification,
};

export default notificationService;
