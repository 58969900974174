import { Card, CardContent, CardMedia, Typography } from "@mui/material";

interface PageHeaderProps {
    imageSrc: string;
    title: string;
    descriptions: string[];
    simplified?: boolean;
}

export default function PageHeader({ imageSrc, title, descriptions, simplified = false }: PageHeaderProps) {
    return (<Card raised sx={{ width: '100%', position: 'relative', marginBottom: 2 }}>
        {!simplified && (
            <CardMedia
                sx={{ height: 100, }}
                image={imageSrc}
                title={title}
            />
        )}
        <CardContent>
            <Typography variant="h4" component="div" color="primary">
                {title}
            </Typography>
            {!simplified && descriptions.map(
                (description: string, indx: number) => (
                    <Typography key={`descr-${indx}`} variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                )
            )}
        </CardContent>
    </Card>);
} 