import { handleResponseFile } from '_helpers/middleware';

/**
 * get report
 * @returns Promise (file)
 */
function getReport(projectId, accessToken) {
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  return fetch(`/api/reports/${projectId}`, requestOptions).then(handleResponseFile);
}

const reportService = {
  getReport,
};

export default reportService;
