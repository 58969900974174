import { useAuth0 } from '@auth0/auth0-react';
import { useIsMounted } from '_hooks/useIsMounted';
import projectsService from '_services/harbor/projects.service';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PlotsSSFromProject from '_components/plots/PlotsSSFromProject';
import InternalFileObjType from '_components/base/table/InternalFileObj';
import SaveProjectModal from '_pages/Projects/Routines/SaveProjectModal';
import { Box, Button, Chip, Container, Stack, Typography } from '@mui/material';
import { withServiceCallHandling } from '_helpers/decorators';
import Notepad, { Note } from '_components/notepad';
import PdfDownloadableWrap from '_components/pdfDownloadableWrap';

export interface UploadType {
  disease: string;
  files: InternalFileObjType[];
  [key: string]: string | InternalFileObjType[];
}

export interface VisProjectType {
  name: string;
  is_default: boolean;
  upload: UploadType;
  group_column: string;
  group_values: string[];
  notes: Note[];
  [key: string]: string | string[] | UploadType | boolean | Note[];
}

export default function VisProject() {
  const isMounted = useIsMounted();
  const { getAccessTokenSilently } = useAuth0();
  const [projectData, setProjectData] = useState<VisProjectType>();
  const { projectId } = useParams<any>();
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    getVisProjectInfo();
  }, []);

  async function saveProjectImpl(name: string) {
    const accessToken = await getAccessTokenSilently();
    return projectsService.saveProject(accessToken, projectId, name);
  }
  const handleSaveProject = withServiceCallHandling(saveProjectImpl, (data) => {
    setProjectData({ ...projectData, ...data }), setOpenModal(false);
  });

  async function getVisProjectImpl() {
    const accessToken = await getAccessTokenSilently();
    return projectsService.getVisProject(accessToken, projectId);
  }
  const getVisProjectInfo = withServiceCallHandling(
    getVisProjectImpl,
    (data) => {
      setProjectData(data);
    },
    undefined,
    false,
    () => isMounted(),
  );

  if (!projectData) return <></>;
  return (
    <Container>
      <SaveProjectModal
        open={openModal}
        onCancel={() => {
          setOpenModal(false);
        }}
        onSubmit={handleSaveProject}
      />
      <Notepad projectId={projectId} projectNotes={projectData.notes ?? []} />
      <Stack spacing={2}>
        <Box className="header">
          <Typography variant="h4" color="primary">
            Visualization
          </Typography>
        </Box>

        <PlotsSSFromProject projectId={projectId} projectData={projectData} setOpenModal={setOpenModal} />
      </Stack>
    </Container >
  );
}
