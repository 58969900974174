import { create } from 'zustand';
import { DataRecordType } from '../DataRecordType';
import { ProjectStatusType } from './ProjectStatusEnum';
import { RecGroupType } from './RecGroupType';


export interface BMRoutineStoreType {
  dataRecords: DataRecordType[];

  projectName: string;
  projectStatus: ProjectStatusType;
  recGroups: RecGroupType[];
  setInitBMProject: (projectName: string, records: DataRecordType[]) => void;
  setProjectStatus: (projectStatus: ProjectStatusType) => void;
  setRecGroups: (recGroups: RecGroupType[]) => void;
  addDataRecord: (record: DataRecordType) => void;
  removeDataRecord: (record: DataRecordType) => void;
  reset: () => void;
}

const INIT_DATA_RECORDS: DataRecordType[] = [];
const INIT_PROJECT_NAME: string = '';
const INIT_PROJECT_STATUS: ProjectStatusType = ProjectStatusType.Init;
const INIT_REC_GROUPS: RecGroupType[] = [];

const BMRoutineStore = create<BMRoutineStoreType>((set) => ({
  dataRecords: INIT_DATA_RECORDS,
  projectName: INIT_PROJECT_NAME,
  projectStatus: INIT_PROJECT_STATUS,
  recGroups: INIT_REC_GROUPS,
  setInitBMProject: (projectName: string, dataRecords: DataRecordType[]) => set(() => ({ dataRecords, projectName, projectStatus: ProjectStatusType.Reconcile })),
  setProjectStatus: (status: ProjectStatusType) => set(() => ({ projectStatus: status })),
  setRecGroups: (recGroups: RecGroupType[]) => set(() => ({ recGroups, projectStatus: ProjectStatusType.RecSummary })),
  addDataRecord: (recordVal: DataRecordType) => set((state) => ({ dataRecords: [...state.dataRecords, recordVal] })),
  removeDataRecord: (recordVal: DataRecordType) => set((state) => ({ dataRecords: state.dataRecords.filter(o => o.fileUpload?._id !== recordVal.fileUpload?._id) })),
  reset: () => set(() => ({ dataRecords: INIT_DATA_RECORDS, projectName: INIT_PROJECT_NAME, projectStatus: INIT_PROJECT_STATUS, recGroups: INIT_REC_GROUPS })),
}));

export default BMRoutineStore;
