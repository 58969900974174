import { useState, useEffect } from 'react';
import VisualizationService from '_services/visualization.service';
import { useAuth0 } from '@auth0/auth0-react';
import LocalPlot from './LocalPlot';
import type { Data, Layout } from 'plotly.js';
import TableOfContents from '_components/TableOfContent';
import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import { withServiceCallHandling } from '_helpers/decorators';
import PdfDownloadableWrap from '_components/pdfDownloadableWrap';
import { VisProjectType } from '_pages/Visualization/VisProject';

interface PlotSSProps {
    projectId: string;
    projectData: VisProjectType;
    setOpenModal: Function;
}

type PlotType = { data: Data[] | null, layout: Partial<Layout> | null };

const PLOT_DEFAULT_STATE: PlotType = { data: null, layout: null };

const PLOT_KINDS: string[] = ['boxplot', 'heatmap', 'lineplot_cv_dr', 'lineplot_var', 'volcano'];

type TocType = { [key: string]: string };

const TOC_DICT: TocType = {
    'boxplot': 'Boxplot',
    'heatmap': 'Heatmap',
    'lineplot_cv_dr': 'Line Plot (CV DR)',
    'lineplot_var': 'Line Plot (Variance)',
    'volcano': 'Volcano plot'
};

type AggType = { [key: string]: PlotType };

const PLOTS_DEFAULT_STATE = PLOT_KINDS.reduce((agg: AggType, cur: string) => {
    agg[cur] = PLOT_DEFAULT_STATE;
    return agg;
}, {});

export default function PlotsSS({ projectId, projectData, setOpenModal }: PlotSSProps) {
    const { getAccessTokenSilently } = useAuth0();
    const [plots, setPlots] = useState(PLOTS_DEFAULT_STATE);

    async function getPlotsSSFromProjectImpl() {
        const accessToken = await getAccessTokenSilently();
        return await VisualizationService.plotsSSFromProject(accessToken, projectId);
    }
    const getPlotsSSFromProject = withServiceCallHandling(
        getPlotsSSFromProjectImpl,
        (data, response) => setPlots(response),
        undefined,
        "Preparing data..."
    )
    useEffect(() => {
        getPlotsSSFromProject();
    }, [projectId]);
    const headings = PLOT_KINDS
        .filter(plotKind => plots[plotKind]?.layout)
        .map(plotKind => ({ hash: plotKind, label: TOC_DICT[plotKind] }));
    return (<>
        {headings && headings.length > 0 && (
            <div className="toc-container" style={{ position: "fixed", top: "100px", right: "10px", zIndex: '1' }}>
                <TableOfContents headings={headings} />
            </div>
        )}
        <PdfDownloadableWrap>
            <Box sx={{ marginTop: '10px' }} display="block">
                {!projectData.is_default && (
                    <Typography variant="h4" color="primary">
                        Project: {projectData.name}
                    </Typography>
                )}

                <Typography variant="body1" color="primary" fontStyle="italic" align="left">
                    Files:
                </Typography>
                <Stack direction="row" justifyContent="start">
                    {projectData.upload.files.map((f, indx) => (
                        <Chip key={`chip-${indx}`} label={f._id} size="medium" />
                    ))}
                </Stack>


                <Typography variant="body1" color="primary" fontStyle="italic" align="left">
                    Groups: {projectData.group_values.join(', ')}
                </Typography>

                {projectData.is_default && (
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setOpenModal(true);
                        }}
                    >
                        Save Project
                    </Button>
                )}
            </Box>
            <Box sx={{ width: "90%", margin: "20px" }}>
                {PLOT_KINDS.map((plotKind: string) => (
                    plots[plotKind]?.data && plots[plotKind]?.layout && (
                        <section key={plotKind} id={plotKind}>
                            <h2>{TOC_DICT[plotKind]}</h2>
                            <LocalPlot
                                key={plotKind}
                                data={plots[plotKind].data as Data[]}
                                layout={plots[plotKind].layout as Partial<Layout>}
                                divId={`${plotKind}-lp`} />
                            <div className='page-break' />
                        </section>
                    )
                ))}
            </Box>
        </PdfDownloadableWrap>
    </>);
}
