import InternalFileObjType from "_components/base/table/InternalFileObj";
import RowType from "_components/base/table/RowType";
import config from "_configs";
import { ProjectType } from "_pages/Projects/ProjectTypeEnum";
import { toast } from "react-toastify";

export const isNone = (val: any) => val === undefined || val === null;
// multiple exports expected

export const humanReadableSize = (bytes: number) => {
    let size = Math.trunc(bytes);
    for (let unit of ['b', 'Kb', 'Mb', 'Gb']) {
        if (size < 1024) return `${size.toFixed(2)} ${unit}`
        size /= 1024.0
    }
}

export const convertProjectType2ProjectUrl = (projectType: ProjectType, projectId: string) => {
    switch (projectType) {
        case ProjectType.TargetValidation:
            return config.clientUrls.NODE_PROCESS_PATHS__TV_PROJECT(projectId);
        case ProjectType.Visualization:
            return config.clientUrls.VISUALIZATION_PROJECT(projectId);
        case ProjectType.Biomarkers:
            return config.clientUrls.BIOMARKERS_PROJECT(projectId);
        default:
            toast.error('Wrong Project Type');
            return config.clientUrls.PROJECTS;
    }
}

export const extractPrefixFromFileUpload = (fileUpload: RowType | null) => {
    const testString = (fileUpload?.files as InternalFileObjType[])[0]._id || '';
    const datasetNameRe = /^(.+)(\.[^.]+\.[^.]+)/g.exec(testString);
    const prefix = datasetNameRe ? datasetNameRe[1] : '';
    return prefix
}