import InternalIdNameObjType from "_components/base/table/InternalIdNameObj";

export function isInternalIdNameObjType(obj: any): obj is InternalIdNameObjType {
    return typeof obj === "object" &&
        "_id" in obj &&
        "name" in obj;
}

export function isInternalIdNameObjArrayType(elm: any): elm is InternalIdNameObjType[] {
    return Array.isArray(elm) && elm.every(obj => isInternalIdNameObjType(obj))
}
