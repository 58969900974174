import Table, { TableProps } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { Container } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import HeadCellType from './HeadCellType';

import RowType from './RowType';
import { renderRowElement } from './RowTypeAPI';

export interface BasicTableProps {
  title: string;
  rows: RowType[];
  headCells: HeadCellType[];
  tableSx?: SxProps<Theme>;
  tableBodyRowSx?: SxProps<Theme>;
  tableHeadSx?: SxProps<Theme>;
  tableHeadRowSx?: SxProps<Theme>;
  tableHeadCellSx?: SxProps<Theme>;
  tableProps?: TableProps;
}

export default function BasicTable<T>({
  title,
  rows,
  headCells,
  tableSx,
  tableBodyRowSx,
  tableHeadCellSx,
  tableHeadRowSx,
  tableHeadSx,
  tableProps,
}: BasicTableProps) {
  return (
    <TableContainer component={Paper}>
      <Table sx={tableSx} aria-label="simple table" {...tableProps}>
        <TableHead sx={tableHeadSx}>
          {title !== '' && (
            <Container sx={{ marginTop: 1 }}>
              <Typography variant="h5">{title}</Typography>
            </Container>
          )}
          <TableRow sx={tableHeadRowSx}>
            {headCells.map((cell) => (
              <TableCell sx={tableHeadCellSx} key={cell.id}>
                <Typography variant="h6">{cell.label}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={tableBodyRowSx || { '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              {headCells
                .filter((cell) => cell.id !== 'name')
                .map((cell) => (
                  <TableCell align="left" key={`${row.name}-${cell.id}`}>
                    {renderRowElement(row[cell.id])}
                  </TableCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
