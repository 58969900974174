import { Divider, Paper, Typography } from '@mui/material';

interface noAcessProps {
  resourceName: string;
}

export default function NoAccess({ resourceName }: noAcessProps) {
  return (
    <Paper sx={{ p: 4 }}>
      <Typography variant="h5">{`Your organization doesn't have access to ${resourceName}.`}</Typography>
      <Divider sx={{ m: 1 }}></Divider>
      <Typography>{`Contact your organization to get access to this feature.`}</Typography>
    </Paper>
  );
}
