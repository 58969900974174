import { useEffect } from 'react';
import { useLoadGraph } from '@react-sigma/core';
import { useLayoutForceAtlas2 } from '@react-sigma/layout-forceatlas2';
import { DirectedGraph } from 'graphology';
import { isNone } from '_helpers/data';
import { graphTheme } from '_theme/graphTheme';
import AiPredStore from '_pages/TargetValidation/FilterAIPredictionStore';
import { toggleAiPredictedEdgesOnGraph } from '_helpers/sigmaEvents';

const NODE_SIZE = 5;

// Load Sigma Graph given @diseaseData data and use ForceAtlas2 Layout
function GraphDataController({ diseaseData }) {
  const loadGraph = useLoadGraph();
  const { positions, assign } = useLayoutForceAtlas2({
    iterations: 50,
    settings: {
      gravity: 5,
      barnesHutOptimize: true,
    },
  });

  function getNodeColor(isEndpoint) {
    return isEndpoint ? graphTheme.ENDPOINT_COLOR : graphTheme.NODE_DEFAULT_COLOR;
  }
  function getEdgeColor(isPredicted) {
    return isPredicted ? graphTheme.AI_PRED_EDGE_COLOR : graphTheme.EDGE_DEFAULT_COLOR;
  }
  useEffect(() => {
    // run only once
    if (isNone(diseaseData.disease)) {
      return;
    }

    // Create the graph
    const graph = new DirectedGraph();
    diseaseData.nodes.forEach((node) => {
      // In case concFile was provided -
      //   concFactor must take into account the weight (node.conc)
      //   (Conc * 2 - 1) * Magnitude
      //   Magitude is 3
      //   Conc (if any) is in range [0, 1]
      // In case node.conc is undefined - concFactor is null and can be ignored
      const concFactor = ((node.conc || 0.5) * 2 - 1) * 3;
      const x_pos = Math.random();
      const y_pos = Math.random();

      graph.addNode(node.id, {
        id: node.id,
        x: x_pos,
        y: y_pos,
        label: node.name,
        originalLabel: node.name,
        size: NODE_SIZE + concFactor,
        color: getNodeColor(node.is_endpoint),
        originalColor: getNodeColor(node.is_endpoint),
        stringId: node.stringId,
        annotation: node.annotation,
        keywords: node.keywords,
        filteron: false,
        hidden: false,
      });
    });

    diseaseData.links.forEach((link) => {
      graph.addDirectedEdgeWithKey(`${link.source}>${link.target}`, link.source, link.target, {
        predicted: link.predicted,
        relation: link.relation,
        color: getEdgeColor(link.predicted),
        originalColor: getEdgeColor(link.predicted),
        hidden: false,
      });
    });
    loadGraph(graph);
    const aiToggle = AiPredStore.getState().filterBooleanValue;
    const aiCutoff = AiPredStore.getState().filterCutoff;
    toggleAiPredictedEdgesOnGraph(graph, aiToggle, aiCutoff);

    assign();
  }, [assign, positions, loadGraph, diseaseData]);
  return null;
}

export default GraphDataController;
