import { Table, TableCell, TableRow } from '@mui/material';
import { graphTheme } from '_theme/graphTheme';

interface LegendRowEntryProps {
  color: string;
  coloredText: string;
  descriptionText: string;
}

function LegendRowEntry({ color, coloredText, descriptionText }: LegendRowEntryProps) {
  const textfontsize = '80%';

  const tableCellStyles = {
    textAlign: 'left',
    padding: '4px',
  };
  return (
    <TableRow>
      <TableCell sx={tableCellStyles}>
        <span style={{ color: color, fontSize: textfontsize }}>{coloredText}</span>
      </TableCell>
      <TableCell sx={tableCellStyles}>
        <span style={{ fontSize: textfontsize }}>{descriptionText}</span>
      </TableCell>
    </TableRow>
  );
}

export default function GraphLegendTable() {
  const tableStyles = {
    margin: 'auto',
    width: '100%',
  };
  return (
    <Table className="Legend" style={tableStyles}>
      <LegendRowEntry
        color={graphTheme.EDGE_DEFAULT_COLOR}
        coloredText="Known"
        descriptionText="Known Interaction"
      ></LegendRowEntry>
      <LegendRowEntry
        color={graphTheme.AI_PRED_EDGE_COLOR}
        coloredText="Predicted"
        descriptionText="AI-predicted Interaction"
      ></LegendRowEntry>
      <LegendRowEntry
        color={graphTheme.NODE_SELECTED_COLOR}
        coloredText="Target"
        descriptionText="The Selected Molecular Node"
      ></LegendRowEntry>
      <LegendRowEntry
        color={graphTheme.NODE_IN_PATH_COLOR}
        coloredText="Intermediate"
        descriptionText="Node Inside The Pathway"
      ></LegendRowEntry>
      <LegendRowEntry
        color={graphTheme.ENDPOINT_COLOR}
        coloredText="Endpoint"
        descriptionText="The Disease Endpoint"
      ></LegendRowEntry>
    </Table>
  );
}
