import { toast } from 'react-toastify';

export function withServiceCallHandling(
    target: any,
    successCallback?: (data: any, response: any) => any,
    errorCallback?: () => void,
    loading?: boolean | string,
    preFunc?: () => boolean, // logic to exit function in advance
) {
    const originalMethod = target;
    return async function (...args: any[]) {
        if (preFunc && !preFunc()) return;
        let toastId;
        if (loading) {
            toastId = toast.loading(typeof loading === "string" ? loading : "Processing...");
        }
        try {
            const response = await originalMethod.apply(this, args);
            const { data, message } = response;
            if (toastId) {
                toast.update(toastId, {
                    type: 'success',
                    autoClose: 2000,
                    render: message || "Complete",
                    isLoading: false
                });
            } else if (message) {
                toast.success(message);
            }
            let successReturnVal;
            if (successCallback) successReturnVal = successCallback(data, response);
            return successReturnVal || data || response;
        } catch (error: any) {
            if (error === 'unmounted') return false;
            console.error(error);
            const errorMessage = error.message || "An error occurred";
            if (toastId) {
                toast.update(toastId, {
                    type: 'error',
                    autoClose: false,
                    render: errorMessage,
                    isLoading: false
                });
            } else {
                toast.error(errorMessage);
            }
            if (errorCallback) errorCallback();
            return false;
        }
    };
}

interface ApiResponse {
    message?: string;
    data?: any;
    sse_channel?: string;
}
type APIFunction<T> = (accessToken: string, data?: T) => T | Promise<T> | void;
type SuccessCallback<T> = (data: T) => void;
type ErrorCallback = (error: any) => void;

export function withAPICall<T extends ApiResponse>(
    getAccessToken: () => Promise<string>,
    functions: APIFunction<T>[],
    successCallback?: SuccessCallback<(T | undefined)[]>,
    errorCallback?: ErrorCallback,
    isLoading?: boolean | string,
    preFunc?: () => boolean, // logic to exit function in advance 
) {
    return async function (...args: any[]) {
        if (preFunc && !preFunc()) return;
        let toastId;
        try {
            const accessToken = await getAccessToken();
            if (isLoading) {
                toastId = toast.loading(typeof isLoading === "string" ? isLoading : "Processing...");
            }

            const results: (T | undefined)[] = [];
            let result: T | undefined;

            for (const func of functions) {
                const res = func(accessToken, result);
                if (res instanceof Promise) {
                    result = await res;
                } else if (res) {
                    result = res;
                } else {
                    result = undefined;
                }
                results.push(result);
                if (toastId) {
                    toast.update(toastId, {
                        type: 'success',
                        autoClose: 2000,
                        render: result?.message || "Complete",
                        isLoading: false
                    });
                } else if (result?.message) {
                    toast.success(result.message);
                }
            }

            if (successCallback) successCallback(results);
        } catch (error: any) {
            console.error(error);
            const errorMessage = error.message || "An error occurred";
            if (toastId) {
                toast.update(toastId, {
                    type: 'error',
                    autoClose: false,
                    render: errorMessage,
                    isLoading: false
                });
            } else {
                toast.error(errorMessage);
            }
            if (errorCallback) errorCallback(error);
            return;
        }
    }
}