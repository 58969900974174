import { Box, Button, Paper, Theme, Typography, useTheme } from '@mui/material';
import BasicTable from '_components/base/table/BasicTable';
import { ColumnRowType } from './ManageGroupColumn';
import { RowElementType } from '_components/base/table/RowType';
import { renderRowElement } from '_components/base/table/RowTypeAPI';
import ButtonGroup from '_components/base/ButtonGroup';

const verifyHeadCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Groups',
  },
];

export interface VerifyGroupValuesProps {
  selectedFilename: RowElementType;
  onSubmit: () => void;
  onCancel: () => void;
  selectedFileSize?: number | null;
  selectedColumnData: ColumnRowType;
}
export default function VerifyGroupValues({
  selectedFilename,
  selectedFileSize = 11677996,
  onSubmit,
  onCancel,
  selectedColumnData,
}: VerifyGroupValuesProps) {
  const theme: Theme = useTheme();

  const rows = (selectedColumnData.unique_values || []).map((name: string) => ({ name: name }));

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ pb: 2 }}>
        <Typography variant="body1" color="dark" sx={{ pb: 0, fontWeight: 800 }}>
          {renderRowElement(selectedFilename)}
        </Typography>
        <Typography variant="subtitle2" color="dark" fontStyle="italic">
          Group column: {selectedColumnData.name}
        </Typography>
        <Typography variant="subtitle2" color="dark" fontStyle="italic">
          {selectedColumnData.unique_values_no} groups found in column {selectedColumnData.name}
        </Typography>
      </Box>
      <Paper sx={{ width: '100%', mb: 0 }}>
        <BasicTable
          title=""
          rows={rows}
          headCells={verifyHeadCells}
          tableProps={{ size: 'small' }}
          tableSx={{
            minWidth: 450,
            '& .MuiTableRow-head.MuiTableRow-root': {
              backgroundColor: theme.palette.tables.light,
            },
            '& .MuiTableRow-root.Mui-selected': {
              backgroundColor: theme.palette.tables.dark,
            },

            '& .MuiTableBody-root>.MuiTableRow-root:hover': {
              backgroundColor: theme.palette.tables.main,
            },
          }}
        />
      </Paper>

      <ButtonGroup
        isNextVisible={true}
        isBackVisible={true}
        isCreateVisible={false}
        onBack={onCancel}
        onNext={onSubmit}
        nextLabel="Confirm"
        backLabel="Cancel"
      />


    </Box>
  );
}
