import { handleResponse } from '_helpers/middleware';

const URLS = {
    listBaseModels: '/api/harbor/base_models',
};

/**
 * List base models
 * @returns Promise
 */
function listBaseModels(accessToken) {
    const requestOptions = {
        method: 'GET',
        headers: { Authorization: `Bearer ${accessToken}` },
    };
    return fetch(URLS.listBaseModels, requestOptions).then(handleResponse);
}

const baseModelsServices = {
    listBaseModels,
    URLS
};

export default baseModelsServices;
