import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import './frontpage.scss';

const MovingPoint = () => {
  const svgRef = useRef();

  useEffect(() => {
    var mousecoords = [0, 0];

    const svg = d3.select(svgRef.current).on('mousemove', (event) => {
      mousecoords = d3.pointer(event);
    });
    svg.selectAll('*').remove();

    const numPoints = 50;
    const probabilityWhite = 0.7;

    const positions = [];
    const speeds = [];
    const circlePairs = [];
    const colors = [];
    const components = [];
    const activation = [];

    var width = window.innerWidth;
    var height = window.innerHeight;
    if (width >= height) {
      var minx = -(100 * width / height - 100) / 2;
      var maxx = 100 + (100 * width / height - 100) / 2;
      var miny = 0;
      var maxy = 100;
    }
    else {
      var minx = 0;
      var maxx = 100;
      var miny = -(100 * height / width - 100) / 2;
      var maxy = 100 + (100 * height / width - 100) / 2;
    }
    const hiddenmargin = 25;

    for (let i = 0; i < numPoints; i++) {
      const posx = (Math.random()) * (maxx - minx + 2 * hiddenmargin) + minx - hiddenmargin;
      const posy = (Math.random()) * (maxy - miny + 2 * hiddenmargin) + miny - hiddenmargin;

      const angle = Math.random() * 2 * Math.PI;
      const speed = (Math.random() / 30 + 0.04)
      const speedx = speed * Math.cos(angle);
      const speedy = speed * Math.sin(angle);

      positions.push([posx, posy]);
      speeds.push([speedx, speedy]);
      activation.push(0);
    }

    for (let i = 0; i < numPoints; i++) {
      var tempcolors=[];
      var tempcomponents=[];
      for (let j = 0; j < numPoints; j++) {
        if (j>i) {
          if (Math.random() < probabilityWhite) {
            tempcolors.push(true)
          }
          else {
            tempcolors.push(false)
          }
  
          tempcomponents.push(null)
        }
        else{
          tempcolors.push(null)
          tempcomponents.push(null)
        }
      }
      colors.push(tempcolors)
      components.push(tempcomponents) 
    }

    for (let i = 0; i < numPoints; i++) {
      circlePairs.push(svg.append('circle')
        .attr('cx', positions[i][0])
        .attr('cy', positions[i][1])
        .attr('r', 0.35)
        .attr('fill', 'white')
      )
    }

    function updateItem(i,j) {
      var posx1,posy1,posx2,posy2,col,opacity,dist

      posx1 = positions[i][0]
      posy1 = positions[i][1]
      posx2 = positions[j][0]
      posy2 = positions[j][1]
      
      if (colors[i][j]) {
        col="white"

        dist = Math.sqrt((posx1 - posx2) * (posx1 - posx2) + (posy1 - posy2) * (posy1 - posy2))

        opacity = 1 / (1 + Math.exp((dist - 20) / 4))

        if (opacity < 0.025) {
          opacity = 0
        }
      }
      else {
        col="#FECE61"

        opacity = activation[i] * activation[j] * 6
      }

      if (opacity < 0.025) {
        if (components[i][j]!=null) {
          components[i][j].remove()
          components[i][j]=null
        }
      }
      else {
        if (components[i][j]==null) {
          components[i][j]=svg
            .append("line")
            .style("stroke", col)
            .style("stroke-width", 0.2)
            .style("opacity", opacity)
            .attr("x1", posx1)
            .attr("y1", posy1)
            .attr("x2", posx2)
            .attr("y2", posy2)
        }
        else {
          components[i][j].attr("x1", posx1)
          components[i][j].attr("y1", posy1)
          components[i][j].attr("x2", posx2)
          components[i][j].attr("y2", posy2)
          components[i][j].style("opacity", opacity)
        }
      }
    }

    // Function to update the position of the circle
    function updatePosition() {
      var dist

      width = window.innerWidth;
      height = window.innerHeight;
      if (width >= height) {
        minx = -(100 * width / height - 100) / 2;
        maxx = 100 + (100 * width / height - 100) / 2;
        miny = 0;
        maxy = 100;
      }
      else {
        minx = 0;
        maxx = 100;
        miny = -(100 * height / width - 100) / 2;
        maxy = 100 + (100 * height / width - 100) / 2;
      }

      for (let i = 0; i < numPoints; i++) {
        positions[i][0] += speeds[i][0];
        positions[i][1] += speeds[i][1];

        if (positions[i][0] > maxx + hiddenmargin) {
          positions[i][0] = minx - hiddenmargin;
        }

        if (positions[i][0] < minx - hiddenmargin) {
          positions[i][0] = maxx + hiddenmargin;
        }

        if (positions[i][1] > maxy + hiddenmargin) {
          positions[i][1] = miny - hiddenmargin;
        }

        if (positions[i][1] < miny - hiddenmargin) {
          positions[i][1] = maxy + hiddenmargin;
        }

        circlePairs[i].attr('cx', positions[i][0]);
        circlePairs[i].attr('cy', positions[i][1]);

        dist = Math.sqrt((mousecoords[0] - positions[i][0]) * (mousecoords[0] - positions[i][0]) + (mousecoords[1] - positions[i][1]) * (mousecoords[1] - positions[i][1]));
        activation[i] = Math.max(1 / (1 + Math.exp((dist - 20) / 5)), 0);
      }

      for (let i = 0; i < numPoints; i++) {
        for (let j = i + 1; j < numPoints; j++) {
          updateItem(i,j)
        }
      }

    }

    // Use d3.timer to animate the circle
    const timer = d3.timer(updatePosition);

    // Clean up the timer on component unmount
    return () => timer.stop();
  }, []);

  return <svg className='svgsimmutron' ref={svgRef} width="100%" height="100%" viewBox="0 0 100 100" />;
};

export default MovingPoint;
