import { create } from 'zustand';

export interface NodeState {
  selected_id: string;
  selected_name: string;
  setNode: (id: string, name: string) => void;
}

const nodeStore = create<NodeState>((set) => ({
  selected_id: 'test',
  selected_name: '',
  setNode: (id: string, name: string) => set(() => ({ selected_id: id, selected_name: name })),
}));

export default nodeStore;
