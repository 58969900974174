import { Box, Chip, Stack } from '@mui/material';
import HeadCellType from '_components/base/table/HeadCellType';
import RowType, { RowElementType } from '_components/base/table/RowType';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useIsMounted } from '_hooks/useIsMounted';
import ContentTable from '_components/base/table/ContentTable';
import { isInternalIdNameObjArrayType } from '_components/base/table/InternalIdNameObjAPI';
import { withServiceCallHandling } from '_helpers/decorators';
import baseModelsServices from '_services/harbor/basemodels.service';

const defaultIdHeadCell: HeadCellType = {
    id: '_id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
};

const defaultHeadCells: HeadCellType[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name',
        renderElementImpl: (name: RowElementType) => {
            return <Box sx={{ pl: 1 }}>{name as String}</Box>;
        },
    },
    { id: 'version', numeric: false, disablePadding: false, label: 'Version' },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'Date',
        isDate: true,
    },
    { id: 'disease', numeric: false, disablePadding: false, label: 'Disease' },
    {
        id: 'projects',
        numeric: false,
        disablePadding: false,
        label: 'Projects in Use',
        renderElementImpl: (models: RowElementType) => {
            if (!isInternalIdNameObjArrayType(models)) return undefined;
            return (
                <Stack direction="column" spacing={0.1}>
                    {models.map((m) => (
                        <Chip key={m._id} label={m.name} size="small" />
                    ))}
                </Stack>
            );
        },
    },
];

export interface BaseModelsProps {
    asPage?: boolean;
    tableTitle?: string;
    entityName?: string;
    allowInsert?: boolean;
    allowDelete?: boolean;
    allowFilter?: boolean;
    allowRename?: boolean;
    allowSelect?: boolean;
    allowCreateProject?: boolean;
    allowMultipleSelect?: boolean;
    initRows?: RowType[];
    initRowsSelected?: RowType[];
    headCells?: HeadCellType[];
    idHeadCell?: HeadCellType;
    onRowsSelected?: (rows: RowType[]) => void;
}
function BaseModels({
    initRows = [],
    initRowsSelected = [],
    tableTitle = 'Base Models',
    entityName = 'Base Model',
    allowSelect = false,
    allowFilter = true,
    headCells = defaultHeadCells,
    idHeadCell = defaultIdHeadCell,
    onRowsSelected,
}: BaseModelsProps) {
    const isMounted = useIsMounted();
    const { getAccessTokenSilently } = useAuth0();

    const [baseModels, setBaseModels] = useState<RowType[]>(initRows);
    const [rowsSelected, setRowsSelected] = useState<RowType[]>(initRowsSelected);

    async function listBaseModelsImpl() {
        const accessToken = await getAccessTokenSilently();
        return baseModelsServices.listBaseModels(accessToken);
    }
    const listBaseModels = withServiceCallHandling(
        listBaseModelsImpl,
        (data) => setBaseModels(data),
        undefined,
        false,
        () => isMounted(),
    );

    useEffect(() => {
        if (!initRows) listBaseModels();
    }, []);

    function handleRowsSelected(selRows: RowType[]) {
        if (onRowsSelected) onRowsSelected(selRows);
        setRowsSelected(selRows);
    }

    const baseModelBody = (
        <ContentTable
            idHeadCell={idHeadCell}
            headCells={headCells}
            rows={baseModels}
            rowsSelected={rowsSelected}
            allowSelect={allowSelect}
            allowFilter={allowFilter}
            onRowsSelected={handleRowsSelected}
            InsertRecordComponent={false}
            DeleteRecordComponent={false}
            renameRecordFunction={undefined}
            tableTitle={tableTitle}
            entityName={entityName}
            allowMultipleSelect={false}
        />
    );
    return baseModelBody;
}

export default BaseModels;
