import { handleResponse } from '_helpers/middleware';

const URLS = {
    listModels: '/api/harbor/models',
    createModel: '/api/harbor/models',
    deleteModel: (modelId) => `/api/harbor/models/${modelId}`,
    deleteModels: '/api/harbor/models',
    renameModel: (modelId) => `/api/harbor/models/${modelId}/rename`,
};

function listModels(accessToken) {
    const requestOptions = {
        method: 'GET',
        headers: { Authorization: `Bearer ${accessToken} ` },
    };
    return fetch(URLS.listModels, requestOptions).then(handleResponse);
}

function createModel(accessToken, name, disease, type, base_model_id, upload_ids) {
    // name=str, disease=str, type=str, base_model_id=str, upload_ids=str
    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name,
            disease,
            type,
            base_model_id,
            upload_ids,
        }),
    };
    return fetch(URLS.createModel, requestOptions).then(handleResponse);
}

function deleteModel(accessToken, modelId) {
    const requestOptions = {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${accessToken}` },
    };
    return fetch(URLS.deleteModel(modelId), requestOptions).then(handleResponse);
}

function renameModel(accessToken, modelId, name, newName) {
    const requestOptions = {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, newName }),
    };
    return fetch(URLS.renameModel(modelId), requestOptions).then(handleResponse);
}

function deleteModels(accessToken, modelIds) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ model_ids: modelIds }),
    };
    return fetch(URLS.deleteModels, requestOptions).then(handleResponse);
}

const modelsService = {
    listModels,
    createModel,
    deleteModel,
    deleteModels,
    renameModel,
    URLS,
};

export default modelsService;
