import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import { InterruptedPath, ProteinInfo, Node, Link } from './TargetEfficacyInterface';
import { SCORES_DECIMAL } from './NodeProcessPathsPage';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
var testMode = true; // if true just opens the pdf instead of downloading it


// Function to wrap content with a border
function wrapWithBorder(content: any): any {
  return {
    table: {
      body: [
        [
          {
            // Content inside the bordered section
            stack: Array.isArray(content) ? content : [content],
            margin: [10, 10, 10, 10], // Adjust padding as needed
          },
        ],
      ],
    },
    margin: [0, 20, 0, 20], // Margin around the table (left, top, right, bottom)
    layout: {
      hLineWidth: () => 1, // Top and bottom border thickness
      vLineWidth: () => 1, // Left and right border thickness
      hLineColor: () => 'black', // Border color
      vLineColor: () => 'black', // Border color
    },
  };
};

function orderNodesAndRelations(path: InterruptedPath): [String[], String[]] {
  const orderedNodes: String[] = [path.target];
  const orderedRelations: String[] = [];
  const linksList: Link[] = path.path.links;
  const orderedNodeNames: String[] = []
  while (orderedNodes[orderedNodes.length - 1] !== path.endpoint) {
    for (const l of linksList) {
      if (l.source.id === orderedNodes[orderedNodes.length - 1]) {
        orderedNodes.push(l.target.id);
        if (orderedNodeNames.length === 0) {
          orderedNodeNames.push(l.source.name)
        }
        orderedNodeNames.push(l.target.name)
        orderedRelations.push(l.relation.toFixed(SCORES_DECIMAL));
      }
    }
  }
  return [orderedNodeNames, orderedRelations];
}

function InterruptedPaths(targetName: string, interruptedPaths: InterruptedPath[], endpointsNames: string[]): Content {
  var contents: Content[] = [
    {
      text: 'Interrupted Paths',
      style: 'sectionTitle',
      margin: [0, 10, 0, 0],
    },
  ];
  var currentKey = 0;
  interruptedPaths.forEach((p: InterruptedPath) => {
    const endpointName = endpointsNames[currentKey];
    const endpointId = p.endpoint;
    const nNodesInPath = p.path.nodes.length;
    const score = p.score;
    const nodesAndRelations = orderNodesAndRelations(p)
    const nodesInPath = nodesAndRelations[0].join()
    const relationsInPath = nodesAndRelations[1].join()

    contents.push(createPathSection(currentKey + 1, targetName, endpointName, endpointId, nNodesInPath, score, true, nodesInPath, relationsInPath));
    currentKey++;
  });

  return contents;
}


function NonInterruptedPaths(unreachedEndpoints: string[], targetName: string, proteinInfo: ProteinInfo): Content {
  var contents: Content[] = [
    {
      text: 'Non-Interrupted Paths',
      style: 'sectionTitle',
      margin: [0, 10, 0, 0],
    },
  ];
  var currentKey = 0;
  unreachedEndpoints.forEach((endpointId: string) => {
    currentKey++;
    const endpointName = proteinInfo[endpointId].preferredName;
    const nNodesInPath = -10;
    const score = 0;
    contents.push(createPathSection(currentKey, targetName, endpointName, endpointId, nNodesInPath, score, false));
  });

  return contents;
}

function createPathSection(
  pathKey: number,
  targetName: string,
  endpointName: string,
  endpointId: string,
  numberOfNodeInPath: number,
  score: number,
  showNumberOfNodeInPath: boolean = true,
  nodesInPath: string = "",
  scoresInPath: string = ""
): Content {
  var content: Content = [
    {
      text: `${targetName}->${endpointName} Path${pathKey} Information:\n`,
      margin: [4, 10, 4, 4],
      bold: true,
      style: 'nodeInformation',
    },
    {
      table: {
        headerRows: 0,
        body: [
          [{ text: 'Endpoint Name:', alignment: 'right' }, endpointName],
          [{ text: 'String ID:', alignment: 'right' }, endpointId],
          ...(showNumberOfNodeInPath ? [[{ text: 'Path Length:', alignment: 'right' }, numberOfNodeInPath]] : []),
          ...(showNumberOfNodeInPath ? [[{ text: 'Nodes in Path:', alignment: 'right' }, nodesInPath]] : []),
          ...(showNumberOfNodeInPath ? [[{ text: 'Edge Scores in Path', alignment: 'right' }, scoresInPath]] : []),
          [
            { text: 'Individual Score:', bold: true, alignment: 'right' },
            { text: score, bold: true },
          ],
        ],
      },
      layout: 'noBorders',
      style: 'nodeInformation',
    },
    stringLink(endpointName, endpointId),
  ];

  return content;
}

function stringLink(nodeName: string, nodeId: string): Content {
  var content =
    [{
      text: `Find more information about ${nodeName} on the String database`,
      link: `https://string-db.org/network/${nodeId}`,
      color: 'blue',
      fontSize: 10
    }]
  return content;
}

function Separator(x_pos: number = -230): Content {
  return {
    canvas: [
      {
        type: 'rect',
        x: x_pos,
        y: 1,
        w: 220,
        h: 10,
        r: 7,
        color: '#FBD46C',
      },
    ],
  };
}

function SummarySection(
  projectName: string | undefined,
  AiPredictionThreshold: number | undefined,
  targetName: string,
  targetId: string,
  diseaseName: string,
  modelName: string | undefined,
  modelVersion: string = 'version 1',
): Content {
  return wrapWithBorder(
    [
      {
        text: 'Project: ' + projectName
      },
      {
        text: 'AI Prediction Threshold: ' + AiPredictionThreshold
      },
      {
        text: 'Target Name: ' + targetName
      },
      {
        text: 'Target String ID: ' + targetId
      },
      {
        text: 'Disease: ' + diseaseName
      },
      {
        text: 'Model Name: ' + modelName
      },
      {
        text: 'Model Version: ' + modelVersion
      },
    ]
  )
}

function interpretationSection(targetName: string, diseaseName: string): Content {
  return wrapWithBorder(
    [{
      text: [
        {
          text: `This is an automated report on the ${targetName} target made by Simmunome's target validation AI.\n\n`,
          style: { fontSize: 9 },
        },
        {
          text: `How to interpret the scores:`,
        },
      ],
      style: {
        fontSize: 11,
      },
      margin: [0, 5, 0, 0],
    },
    {
      // to treat a paragraph as a bulleted list, set an array of items under the ul key
      ul: [
        'The scores for individual endpoints are numbers ranging from 0 to 1.',
        `The target score gives an idea of the probability of success of the Target for the ${diseaseName} disease.`,
        `Any individual score below 1 is an idea of the probability of interaction predicted by Simmunome's AI model.`,
        `An individual score of 1 means that the interaction the target has on an endpoint is already experimentally confirmed.`,
      ],
      style: { fontSize: 10 },
    }]
  )
}

function createPdfReport(
  projectName: string | undefined,
  AiPredictionThreshold: number | undefined,
  targetName: string,
  targetId: string,
  diseaseName: string,
  interruptedPaths: InterruptedPath[],
  interruptedEndpointsNames: string[],
  score: string,
  protein_info: ProteinInfo,
  unreachedEndpoints: string[],
  modelName: string | undefined,
  modelVersion: string = 'version 1',
) {
  const docDefinition: TDocumentDefinitions = {
    content: [

      Separator(440),
      {
        text: 'Simmunome Report for Target ' + targetName,
        style: 'header',
      },
      {
        canvas: [
          {
            type: 'rect',
            x: -100,
            y: 1,
            w: 620,
            h: 10,
            r: 7,
            color: '#113046',
          },
        ],
      },

      SummarySection(
        projectName,
        AiPredictionThreshold,
        targetName,
        targetId,
        diseaseName,
        modelName,
        modelVersion,
      ),

      interpretationSection(targetName, diseaseName),

      Separator(),
      { text: 'Target', style: 'sectionTitle' },
      {
        text: 'Target Information:\n',
        margin: [4, 10, 4, 4],
        bold: true,
        style: 'nodeInformation',
      },
      {
        table: {
          headerRows: 0,
          body: [
            [{ text: 'Name:', alignment: 'right' }, targetName],
            [{ text: 'String ID:', alignment: 'right' }, targetId],
            [
              { text: 'Target Score:', bold: true, alignment: 'right' },
              { text: score, bold: true },
            ],
          ],
        },
        layout: 'noBorders',
        style: 'nodeInformation',
      },
      stringLink(targetName, targetId),

      Separator(),
      InterruptedPaths(targetName, interruptedPaths, interruptedEndpointsNames),
      Separator(),
      NonInterruptedPaths(unreachedEndpoints, targetName, protein_info),
      Separator(),

    ],

    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 5],
      },
      nodeInformation: { margin: 4, fontSize: 10 },
      sectionTitle: {
        fontSize: 14,
        bold: true,
      },
    },
  };

  if (testMode) {
    pdfMake.createPdf(docDefinition).open();
  } else {
    pdfMake.createPdf(docDefinition).download('SimmunomeTargetReport_' + targetName + '.pdf');
  }
}

export default createPdfReport;
