import { FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent } from "@mui/material";
import { ChangeEvent } from "react";

interface FileFormControlProps {
    label: string;
    stateVariable: string;
    choices: string[];
    asSelect?: boolean;
    setVariableStateFunction: (value: string) => void
}
export default function FileFormControl({
    label, stateVariable, choices, setVariableStateFunction, asSelect = false
}: FileFormControlProps) {
    function handleRadioChange(event: ChangeEvent<HTMLInputElement>) {
        setVariableStateFunction(event.target.value);
    };
    function handleSelectChange(event: SelectChangeEvent<string>) {
        setVariableStateFunction(event.target.value);
    };
    return (
        <FormControl variant="standard">
            {asSelect ? (
                <>
                    <InputLabel id="controlled-select-buttons-group">{label}</InputLabel>
                    <Select
                        labelId="controlled-select-buttons-group"
                        id="demo-simple-select"
                        value={stateVariable}
                        label={label}
                        onChange={handleSelectChange}
                    >
                        {choices.map((choice) => (
                            <MenuItem key={choice} value={choice} >{choice}</MenuItem>
                        ))}
                    </Select>
                </>
            ) : (
                <>
                    <FormLabel id="demo-controlled-radio-buttons-group">{label}</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={stateVariable}
                        onChange={handleRadioChange}
                    >
                        {choices.map((choice) => (
                            <FormControlLabel key={choice} value={choice} control={<Radio />} label={choice} />
                        ))}
                    </RadioGroup>
                </>
            )}
        </FormControl>
    );
}