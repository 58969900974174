import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack, Tooltip } from '@mui/material';

interface InfoTooltipSurrounderProps {
  description: string;
  children: React.ReactNode;
}

const InfoTooltipSurrounder: React.FC<InfoTooltipSurrounderProps> = ({ description, children }) => {
  return (
    <Stack direction="row" sx={{ m: 'auto' }}>
      {children}
      <Tooltip title={description} sx={{ mt: 'auto', mb: 'auto', ml: '5px' }}>
        <InfoOutlinedIcon />
      </Tooltip>
    </Stack>
  );
};

export default InfoTooltipSurrounder;
