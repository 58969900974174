import { useEffect, useState } from 'react';
import Scrollspy from 'react-scrollspy';
import { List, ListItem, ListItemButton, ListItemText, ListSubheader } from '@mui/material';

export interface HeadingType {
  hash: string;
  label: string;
}
export interface TableOfContentsProps {
  headings: HeadingType[];
  title?: string;
}

export default function TableOfContents({ headings, title = "Content" }: TableOfContentsProps) {
  const [activeHeading, setActiveHeading] = useState<string | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      // Calculate which heading is in the viewport
      // The idea - find the first most visible element in viewport
      const visibleRatios = headings.map(({ label, hash }: HeadingType) => {
        const element = document.getElementById(hash);
        if (!element) return -1;
        const rect = element.getBoundingClientRect();
        const { height, top, bottom } = rect;
        if (bottom < 0 || top > window.innerHeight) return -1;
        return (Math.min(bottom, window.innerHeight) - Math.max(top, 0)) / Math.min(window.innerHeight, height);
      })
      const index = visibleRatios.indexOf(Math.max(...visibleRatios));
      if (index > -1 && visibleRatios[index] > -1) {
        setActiveHeading(headings[index]?.hash || null);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);
    setActiveHeading(headings[0]?.hash)
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [headings]);
  const styleBgColor = 'rgba(99,132,246,0.3)'
  return (
    <List component="nav"
      sx={{ width: '100%', maxWidth: 360, bgcolor: styleBgColor }}
      subheader={<ListSubheader sx={{ bgcolor: styleBgColor }}>{title}</ListSubheader>}
    >
      <Scrollspy
        items={headings.map(({ hash }) => hash)}
        className="scrollspy"
        currentClassName="isCurrent"
        offset={200} // Adjust the offset based on your layout
        style={{ paddingLeft: '5px', paddingRight: '5px' }}
      >
        {
          headings.map(({ hash, label }, indx) => (
            <ListItemButton sx={{ p: 0 }}
              key={hash}
              style={{
                backgroundColor: activeHeading === hash ? styleBgColor /*'#e0e0e0'*/ : 'transparent',
              }}
              component="a"
              href={`#${hash}`}
            >
              <ListItemText sx={{ my: 0, fontSize: 10 }} primary={`${indx + 1}. ${label}`} />
            </ListItemButton>
          ))
        }
      </Scrollspy >
    </List >
  );
};

