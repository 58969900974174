import InternalFileObjType from "_components/base/table/InternalFileObj";

export function isInternalFileObjType(obj: any): obj is InternalFileObjType {
    return typeof obj === "object" &&
        "_id" in obj &&
        // "src" in obj &&
        "type" in obj;
}

export function isInternalFileObjArrayType(elm: any): elm is InternalFileObjType[] {
    return Array.isArray(elm) && elm.every(obj => isInternalFileObjType(obj))
}
