import { handleResponse } from '_helpers/middleware';
import { generatePersonalSSEToken } from '_helpers/sse';
import { APIOptionsType } from '../_types/APIOptionsType';

const URLS = {
  startMotifMiningPipeline: (projectId: string): string => `/api/biomarkers/project/${projectId}/motif_mining/start`,
  getMMPipelineResult: (projectId: string): string => `/api/biomarkers/project/${projectId}/motif_mining/result`,

  // TODO TOFIX react proxy causes Event listener to misbehave
  sseRunBMPipeline: `${!process.env.REACT_APP_API_SERVER_URL || process.env.REACT_APP_API_SERVER_URL.includes('localhost')
    ? 'http://127.0.0.1:5000'
    : ''
    }/api/biomarkers/sse/stream`,
};

/**
 * Start motif mining pipeline from a BM project.
 * Used randomized personal SSE token
 * @param {*} accessToken 
 * @param {*} projectId 
 * @returns Promise
 */
async function startMotifMiningPipeline(accessToken: string, projectId: string, options?: APIOptionsType): Promise<any> {
  // Gen a token to create "hidden" personal SSE channel
  const personalSSEToken = generatePersonalSSEToken();
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ personalSSEToken }),
    signal: options?.signal
  };
  const response = await fetch(URLS.startMotifMiningPipeline(projectId), requestOptions);
  return handleResponse(response);
}

/**
 * Get Result of Motif Mining pipeline (matches-based results)
 * 
 * body: data:
 *      _id: string - project_id
 *      meta: {
 *          auc: float,
 *          accuracy: float,
 *          f1: float
 *      },
 *      tabs: dict {
 *          table_view: dict {
 *              columns: list - column names of the table view
 *              data: list<list> - data
 *          },
 *          dendrogram: plotly figure,
 *          roc: plotly figure,
 *      }
 * @param {*} accessToken 
 * @param {*} projectId 
 * @returns Promise
 */
async function getMMPipelineResult(accessToken: string, projectId: string, options?: APIOptionsType): Promise<any> {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    signal: options?.signal
  };
  const response = await fetch(URLS.getMMPipelineResult(projectId), requestOptions);
  return handleResponse(response);
}


const biomarkerService = {
  startMotifMiningPipeline,
  getMMPipelineResult,

  URLS,
};

export default biomarkerService;
