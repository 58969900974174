import { useAuth0 } from '@auth0/auth0-react';
import organizationService from '_services/harbor/organization.service';
import { useState, useEffect } from 'react';
import { withServiceCallHandling } from '_helpers/decorators';
import Organization from '_interfaces/OrganizationInterface';
import { Box, Button, LinearProgress, Stack, Tooltip } from '@mui/material';
import NoAccess from '_components/NoAccess';

const BUTTON_MESSAGE = 'New Project';
interface FeatureHomePageContentProps {
  buttonAction: Function;
  featureName: string;
  children: React.ReactNode;
}

export default function FeatureHomePageContent({ buttonAction, featureName, children }: FeatureHomePageContentProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAccessible, setIsAccessible] = useState<boolean>(true);
  const { getAccessTokenSilently } = useAuth0();

  async function getDataImpl() {
    const accessToken = await getAccessTokenSilently();
    return organizationService.getUserOrganization(accessToken);
  }

  const fetchData = withServiceCallHandling(
    getDataImpl,
    (data: any) => {
      const org: Organization = data[0];
      setIsAccessible(org.access_features.includes(featureName));
      setIsLoading(false);
    },
    undefined,
    false,
    undefined,
  );

  useEffect(() => {
    fetchData();
  }, []);

  function buttonTooltipTitle() {
    if (isAccessible) {
      return `Start a ${featureName} project`;
    } else {
      return `You don't have access to ${featureName}`;
    }
  }

  return (
    <Stack spacing={3}>
      {isLoading && <LinearProgress />}
      {!isLoading && (
        <Tooltip title={buttonTooltipTitle()}>
          <Box textAlign="center">
            <Button
              variant="contained"
              sx={{ width: '60%', marginLeft: '20%', marginRight: '20%' }}
              onClick={() => buttonAction()}
              disabled={!isAccessible}
            >
              {BUTTON_MESSAGE}
            </Button>
          </Box>
        </Tooltip>
      )}
      {isAccessible && children}
      {!isAccessible && <NoAccess resourceName={featureName}></NoAccess>}
    </Stack>
  );
}
