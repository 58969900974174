import { handleResponse } from '_helpers/middleware';
import { APIOptionsType } from '_types/APIOptionsType';

const URLS = {
    listTasks: '/api/harbor/tasks',
};


async function listActiveTasks(accessToken: string, options?: APIOptionsType): Promise<any> {
    const url = new URL(URLS.listTasks, window.location.href);
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { Authorization: `Bearer ${accessToken}` },
        signal: options?.signal,
    };
    const response = await fetch(url.toString(), requestOptions)
    return handleResponse(response);
}


const taskServices = {
    listActiveTasks,
    URLS,
};

export default taskServices;
