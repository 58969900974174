import { Box, Button, useTheme } from "@mui/material";

interface ButtonGroupProps {
    isNextVisible?: boolean;
    isBackVisible?: boolean;
    isCreateVisible?: boolean;
    isNextDisabled?: boolean;
    isBackDisabled?: boolean;
    isCreateDisabled?: boolean;
    nextLabel?: string;
    backLabel?: string;
    createLabel?: string;
    onNext?: () => void;
    onBack?: () => void;
    onCreate?: () => void;
}
export default function ButtonGroup({
    isNextVisible = true,
    isBackVisible = true,
    isCreateVisible = true,
    isNextDisabled = false,
    isBackDisabled = false,
    isCreateDisabled = false,
    nextLabel = 'Next',
    backLabel = 'Back',
    createLabel = 'Create',
    onNext,
    onBack,
    onCreate,

}: ButtonGroupProps) {
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pt: 2 }}>
            <Box>
                {isBackVisible && (
                    <Button
                        disabled={isBackDisabled}
                        onClick={onBack}
                        variant="outlined"
                        sx={{ mr: 1, /*backgroundColor: theme.palette.primary.main*/ }}
                    >
                        {backLabel}
                    </Button>
                )}
                {isNextVisible && (
                    <Button
                        disabled={isNextDisabled}
                        onClick={onNext}
                        variant="outlined"
                        sx={{ mr: 1, /*backgroundColor: theme.palette.primary.main*/ }}
                    >
                        {nextLabel}
                    </Button>
                )}
            </Box>
            <Box>
                {isCreateVisible && (
                    <Button
                        disabled={isCreateDisabled}
                        onClick={onCreate} variant="contained"
                        sx={{ mr: 1, backgroundColor: theme.palette.primary.main }}
                    >
                        {createLabel}
                    </Button>
                )}
            </Box>
        </Box>
    );
}