import { useAuth0 } from '@auth0/auth0-react';
import { Stack, Typography, Tabs, Tab, IconButton, Link, Box, Paper, Button, Container, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useIsMounted } from '_hooks/useIsMounted';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import BMProjectType from '../BMProjectType';
import projectsService from '_services/harbor/projects.service';
import { withServiceCallHandling } from '_helpers/decorators';
import moment from 'moment';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import HeadCellType from '_components/base/table/HeadCellType';
import EnhancedTable from '_components/base/table/EnhancedTable';
import RowType from '_components/base/table/RowType';
import EnhancedTableToolbar from '_components/base/table/EnhancedTableToolbar';
import { isInternalFileObjArrayType } from '_components/base/table/InternalFileObjAPI';
import InternalFileObjType from '_components/base/table/InternalFileObj';
import BasicModal from '_components/base/BasicModal';
import FileUploads from '_pages/FileUploads/FileUploads';
import ButtonGroup from '_components/base/ButtonGroup';
import Notepad from '_components/notepad';
import biomarkerService from '_services/biomarker.service';
import MotifMiningResultType from '_types/MotifMiningResultType';
import LocalPlot from '_components/plots/LocalPlot';
import { useAbortOnUnload } from '_hooks/useAbortOnUnload';
import Loading from '_components/Loading';

interface FileUploadModalContentProps {
  onSubmit: (fileRows: RowType[]) => void;
  onCancel: () => void;
}

/**
 * Split column with underscore and capitalize every word
 * @param col - The column name to process
 * @returns - The processed column name with each word capitalized
 */
function processColumnName(col: string): string {
  return col
    .split('_') // Split the string by underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(' '); // Join the words back with spaces
}

function FileUploadModalContent({ onSubmit, onCancel }: FileUploadModalContentProps) {
  const [selectedFiles, setSelectedFiles] = useState<RowType[]>([]);
  return (
    <>
      <FileUploads
        asPage={false}
        tableTitle="Add new or select existing dataset"
        allowDelete={false}
        allowMultipleSelect={false}
        allowRename={false}
        // Add for external file upload load
        // initRows={}
        // initRowsSelected={}
        onRowsSelected={setSelectedFiles}
      />
      <ButtonGroup
        isNextVisible={true}
        isBackVisible={true}
        isCreateVisible={false}
        nextLabel="Next"
        backLabel="Cancel"
        isNextDisabled={selectedFiles.length !== 1}
        onNext={() => onSubmit(selectedFiles)}
        onBack={onCancel}
      />
    </>
  );
}
interface RowFilter {
  columnId: string;
  value: string;
}

const defaultBMHeadCells: HeadCellType[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Identifier',
  },
  {
    id: 'bm_pattern',
    numeric: false,
    disablePadding: false,
    label: 'Biomarker Pattern',
  },
  {
    id: 'count_disease',
    numeric: false,
    disablePadding: false,
    label: 'Disease Count',
  },
  {
    id: 'count_recovery',
    numeric: false,
    disablePadding: false,
    label: 'Recovery Count',
  },
  {
    id: 'frequency',
    numeric: false,
    disablePadding: false,
    label: 'Frequency',
  },
  {
    id: 'abs_diff',
    numeric: false,
    disablePadding: false,
    label: 'Difference',
  },
  {
    id: 'group',
    numeric: false,
    disablePadding: false,
    label: 'Group Bias',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'More information',
    renderElementImpl: (rowElements, row) => {
      return (
        <Link href="#" underline="always">
          See more
        </Link>
      );
    },
    rowCellSx: { px: 2 },
  },
];

const idHeadCell: HeadCellType = {
  id: 'pattern',
  numeric: false,
  disablePadding: true,
  label: 'Pattern',
};

const styles = {
  uploadModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  },
};

export default function BMProject() {
  const isMounted = useIsMounted();
  const { getAccessTokenSilently } = useAuth0();
  const [projectData, setProjectData] = useState<BMProjectType>();
  const [motifMiningResult, setMotifMiningResult] = useState<MotifMiningResultType>();
  const { projectId } = useParams<{ projectId: string }>();
  const [activeTab, setActiveTab] = useState(0);
  const [classifierActiveTab, setClassifierActiveTab] = useState(0);
  const [rowFilters, setRowFilters] = useState<RowFilter[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<RowType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  // control abort
  const abortControllerRef = useAbortOnUnload();

  async function getBMProjectImpl(signal?: AbortSignal) {
    const accessToken = await getAccessTokenSilently();
    return projectsService.getBMProject(accessToken, projectId, { signal });
  }

  async function getPipelineResultImpl(signal?: AbortSignal) {
    const accessToken = await getAccessTokenSilently();
    return biomarkerService.getMMPipelineResult(accessToken, projectId, { signal });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 5000); // 5 seconds delay

    return () => clearTimeout(timer); // Cleanup timer
  }, []);

  const getBMProjectInfo = withServiceCallHandling(
    getBMProjectImpl,
    (data) => {
      setProjectData(data);
    },
    undefined,
    false,
    () => isMounted(),
  );

  const getPipelineResult = withServiceCallHandling(
    getPipelineResultImpl,
    (data) => {
      setMotifMiningResult(data);
    },
    undefined,
    false,
    () => isMounted(),
  );

  useEffect(() => {
    abortControllerRef.current = new AbortController();
    getBMProjectInfo(abortControllerRef.current.signal);
  }, []);

  useEffect(() => {
    if (!projectData?.is_complete) return;
    abortControllerRef.current = new AbortController();
    getPipelineResult(abortControllerRef.current.signal);
  }, [projectData?.is_complete]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getBMProjectInfo();
    }, 2000);

    if (projectData?.task_id && projectData?.is_complete) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [projectData]);

  if (!projectData) return <></>;
  if (projectData.task_id && !projectData.is_complete) {
    return <p>Pipeline is not complete yet. Page will refresh when it is ready. You can close this page.</p>;
  }
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleClassifierTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setClassifierActiveTab(newValue);
  };

  let rows: RowType[];
  let bmHeadCells: HeadCellType[];
  if (!motifMiningResult) {
    bmHeadCells = defaultBMHeadCells;
    rows = [];
  } else {
    bmHeadCells = motifMiningResult.tabs.table_view.columns.map(
      (col: string): HeadCellType => ({
        id: col,
        numeric: false,
        disablePadding: false,
        label: processColumnName(col),
      }),
    );
    rows = motifMiningResult.tabs.table_view.data.map((dat: string[]): RowType => {
      const cols: string[] = motifMiningResult.tabs.table_view.columns;
      return cols.reduce((acc, col, i) => {
        acc[col] = dat[i];
        return acc;
      }, {} as RowType);
    });
  }

  const rowsImpl = rows.filter((row) => {
    return rowFilters.every(({ columnId, value }) => {
      const elm = row[columnId];
      if (elm && isInternalFileObjArrayType(elm)) {
        return (elm as InternalFileObjType[]).some((obj) =>
          obj._id?.toString()?.toLowerCase().includes(value.toLowerCase()),
        );
      }
      return elm?.toString()?.toLowerCase().includes(value.toLowerCase());
    });
  });

  const filterColumnNames = bmHeadCells
    .filter((c) => !c.isEmpty && !c.isSelfClickable)
    .map((c) => ({ label: c.label, id: c.id }));

  function handleFilterData(columnId: string, filterValue: string) {
    setRowFilters([{ columnId: columnId, value: filterValue }]);
  }

  return (
    <Container>
      <Stack spacing={4} sx={{ pb: 2 }} direction="row" alignItems="baseline" justifyContent="flex-end">
        <Typography variant="h5">{projectData.name}</Typography>
        <Typography variant="subtitle2">Created by: {projectData.user.email}</Typography>
        <Typography variant="subtitle2">Date: {moment(projectData.created_at).format('LL')}</Typography>
      </Stack>
      <Notepad projectId={projectData._id} projectNotes={projectData.notes ?? []} />
      <Typography variant="h6">Analysis</Typography>
      {motifMiningResult?.meta.accuracy && (
        <Paper sx={{ p: 2, mb: 3 }}>
          <Typography variant="subtitle2" fontWeight="bold">
            Accuracy
          </Typography>
          <Stack direction="row" spacing={3} alignItems="center">
            <Typography variant="h3" color="green" display="inline" fontWeight="bold">
              {(motifMiningResult.meta.accuracy * 100).toFixed(0)}%
            </Typography>
            <Typography variant="body1" display="inline">
              Test data labels correctly predicted by model
            </Typography>
          </Stack>
        </Paper>
      )}
      <Tabs value={activeTab} onChange={handleTabChange} aria-label="analysis tabs">
        <Tab label="Table View" />
        <Tab label="Clustering" />
        <Tab label="Classifier Performance" />
      </Tabs>
      {activeTab === 0 && (
        <Box>
          <EnhancedTableToolbar
            title={
              <Stack direction="row" alignItems="center" sx={{ pt: 3, flex: '1 1 100%' }}>
                <Typography variant="h4" display="inline">
                  Biomarkers
                </Typography>
                <IconButton>
                  <ListAltIcon />
                </IconButton>
              </Stack>
            }
            allowSelect={false}
            selected={[]}
            filterDefaultColumn={filterColumnNames[0]}
            filterColumnNames={filterColumnNames}
            onFilter={handleFilterData}
          />
          <EnhancedTable
            headCells={bmHeadCells}
            allowSelect={false}
            rows={rowsImpl}
            idHeadCell={idHeadCell}
            selected={[]}
            setSelected={() => { }}
          />
        </Box>
      )}
      {activeTab === 1 && (
        <Box>
          <Box sx={{ py: 5 }}>
            <Typography variant="h6">Predictions Cluster Plots</Typography>
            {motifMiningResult ? (
              <LocalPlot
                data={motifMiningResult.tabs.dendrogram.data}
                layout={motifMiningResult.tabs.dendrogram.layout}
              />
            ) : (
              <Typography variant="body2">Cluster plots are not available</Typography>
            )}

            {false && selectedFiles.length < 1 ? (
              <Box sx={{ textAlign: 'center', mt: 3 }}>
                <MoodBadIcon sx={{ fontSize: 100, mt: 2 }} />
                <Typography variant="body2" fontWeight="bold" sx={{ mt: 2 }}>
                  No prediction yet!
                </Typography>
                <Typography variant="body2" fontWeight="bold" sx={{ mt: 2 }}>
                  Upload data for new patient in order to generate predictions
                </Typography>
                <Button variant="contained" sx={{ my: 2 }} onClick={() => setModalOpen(true)}>
                  Upload data for new patient
                </Button>

                <BasicModal
                  open={modalOpen}
                  onClose={() => setModalOpen(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  boxSx={styles.uploadModal}
                >
                  <FileUploadModalContent
                    onSubmit={(fileRows) => {
                      setSelectedFiles(fileRows);
                      setModalOpen(false);
                    }}
                    onCancel={() => setModalOpen(false)}
                  />
                </BasicModal>
              </Box>
            ) : (
              <>
                {
                  //{<PatientClusterPlotPage />
                }
              </>
            )}
          </Box>
        </Box>
      )}
      {activeTab === 2 && (
        <Box>
          <Tabs
            value={classifierActiveTab}
            onChange={handleClassifierTabChange}
            aria-label="Classifier Performance sub-tabs"
          >
            <Tab label="ROC" />
            <Tab label="Metrics" />
          </Tabs>
          {classifierActiveTab === 0 && (
            <Stack spacing={2} sx={{ marginTop: 1 }}>
              <Typography>Model ROC Curve</Typography>
              {motifMiningResult ? (
                <LocalPlot data={motifMiningResult.tabs.roc.data} layout={motifMiningResult.tabs.roc.layout} />
              ) : (
                <Typography variant="body2">ROC curve is not available</Typography>
              )}
            </Stack>
          )}
          {classifierActiveTab === 1 && motifMiningResult && (
            <TableContainer sx={{ margin: 'auto' }} component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Metrics</TableCell>
                    <TableCell align="left">Result</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key={'acc'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {"Accuracy"}
                    </TableCell>
                    <TableCell align="left">{motifMiningResult.meta.accuracy}</TableCell>
                  </TableRow>
                  <TableRow
                    key={'F1'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {"F1 Score"}
                    </TableCell>
                    <TableCell align="left">{motifMiningResult.meta.f1}</TableCell>
                  </TableRow>
                  <TableRow
                    key={'acc'}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {"AUC"}
                    </TableCell>
                    <TableCell align="left">{motifMiningResult.meta.auc}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>)}
          {classifierActiveTab === 2 && <Typography variant="caption">Content is pending</Typography>}
        </Box>
      )}
    </Container>
  );
}
