import { Box, Grid, Input, Slider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import AiPredStore from '_pages/TargetValidation/FilterAIPredictionStore';
import { resetAllEdgesColor, leaveAllNodes, toggleAIPredictedEdges } from '_helpers/sigmaEvents';
import { useSigma } from '@react-sigma/core';

export default function AiSlider() {
  const { filterCutoff } = AiPredStore();
  const [activity, setActivity] = useState<number | number[]>(filterCutoff);
  const sigma = useSigma();
  const setAiPredCutoffStore = AiPredStore((state) => state.setCutoff);

  function onChangeSlider(value: number | number[]) {
    setActivity(value);
    setAiPredCutoffStore(Number(value));
  }

  useEffect(() => {
    resetAllEdgesColor(sigma);
    leaveAllNodes(sigma);
    toggleAIPredictedEdges(sigma, AiPredStore.getState().filterBooleanValue, activity);
  }, [activity]);

  return (
    <Box>
      <Typography align="center">Predictions level</Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Slider
            onChange={(_, value) => onChangeSlider(value)}
            value={activity}
            step={0.01}
            valueLabelDisplay="auto"
            min={0.5}
            max={1}
            track={'inverted'}
          />
        </Grid>
        <Grid item>
          <Input
            value={activity}
            size="small"
            sx={{ width: 50 }}
            onChange={(e) => onChangeSlider(Number(e.target.value))}
            inputProps={{
              step: 0.01,
              min: 0.5,
              max: 1,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          ></Input>
        </Grid>
      </Grid>
    </Box>
  );
}
