import SSEStore from '_helpers/SSEStore';
import { ReactNode, useEffect, useRef } from 'react';
import { toast, Id, ToastOptions, TypeOptions } from 'react-toastify';

interface SSEListenerType {
  channelId: string;
  url: string;
  closeable: boolean;
  failCallback?: () => void;
  successCallback?: () => void;
  successMessage?: ReactNode | (() => ReactNode) | undefined;
}

export default function SSEListener({
  channelId,
  url,
  closeable,
  failCallback,
  successCallback,
  successMessage,
}: SSEListenerType) {
  const delete_channel = SSEStore((state) => state.delete_channel);
  const toastRef = useRef<Id>();

  function closeToastIfExists() {
    if (toastRef.current) toast.dismiss(toastRef.current);
    toastRef.current = undefined;
  }
  useEffect(() => {
    console.log(`supposed to connect to  ${url}`);
    if (closeable) {
      toastRef.current = toast('Initiating the job...', {
        autoClose: false,
        onClose: closeToastIfExists,
        toastId: channelId,
      });
    } else {
      toastRef.current = toast.loading('Initiating the job...', { toastId: channelId, autoClose: false });
    }
    const sse = new EventSource(url);
    sse.addEventListener(
      channelId,
      (e) => {
        const data = JSON.parse(e.data);
        console.log(data);
        const status = data?.status || 'loading';
        let render = 'Loading...';
        let _type: TypeOptions = 'info';
        let isLoading = true;
        let progress = null;
        if (data.message) render = data.message;
        if (data.progress !== undefined) progress = data.progress / 100;
        if (data.call_fail_function && failCallback) failCallback();

        switch (status) {
          case 'failed': {
            closeToastIfExists();
            toast.error(render, { autoClose: false, progress: undefined });
            return;
          }
          case 'finish': {
            const localRender = successMessage || data.message;
            if (toastRef.current) {
              toast.update(toastRef.current, {
                render: localRender,
                type: 'success',
                autoClose: closeable ? false : 2000,
                progress: null,
                isLoading: false,
                toastId: channelId,
              });
            } else {
              toastRef.current = toast.success(localRender, {
                autoClose: closeable ? false : 2000,
                toastId: channelId,
              });
            }
            if (successCallback) {
              successCallback();
            }
            break;
          }
          case 'terminated': {
            closeToastIfExists();
            toast.error(render, { autoClose: false, progress: undefined });
            break;
          }
          default: {
            if (toastRef.current) {
              toast.update(toastRef.current, {
                render,
                type: _type,
                autoClose: false,
                isLoading,
                progress,
                toastId: channelId,
              });
            } else {
              const toastOptions: ToastOptions = { autoClose: false, isLoading, type: _type, toastId: channelId };
              if (progress) toastOptions['progress'] = progress;
              toastRef.current = toast(render, toastOptions);
            }
            // quit instead of continue to dropping the channel
            return;
          }
        }
        sse.close();
        delete_channel(channelId);
        console.log('DELETING CHANNEL!!!!');
        return;
      },
      false,
    );
    sse.addEventListener(
      'error',
      function (event) {
        console.log(event);
        closeToastIfExists();
        toast.error('Job has failed', { autoClose: false, progress: undefined });
        sse.close();
        delete_channel(channelId);
      },
      false,
    );

    return () => {
      sse.close();
      delete_channel(channelId);
    };
  }, [channelId]);
  return <></>;
}
