import { useAuth0 } from '@auth0/auth0-react';
import { useIsMounted } from '_hooks/useIsMounted';
import projectsService from '_services/harbor/projects.service';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SaveProjectModal from '_pages/Projects/Routines/SaveProjectModal';
import { Box, Chip, Container, Stack, Typography } from '@mui/material';
import { withServiceCallHandling } from '_helpers/decorators';
import GraphDiseasePage from './GraphDisease/GraphDiseasePage';
import TVProjectType from './TVProjectType';
import NodeProcessPathsPage from './NodeProcessComponents/NodeProcessPathsPage';
import TVProjectStore from './TVProjectStore';
import { toast } from 'react-toastify';
import history from '_helpers/history';
import config from '_configs';
import AiPredStore from './FilterAIPredictionStore';
import disease_name_readable from '_helpers/format';

export default function TVProject() {
  const isMounted = useIsMounted();
  const { getAccessTokenSilently } = useAuth0();
  const [projectData, setProjectData] = useState<TVProjectType>();
  const { projectId } = useParams<{ projectId: string }>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [nodeProcessProps, setNodeProcessProps] = useState<{
    nodeId: string;
    filterToggle: boolean;
    filterValue: number;
  }>();
  const resetTVProjectStore = TVProjectStore((state) => state.reset);
  const resetAiPredStore = AiPredStore((state) => state.reset);

  useEffect(() => {
    const tvProjectModel = TVProjectStore.getState().model;
    // simulate existing project to initiate
    setProjectData({
      name: '',
      model_id: tvProjectModel._id as string,
      disease: tvProjectModel.disease as string,
      model: {
        _id: tvProjectModel._id as string,
        name: tvProjectModel.name,
        disease: tvProjectModel.disease as string
      },
    });
  }, []);

  async function saveProjectImpl(name: string) {
    if (!projectData) {
      toast.error('Project data is missing');
      return;
    }
    const accessToken = await getAccessTokenSilently();
    return projectsService.createTVProject(accessToken, name, {
      model_id: projectData.model_id,
      disease: projectData.disease,
      nodeId: nodeProcessProps?.nodeId,
      filterToggle: nodeProcessProps?.filterToggle,
      filterValue: nodeProcessProps?.filterValue,
    });
  }

  const handleSaveProject = withServiceCallHandling(saveProjectImpl, (data) => {
    setProjectData({ ...projectData, ...data });
    setOpenModal(false);
    resetAiPredStore();
    history.push(config.clientUrls.NODE_PROCESS_PATHS__TV_PROJECT(data._id));
  });

  async function getTVProjectImpl() {
    const accessToken = await getAccessTokenSilently();
    return projectsService.getTVProject(accessToken, projectId);
  }

  const getTVProjectInfo = withServiceCallHandling(
    getTVProjectImpl,
    (data) => setProjectData(data),
    undefined,
    false,
    () => isMounted(),
  );

  if (!projectData) return <></>;

  async function handleGoToNodeProcessPage(nodeId: string, aiPredIsOn: boolean, aiPredCutoff: number) {
    setOpenModal(true);
    setNodeProcessProps({ nodeId, filterToggle: aiPredIsOn, filterValue: aiPredCutoff });
  }

  return (
    <>
      <SaveProjectModal
        description="To save results of this analysis, name current project."
        open={openModal}
        onCancel={() => {
          setOpenModal(false);
        }}
        onSubmit={handleSaveProject}
      />
      <Container disableGutters sx={{ maxWidth: '100%', margin: 'auto' }}>
        <Stack sx={{ marginTop: '10px' }} direction="row" spacing={2} justifyContent="flex-end">
          {projectData._id && (
            <Typography variant="body1" color="primary">
              Project: {projectData.name}
            </Typography>
          )}


          <Box flexDirection="row" justifyContent="start">
            <Typography variant="body1" color="primary" fontStyle="italic" align="left" display="inline">
              Disease: </Typography>
            <Chip label={disease_name_readable(projectData.model.disease)} size="medium" />
          </Box>


          <Box flexDirection="row" justifyContent="start">
            <Typography variant="body1" color="primary" fontStyle="italic" align="left" display="inline">
              Model:
            </Typography>
            <Chip label={projectData.model.name} size="medium" />
          </Box>

        </Stack>
      </Container>

      <GraphDiseasePage modelId={projectData.model_id} onGoToNodeProcessPage={handleGoToNodeProcessPage} />
    </>
  );
}
