import { Chip, Typography } from '@mui/material';

export default function KeywordsChips(keywords: string[]) {
  if (keywords?.length) {
    return (
      <div>
        {keywords.map((kw) => (
          <Chip key={kw} label={kw} sx={{ marginRight: 1, marginBottom: 1 }} color="primary" variant="outlined" />
        ))}
      </div>
    );
  } else {
    return (
      <div>
        <Typography>Nothing to show.</Typography>
      </div>
    );
  }
}
