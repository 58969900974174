import RowType from '_components/base/table/RowType';
import { create } from 'zustand';

export interface TVProjectStoreType {
  model: RowType;
  setInitTVProject: (model: RowType) => void;
  reset: () => void;
}

const INIT_MODEL: RowType = { name: '', disease: '' };

const TVProjectStore = create<TVProjectStoreType>((set) => ({
  model: INIT_MODEL,
  setInitTVProject: (modelVal: RowType) => set(() => ({ model: modelVal })),
  reset: () => set(() => ({ model: INIT_MODEL })),
}));

export default TVProjectStore;
