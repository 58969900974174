import { Box, Button, IconButton, Paper, Stack, TextField, Typography } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { DataRecordType } from '../DataRecordType';
import { extractPrefixFromFileUpload } from '_helpers/data';
import BMRoutineStore from './BMRoutineStore';
import { RecGroupType } from './RecGroupType';
import RowType from '_components/base/table/RowType';

function DataGroupList({
  dataRecord,
  onGroupClick,
  selectedGroups,
  isActive,
}: {
  dataRecord: DataRecordType;
  onGroupClick: (groupValue: string) => void;
  selectedGroups: [RowType, string][];
  isActive: boolean;
}) {
  const modality = dataRecord.fileUpload?.modality as string;
  const prefix = extractPrefixFromFileUpload(dataRecord.fileUpload);

  return (
    <Stack>
      {dataRecord.columnData.unique_values?.map((groupValue, indx) => (
        <Typography
          key={`data-group-item-${indx}`}
          sx={{
            color: selectedGroups.some(
              ([file, value]) => file._id === dataRecord.fileUpload._id && value === groupValue,
            )
              ? 'grey'
              : 'inherit',
            cursor: isActive ? 'pointer' : 'default',
          }}
          onClick={() =>
            isActive &&
            !selectedGroups.some(([file, value]) => file._id === dataRecord.fileUpload._id && value === groupValue) &&
            onGroupClick(groupValue)
          }
        >
          {groupValue} - {modality} - {prefix}
        </Typography>
      ))}
    </Stack>
  );
}

export default function ReconcileRoutine() {
  const projectName = BMRoutineStore((state) => state.projectName);
  const dataRecords = BMRoutineStore((state) => state.dataRecords);
  const initRecGroups = BMRoutineStore((state) => state.recGroups);
  const saveRecGroups = BMRoutineStore((state) => state.setRecGroups);

  const [recGroups, setRecGroups] = useState<RecGroupType[]>(initRecGroups);
  const [newGroupName, setNewGroupName] = useState('');
  const [newGroupData, setNewGroupData] = useState<[RowType, string][]>([]);
  const [isAddingGroup, setIsAddingGroup] = useState(false);
  const [usedGroups, setUsedGroups] = useState<[RowType, string][]>(initRecGroups.flatMap((gr) => gr.dataGroups));
  const [editingGroupIndex, setEditingGroupIndex] = useState<number | null>(null);
  const [minimizedGroups, setMinimizedGroups] = useState<number[]>([]);

  useEffect(() => {
    if (dataRecords.length !== 1) return;
    const newRecGroups: RecGroupType[] = dataRecords.reduce<RecGroupType[]>((acc, rec) => {
      const res = rec.columnData.unique_values?.map((value) => {
        return { name: value, dataGroups: [[rec.fileUpload, value]] };
      }) as RecGroupType[];
      acc.push(...res);
      return acc;
    }, []);
    setRecGroups(newRecGroups);
    setUsedGroups(newRecGroups.flatMap((rec) => rec.dataGroups));
  }, [dataRecords]);

  const handleAddRecGroupClick = () => {
    setIsAddingGroup(true);
    setEditingGroupIndex(null);
    setNewGroupName('');
    setNewGroupData([]);
  };

  const handleSaveRecGroupClick = () => {
    if (editingGroupIndex !== null) {
      const updatedGroups = [...recGroups];
      updatedGroups[editingGroupIndex] = { name: newGroupName, dataGroups: newGroupData };
      setRecGroups(updatedGroups);
    } else {
      setRecGroups([...recGroups, { name: newGroupName, dataGroups: newGroupData }]);
    }
    setUsedGroups([...usedGroups, ...newGroupData]);
    setNewGroupName('');
    setNewGroupData([]);
    setIsAddingGroup(false);
    setEditingGroupIndex(null);
  };

  const cancelSaveRecGroupClick = () => {
    setNewGroupName('');
    setNewGroupData([]);
    setIsAddingGroup(false);
    setEditingGroupIndex(null);
  };

  const handleGroupClick = (groupValue: string, dataRecordIndex: number) => {
    const selectedDataRecord = dataRecords[dataRecordIndex];
    const selectedTuple: [RowType, string] = [selectedDataRecord.fileUpload, groupValue];
    const existingSelections = newGroupData.filter(
      ([id, value]) => id === selectedTuple[0], //&& value === selectedTuple[1]
    );
    if (existingSelections.length === 0) {
      setNewGroupData([...newGroupData, selectedTuple]);
    } else if (existingSelections.some(([id, value]) => id === selectedTuple[0] && value === selectedTuple[1])) {
      setNewGroupData(newGroupData.filter(([id, value]) => id !== selectedTuple[0] || value !== selectedTuple[1]));
    }
  };

  const handleNewGroupClick = ([fileUpload, groupValue]: [RowType, string]) => {
    setNewGroupData(newGroupData.filter(([file, value]) => file._id !== fileUpload._id || value !== groupValue));
  };

  const handleDeleteGroup = (index: number) => {
    if (editingGroupIndex !== null && editingGroupIndex !== index) return;
    const groupToDelete = recGroups[index];
    setRecGroups(recGroups.filter((_, i) => i !== index));
    setUsedGroups(
      usedGroups.filter(
        ([id, value]) => !groupToDelete.dataGroups.some(([gid, gvalue]) => gid === id && gvalue === value),
      ),
    );
    setMinimizedGroups(
      minimizedGroups
        .filter((gindx) => gindx != index)
        .map((gindx) => {
          if (gindx < index) return gindx;
          return gindx - 1;
        }),
    );
  };

  const handleEditGroup = (index: number) => {
    if (editingGroupIndex !== null && editingGroupIndex !== index) return;
    const groupToEdit = recGroups[index];
    setNewGroupName(groupToEdit.name);
    setNewGroupData(groupToEdit.dataGroups);
    setIsAddingGroup(false);
    setEditingGroupIndex(index);
    setUsedGroups(
      usedGroups.filter(
        ([id, value]) => !groupToEdit.dataGroups.some(([gid, gvalue]) => gid === id && gvalue === value),
      ),
    );
  };

  const handleToggleGroupMinimize = (index: number) => {
    setMinimizedGroups((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]));
  };

  const isGroupNameAndDataValid = newGroupName && newGroupData.length > 0;

  const handleClickConfirm = () => {
    saveRecGroups(recGroups);
  };

  return (
    <>
      <Stack spacing={2} sx={{ pb: 2 }}>
        <Typography variant="h4">{projectName}</Typography>
      </Stack>
      <Typography variant="h6" sx={{ pb: 1 }}>
        Group Reconciliation
      </Typography>
      <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ width: '100%' }}>
        {/* Groups */}
        <Stack spacing={2} sx={{ flex: 1, alignContent: 'left' }}>
          {recGroups.map((group, indx) => (
            <Paper key={`group-name-${indx}`} sx={{ p: 2, border: editingGroupIndex === indx ? '1px dashed' : 'none' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box flex={1} onClick={() => handleToggleGroupMinimize(indx)}>
                  <Typography variant="h6" sx={{ cursor: 'pointer' }}>
                    {group.name}
                  </Typography>
                </Box>
                {editingGroupIndex === null && (
                  <Box>
                    <IconButton onClick={() => handleEditGroup(indx)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteGroup(indx)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
              {!minimizedGroups.includes(indx) && (
                <Stack>
                  {editingGroupIndex === indx ? (
                    <>
                      <TextField
                        value={newGroupName}
                        onChange={(e) => setNewGroupName(e.target.value)}
                        placeholder="Enter group name"
                        fullWidth
                        variant="standard"
                        sx={{ mb: 2 }}
                      />
                      <Stack>
                        {newGroupData.map(([fileUpload, groupValue], indx) => {
                          const prefix = extractPrefixFromFileUpload(fileUpload);
                          return (
                            <Typography
                              key={`new-group-item-${indx}`}
                              sx={{ cursor: 'pointer' }}
                              onClick={() => handleNewGroupClick([fileUpload, groupValue])}
                            >
                              {groupValue} - {prefix}
                            </Typography>
                          );
                        })}
                      </Stack>
                      <Button variant="contained" onClick={handleSaveRecGroupClick} disabled={!isGroupNameAndDataValid}>
                        Save Group
                      </Button>
                    </>
                  ) : (
                    <>
                      <Typography variant="body2">Data Groups:</Typography>
                      {group.dataGroups.map(([fileUpload, dataGroup], index) => {
                        const prefix = extractPrefixFromFileUpload(fileUpload);
                        return (
                          <Typography key={index}>
                            {dataGroup} - {prefix}
                          </Typography>
                        );
                      })}
                    </>
                  )}
                </Stack>
              )}
            </Paper>
          ))}
          {isAddingGroup && (
            <Paper sx={{ p: 2, border: '1px dashed' }}>
              <TextField
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
                placeholder="Enter group name"
                fullWidth
                variant="standard"
                sx={{ mb: 2 }}
              />
              {newGroupData.length < 1 && (
                <Typography variant="caption">Click Available Data Groups in the right column to add</Typography>
              )}
              <Stack>
                {newGroupData.map(([fileUpload, groupValue], indx) => {
                  const prefix = extractPrefixFromFileUpload(fileUpload);
                  return (
                    <Typography
                      key={`new-group-item-${indx}`}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => handleNewGroupClick([fileUpload, groupValue])}
                    >
                      {groupValue} - {prefix}
                    </Typography>
                  );
                })}
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Button variant="contained" onClick={handleSaveRecGroupClick} disabled={!isGroupNameAndDataValid}>
                  Save Group
                </Button>
                <Button variant="contained" onClick={cancelSaveRecGroupClick}>
                  Cancel
                </Button>
              </Stack>
            </Paper>
          )}
          {!isAddingGroup &&
            editingGroupIndex === null &&
            dataRecords.reduce((sum, record) => sum + (record.columnData.unique_values_no || 0), 0) !==
              usedGroups.length && (
              <Button variant="outlined" onClick={handleAddRecGroupClick}>
                Add Group
              </Button>
            )}
        </Stack>

        {/* Dataset groups */}
        <Box sx={{ flex: 1, display: 'flex' }} justifyContent="right">
          <Stack spacing={5}>
            <Typography variant="h6">All groups identified</Typography>
            {dataRecords.map((record, rindx) => (
              <DataGroupList
                key={`data-group-${rindx}`}
                dataRecord={record}
                onGroupClick={(groupValue) => handleGroupClick(groupValue, rindx)}
                selectedGroups={[...usedGroups, ...newGroupData]}
                isActive={isAddingGroup || editingGroupIndex !== null}
              />
            ))}
          </Stack>
        </Box>
      </Box>
      <Button
        sx={{ mt: 2 }}
        disabled={isAddingGroup || editingGroupIndex !== null}
        variant="contained"
        onClick={handleClickConfirm}
      >
        Confirm
      </Button>
    </>
  );
}
