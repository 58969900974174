import { ChangeEvent, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useTheme } from '@mui/material/styles';

import { DISEASES, PLATFORMS, MODALITIES, TrioFileFormControl } from '_components/content/SpecFileFormControls'
import { toast } from 'react-toastify';

export interface OnSubmitOptions {
    disease: string;
    modality: string;
    platform: string;
}

interface UploadDatasetPromptProps {
    onCancel: () => void;
    onSubmit: (files: File[], { disease, modality, platform }: OnSubmitOptions) => void;
};

const INIT_THE_FILES: File[] = [];


export default function UploadDatasetPrompt({ onSubmit, onCancel }: UploadDatasetPromptProps) {
    const theme = useTheme();

    const [theFiles, setTheFiles] = useState<File[]>(INIT_THE_FILES);
    const diseases = DISEASES;
    const defaultDisease = diseases[0];
    const [disease, setDisease] = useState<string>(defaultDisease);

    const modalities = MODALITIES;
    const defaultModality = modalities[0];
    const [modality, setModality] = useState<string>(defaultModality);

    const platforms = PLATFORMS;
    const defaultPlatform = platforms[defaultModality][0];
    const [platform, setPlatform] = useState<string>(defaultPlatform);

    function handleFileChosen(event: ChangeEvent<HTMLInputElement>) {
        const targetFiles: FileList | null = event.target.files;
        if (targetFiles?.length === 0) {
            return;
        }
        const files: File[] = [];
        for (var i = 0; i < (targetFiles?.length || 0); ++i) {
            const item = targetFiles?.item(i);
            if (item) files.push(item as File);
        }
        setTheFiles(files);
    }

    function resetChoices() {
        setDisease(defaultDisease);
        setModality(defaultModality);
        setPlatform(defaultPlatform);
        setTheFiles(INIT_THE_FILES);
    }

    async function handleFilesUpload() {
        if (!theFiles || theFiles.length < 1) {
            toast.error("Please, select a dataset file");
            return;
        }
        onSubmit(
            theFiles,
            { disease: disease, modality: modality, platform: platform }
        )
        resetChoices();
    }

    function handleCancel() {
        onCancel();
    }

    return (
        <Box sx={{ minWidth: '700px' }}>
            <Typography variant="h5" color="primary">
                Data Uploads
            </Typography>
            <br></br>
            <input
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={(event) => handleFileChosen(event)}
            />
            <label htmlFor="raised-button-file">
                <Button variant="contained" component="span" sx={{ mr: 1, backgroundColor: theme.palette.primary.main }}>
                    Choose File(s)
                </Button>
            </label>
            <TrioFileFormControl
                disease={disease} setDisease={setDisease}
                modality={modality} setModality={setModality}
                platform={platform} setPlatform={setPlatform}
            />

            <List dense={true}>
                {theFiles.map((file, index) => (
                    <ListItem key={index}>
                        <ListItemIcon>
                            <FileUploadIcon />
                        </ListItemIcon>
                        <ListItemText primary={file.name} />
                    </ListItem>
                ))}
            </List>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    onClick={handleFilesUpload}
                    variant="contained"
                    component="span"
                    disabled={theFiles.length === 0}
                    sx={{ mr: 1, backgroundColor: theme.palette.primary.main }}
                >
                    Upload
                </Button>

                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleCancel} variant="contained" color="error" sx={{ mr: 1 }}>
                    Cancel
                </Button>
            </Box>
        </Box>
    );
}
